.shankar-product-item {
    font-family: 'League Spartan', sans-serif;
}

.product-item-unit-table {
    padding: 6px;
}
.product-item-unit-table table, .product-item-unit-table td {
    border: 1px solid #aaa;
    padding: 8px !important;
}

.product-item-unit-table table, .product-item-unit-table th {
    border: 1px solid #aaa;
    padding: 4px !important;
}

.product-item-unit-table table {
    width: 100%;
    border-collapse: collapse;
}



.eco-button.product-item-addbtn:hover {
    background-color: black;
}

.item-counter-container {
    display: flex;
    max-width: 200px;
    margin: auto;
    background-color: #fcb800;
    border-radius: 6px !important;
    height: 22px;
}

.item-counter-container button {
    width: 16px !important;
    height: 16px !important;
    border: 1px solid #fcb800 !important;
    font-weight: bold;
}
.item-counter-container input {
    height: 12px;
    background-color: transparent;
    height: 16px !important;
    border: none !important;
    font-size: 12px;
    margin-top: 2px;
}
.product-item-price {
    font-size: 24px;
    color: #000;
    font-weight: 700;
}


.shankar-product-name {
    margin: 0;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2em;
    min-height: 3.3em!important;
    color: #06c;
    --max-lines: 2;
    max-height: calc(1.2em*var(--max-lines));
    overflow: hidden;
    padding: 0 1rem 5px 0;
    font-family: 'League Spartan'
}