.Hospitality-navbar-Layout {
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
  .layout {
    display: grid;
    row-gap: 32px;
    column-gap: 64px;
    .column-styles {
      .column {
        display: flex;
        justify-content: space-between;
        background-color: #fff;
        .item-details {
          display: flex;
          column-gap: 10px;
          width: 100%;
          .item-dela-cont {
            height: 100%;
            width: max-content;

            position: relative;
            .item-image {
              height: 48px;
              width: 48px;
              border-radius: 50%;
              object-fit: cover;
              cursor: pointer;
            }
            .dea-cont {
              position: absolute;
              height: 30px;
              width: 30px;
              top: 12px;
              left: 10px;
              border-radius: 4px;
              background-color: #1bac4b;
              display: flex;
              align-items: center;
              justify-content: center;
              .deal {
                height: 22px;
                max-height: 22px;
              }
            }
          }

          .item-des {
            width: 100%;
            .item-des-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .name {
                font-family: "Lato";
                font-size: 15px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                color: #111;
              }
              .dotted-line {
                flex-grow: 1;
                border-bottom: 1px dotted #9999995b;
                margin: 4px 8px;
              }
              .price {
                font-family: "Lato";
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                color: #111;
              }
            }

            .details {
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              font-family: "Lato";
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              color: #999;
              margin-top: 5px;
            }
          }
        }
        .column-right-container {
          width: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          .column-right-container-inner {
            width: 80%;
            .quantity {
              display: flex;
              align-items: center;
              justify-content: space-between;
              .icon-cont {
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #fff;
                border-radius: 8px;
                cursor: pointer;
                border: 1px solid #e4e9ee;
                .delete-icon {
                  height: 18px;
                }
                .icon {
                  height: 14px;
                  width: 14px;
                  filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                    saturate(1930%) hue-rotate(182deg) brightness(101%)
                    contrast(103%);
                }
              }
              .empty-icon-cont {
                height: 44px;
                width: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f3f3f3;
                border-radius: 8px;
                cursor: pointer;
                border: 1px solid #e4e9ee;
                .icon {
                  height: 14px;
                  width: 14px;
                  filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                    saturate(5%) hue-rotate(315deg) brightness(97%)
                    contrast(88%);
                }
              }
              .qty-cont {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: "Roboto";
                font-size: 18px;
                font-weight: 500;
              }
            }
            .add-to-cart-btn {
              width: 100%;
              background-color: #006dae;
              border-radius: 0.25rem;
              font-family: "Roboto";
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #fff;
              padding: 0.625rem 0;
              cursor: pointer;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .add-to-cart-btn-custom {
              width: 100%;
              border-radius: 0.25rem;
              font-family: "Roboto";
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              color: #006dae;
              padding: 0.625rem 0;
              margin-top: 0.4rem;
              border: 1px solid #006dae;
              cursor: pointer;
              a {
                color: #006dae;
                margin: 0;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
    &.layout1 {
      grid-template-columns: 100%;
      width: 100%;
    }
    &.layout2 {
      grid-template-columns: 50% 50%;
      width: calc(100% - 64px);
    }
    &.layout3 {
      grid-template-columns: 33.3% 33.3% 33.3%;
      width: calc(100% - 128px);
    }
  }

  .title-menu {
    color: #a29260;
    font-family: Lato;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 600px) {
  .Hospitality-navbar-Layout {
    padding: 20px;
    .layout {
      &.layout2 {
        grid-template-columns: 100%;
        width: 100%;
      }
      &.layout3 {
        grid-template-columns: 50% 50%;
        width: calc(100% - 64px);
      }
    }
  }
}
