.HomeHero {
  width: 100%;
  height: 100vh;
}
.HomeHeroTwo {
  width: 80%;
  display: flex;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  align-items: center;
  .HomeHerotwo-left {
    width: calc(50% - 80px);
    padding: 0px 100px;
    .header-text-jack {
      color: #b5a46d;
      font-family: Lato;
      font-size: 12px;
      padding: 8px 16px;
      background-color: rgba(181, 164, 109, 0.1);
      border-radius: 16px;
      display: flex;
      width: max-content;
    }
    .des-header {
      font-family: Lustria;
      font-size: 28px;
      color: #111111;
      line-height: 36px;
      margin-top: 16px;
    }
    .description-text {
      font-family: Inter;
      font-size: 14px;
      color: #999;
      line-height: 24px;
      margin-top: 12px;
      text-align: justify;
    }
    .avatar-container {
      display: flex;
      margin-top: 30px;
      align-items: center;
      column-gap: 20px;
      .avatar-cont {
        height: 60px;
        width: 60px;
        background-color: #999;
        border-radius: 50%;
      }
      .avatar-description {
        .avatar-name {
          color: #111111;
          font-family: Lato;
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: 600;
          line-height: 20px;
        }
        .avatar-description {
          font-family: Lato;
          font-size: 15px;
          color: #999;
          line-height: 20px;
        }
      }
    }
  }
  .HomeHerotwo-right {
    width: 50%;
  }
}
.HomeHeroThree {
  width: 100%;
  display: flex;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  align-items: center;
  gap: 10px;
  height: 75vh;
  .HomeHerothree-left {
    width: 50%;
    background-color: #f9f5ec;
    height: 100%;
    padding: 20px;
    .homethreee-container {
      height: 100%;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
      .homethree-left-contnet {
        width: 50%;
        .image-icon {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 20px;
          img {
            height: 100px;
            width: 100px;
          }
        }
        .header-text-jack {
          font-family: Lustria;
          color: #111111;
          font-size: 32px;
          margin-bottom: 20px;
        }
        .opening-hours-text {
          font-family: Lato;
          color: #999;
          font-size: 15px;
          display: flex;
          width: 80%;
          justify-content: space-between;
          margin: auto;
          margin-bottom: 9px;
          align-items: flex-end;
        }
        .btn-container {
          width: 100%;
          display: flex;
          justify-content: center;
          margin-top: 40px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .HomeHerothree-right {
    width: 50%;
    height: 100%;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
.HomeHeroFour {
  width: 95%;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  height: 100%;
  text-align: center;
  .header-text-jack {
    color: #b5a46d;
    font-family: Lato;

    font-size: 12px;
    padding: 8px 16px;
    background-color: rgba(181, 164, 109, 0.1);
    border-radius: 16px;
    display: flex;
    width: max-content;
    margin: auto;
  }
  .menu-component-header {
    font-family: Lustria;
    color: #111111;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .menu-comp-sub-header {
    font-family: Lato;
    font-size: 16.5px;
    color: #999;
    width: 63%;
    margin: auto;
    line-height: 25px;
    margin-bottom: 10vh;
  }
  .menu-category {
    display: flex;
    width: max-content;
    margin: auto;
    .menu-cat-item {
      color: #a29260;
      font-family: Lato;
      font-weight: 700;
      font-size: 13px;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .menu-cat-item:not(:last-child)::after {
      content: "~";
      margin: 24px;
    }
  }
  .menu-layout {
    width: 50%;
    margin: auto;
    margin-top: 70px;
  }
}
.HomeHeroFive {
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 100%;
  background-color: #f9f5ec;
  padding: 15vh 0px;
  .HomeHeroFive-Content {
    width: 65%;
    height: 100%;
    margin: auto;
    .gallery-container {
      display: grid;
      grid-template-columns: 25% 25% 25% 25%;
      .image-cont {
        width: 100%;
        height: 35vh;
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    .btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 40px;
    }
  }
}
.HomeHeroSix {
  width: 75%;
  margin: auto;
  margin-top: 10vh;
  margin-bottom: 10vh;
  height: 80vh;
  text-align: center;
  .header-text-jack {
    color: #b5a46d;
    font-family: Lato;

    font-size: 12px;
    padding: 8px 16px;
    background-color: rgba(181, 164, 109, 0.1);
    border-radius: 16px;
    display: flex;
    width: max-content;
    margin: auto;
  }
  .menu-component-header {
    font-family: Lustria;
    color: #111111;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  .menu-comp-sub-header {
    font-family: Lato;
    font-size: 16.5px;
    color: #999;
    width: 40%;
    margin: auto;
    line-height: 25px;
  }
  .menu-comp-sub-header-2 {
    font-family: Lato;
    font-size: 15px;
    color: #999;
    width: 40%;
    margin: auto;
    line-height: 25px;
  }
  .services-card {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
    margin-top: 80px;
  }
  .seperator {
    width: 90px;
    height: 2px;
    background-color: #b5a46d;
    margin: auto;
    margin-bottom: 40px;
    margin-top: 80px;
  }
}
@media only screen and (max-width: 700px) {
  .HomeHeroTwo {
    display: block;
    width: 100%;
    .HomeHerotwo-left {
      width: 90%;
      padding: 0px;
      margin: auto;
    }
    .HomeHerotwo-right {
      width: 90%;
      padding: 0px;
      margin: auto;
      margin-top: 50px;
    }
  }
  .HomeHeroThree {
    display: block;
    height: 100vh;
    .HomeHerothree-left {
      width: 100%;
      height: 50%;
      .homethreee-container {
        .homethree-left-contnet {
          .opening-hours-text {
            display: none;
          }
        }
      }
    }
    .HomeHerothree-right {
      width: 100%;
      height: 50%;
    }
  }
  .HomeHeroFour {
    width: 90%;
    .menu-comp-sub-header {
      width: 95%;
      margin-bottom: 5vh;
    }
    .menu-category {
      text-wrap: wrap;
      gap: 20px;
      .menu-cat-item:not(:last-child)::after {
        content: none;
        margin: 0px;
      }
    }
    .menu-layout {
      width: 100%;
      margin-top: 40px;
    }
  }
  .HomeHeroFive {
    padding: 10% 0;
    .HomeHeroFive-Content {
      width: 90%;
      .gallery-container {
        display: block;
      }
    }
  }
  .HomeHeroSix {
    width: 90%;
    height: max-content;
    .menu-comp-sub-header {
      width: 95%;
    }
    .services-card {
      grid-template-columns: auto auto;
      row-gap: 40px;
    }
    .menu-comp-sub-header-2 {
      width: 90%;
    }
  }
}
@media only screen and (min-width: 700px) and (max-width: 900px) {
  .HomeHeroTwo {
    display: block;
    width: 100%;
    .HomeHerotwo-left {
      width: 90%;
      padding: 0px;
      margin: auto;
    }
    .HomeHerotwo-right {
      width: 90%;
      padding: 0px;
      margin: auto;
      margin-top: 50px;
    }
  }
  .HomeHeroThree {
    display: block;
    height: 100vh;
    .HomeHerothree-left {
      width: 100%;
      height: 50%;
      .homethreee-container {
        .homethree-left-contnet {
          .opening-hours-text {
            display: none;
          }
        }
      }
    }
    .HomeHerothree-right {
      width: 100%;
      height: 50%;
    }
  }
  .HomeHeroFour {
    width: 80%;
    .menu-comp-sub-header {
      width: 80%;
      margin-bottom: 5vh;
    }
    .menu-category {
      text-wrap: wrap;
      gap: 20px;
      .menu-cat-item:not(:last-child)::after {
        content: none;
        margin: 0px;
      }
    }
    .menu-layout {
      width: 80%;
      margin-top: 80px;
    }
  }
  .HomeHeroFive {
    padding: 10% 0;
    .HomeHeroFive-Content {
      width: 90%;
      .gallery-container {
        grid-template-columns: 50% 50%;
      }
    }
  }
  .HomeHeroSix {
    width: 90%;
    height: max-content;
    .menu-comp-sub-header {
      width: 95%;
    }
    .services-card {
      grid-template-columns: auto auto;
      row-gap: 40px;
    }
    .menu-comp-sub-header-2 {
      width: 90%;
    }
  }
}
