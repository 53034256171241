.PaymentStyles {
  position: relative;
  .eco-button.eco-button--primary {
    background-color: #039be5;
    border-color: transparent;
    color: #fff;
    width: 250px !important;
  }
  .payment-container {
    border: 1px solid #c6c7d2;
    border-radius: 8px;
    .form-header {
      background-color: #f0f1f4;
      padding: 20px 30px;
      color: #4d4d4d;
      font-weight: 600;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    .payment-form-container {
      padding: 20px 40px;
      .eco-mt-80 {
        margin-top: 80px;
      }
    }
  }
  .overlayer {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    .order-placing {
      font-size: 1.8rem;
      color: #fff;
      font-weight: 500;
    }
  }
}
.adyen-checkout__checkbox {
  display: none !important;
}
