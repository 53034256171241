.product-item-unit-table {
    padding: 6px;
}
.product-item-unit-table table, .product-item-unit-table td {
    border: 1px solid;
    padding: 8px !important;
}

.product-item-unit-table table, .product-item-unit-table th {
    border: 1px solid;
    padding: 4px !important;
}

.product-item-unit-table table {
    width: 100%;
    border-collapse: collapse;
}



.eco-button.product-item-addbtn:hover {
    background-color: black;
}