.Footer {
  width: 100%;

  margin-top: 20vh;
  border-top: 1px solid #e4e9ee;
  padding-top: 30px;
  margin-bottom: 20px;
  font-family: Roboto !important;
  .footer-innwr {
    display: flex;
    // justify-content: space-between;
    padding-right: 30px;
    border-top: 1px solid #e4e9ee;
    padding-top: 30px;
    gap: 10%;
    .footer-mid {
      width: 30%;
      .name {
        font-family: Roboto !important;
        font-size: 20px;
        font-weight: 800;
        line-height: 22.4px;
        letter-spacing: -0.20000000298023224px;
        color: #424242;
        margin-bottom: 20px;
      }
      .opening-hours-text {
        font-family: Roboto !important;
        color: #575757;
        font-size: 14px;
        margin-bottom: 12px;
        display: flex;
        width: 100%;
        align-items: flex-end;
        .dotted-line-eg {
          flex-grow: 1;
          border-bottom: 1px dotted #575757;
          margin: 4px 8px;
        }
      }
    }
    .footer-innwr-right {
      //   display: flex;
      //   column-gap: 40px;

      .logo-container {
        margin-bottom: 20px;
        img {
          height: 40px;
        }
      }
      .menu-header-inner-header {
        background-color: #fff;
        .name {
          font-family: Roboto !important;
          font-size: 20px;
          font-weight: 800;
          line-height: 22.4px;
          letter-spacing: -0.20000000298023224px;
          text-align: left;
          color: var(--primary-color);
        }
        .address {
          margin-top: 10px;
          font-family: Roboto !important;
          font-size: 15px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          color: #575757;
          text-transform: capitalize;
        }
      }
    }
    .menu-header-inner {
      background-color: #fff;
      text-align: left;
      .name {
        font-family: Roboto !important;
        font-size: 20px;
        font-weight: 800;
        line-height: 22.4px;
        letter-spacing: -0.20000000298023224px;

        color: #424242;
      }
      .address {
        margin-top: 20px;
        font-family: Roboto !important;
        font-size: 16px;
        font-weight: 400;
        color: #575757;
        .cont {
          display: flex;
          column-gap: 10px;
          margin-top: 10px;
          img {
            height: 14px;
            width: 14px;
            margin-top: 3px;
            filter: brightness(0) saturate(100%) invert(30%) sepia(0%)
              saturate(7%) hue-rotate(152deg) brightness(101%) contrast(80%);
          }
        }
      }
    }
  }
  .witmeg-text {
    border-top: 1px solid #e4e9ee;
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    img {
      height: 22px;
    }
    .text-cont-wite {
      font-family: Roboto !important;
      font-size: 14px;
      color: #161616;
      font-weight: 500;
    }
  }
}
@media only screen and (max-width: 600px) {
  .Footer {
    .footer-innwr {
      padding-right: 0px;
      flex-direction: column-reverse;
      .footer-mid {
        width: 100%;
        margin-bottom: 30px;
      }
      .menu-header-inner {
        margin-bottom: 30px;
      }
    }
  }
}
