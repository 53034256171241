.RestaurantAboutus {
  height: 100%;
  width: 100%;
  margin-top: -67px;
  .About-header {
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    .text {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      height: 100%;
      .header-text-jack {
        font-family: Lustria;
        font-size: 56px;
      }
    }
  }
  .About-content {
    .header-text-jack {
      font-family: Lato;
      font-size: 32px;
      text-transform: uppercase;
      color: #b5a46d;
      margin-bottom: 30px;
    }
    .content {
      font-family: Lato;
      font-size: 16px;
      color: #121212;
      line-height: 28px;
      margin-bottom: 10px;
      text-align: justify;
    }
    width: 80%;

    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  .gallery-container {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    .image-cont {
      width: 100%;
      height: 40vh;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
@media only screen and (max-width: 700px) {
  .RestaurantAboutus {
    .About-header {
      height: 60vh;
      .text {
        .header-text-jack {
          margin-top: 67px;
          font-size: 40px;
          text-align: center;
        }
      }
    }
    .About-content {
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
    .gallery-container {
      display: block;
    }
  }
}
@media only screen and (min-width: 700px) and (max-width: 900px) {
  .RestaurantAboutus {
    .About-header {
      height: 40vh;
      .text {
        .header-text-jack {
          margin-top: 67px;
          text-align: center;
        }
      }
    }
    .About-content {
      margin-bottom: 5vh;
      margin-top: 5vh;
    }
    .gallery-container {
      grid-template-columns: 50% 50%;
    }
  }
}
