body {
  overflow: visible !important;
}
:root {
  --primary-color: #006dae;
  --light-primary-color: #55ccf6;
  --dark-primary-color: #00578a;
  --filter: brightness(0) saturate(100%) invert(0%) sepia(100%) saturate(7434%)
    hue-rotate(211deg) brightness(115%) contrast(115%);
  --opacity-primary-color: #006dae1a;
}

.main-page-layout {
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 0px !important;
  margin-left: 0px !important;
}

html::-webkit-scrollbar,
.main-page-layout::-webkit-scrollbar,
.order-confirmation-page::-webkit-scrollbar,
#root.Default .page-wrapper .page-content::-webkit-scrollbar,
#root.Default
  .page-wrapper
  .page-content
  .tab-content-container::-webkit-scrollbar {
  /* display: none !important; */
}

.main-page-layout .eco-layout-desktop {
  position: relative;
}

body > iframe {
  pointer-events: none;
}
::-webkit-scrollbar {
  width: 6px;
  height: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  border: none;
  box-shadow: none;
}

::-webkit-scrollbar-thumb {
  background: #d6d6d6;
  border-radius: 10px;
}
