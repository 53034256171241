.tab-headers {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    background: rgb(250,250,250);
    position: sticky;
    top: 0px;
    z-index: 99;
}
.tab-item{
    padding: 10px;
    cursor: pointer;
}
.selected-tab-item span{
    font-weight: bold !important;
    border-bottom: solid 2px;
    /* color: #71A601 !important; */
    filter: hue-rotate(180deg);
}
.tab-content-container{
    padding: 10px 0px;
    height: 100%;
    overflow-y: auto;
    max-width: 1200px;
    margin: auto;
    width: 100%;
}
.tab-popup {
    max-height: 60vh!important;
    overflow-y: auto;
    overflow-x: hidden;
}
.tab-popup::-webkit-scrollbar {
    width: 0px;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #808080;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #808080;
}
h2.eco-tab-headers-title {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    color: #333333;
    font-family: 'Jost';
}
/* .eco-tab-headers .custom-tabs .selected-tab-item{
    font-weight: bold;
} */
/* media queries - mobile */
@media only screen and (max-width: 767px){
    .tab-headers{
        flex-wrap: nowrap;
        overflow-x: scroll;
        justify-content: flex-start;
        padding-left: 10px;
    }
    .tab-item{
        white-space: nowrap;
    }
    .tab-content-container{
        padding: 0;
    }
}
