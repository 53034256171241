.accordion {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}

.accordion-item {
  border-bottom: 1px solid #ddd;

  &:last-child {
    border-bottom: none;
  }
}

.accordion-header {
  padding-bottom: 16px;
  padding-right: 16px;
  padding-top: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .header-content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: Jost;
    font-size: 20px;
    color: #121212;
    img {
      height: 16px;
      filter: brightness(0) saturate(100%) invert(59%) sepia(96%) saturate(5%)
        hue-rotate(36deg) brightness(90%) contrast(85%);
    }
  }
  .header-content-small {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: Jost;
    font-size: 16px;
    color: #000;
    font-weight: 500;
  }
}

.accordion-icon {
  height: 16px;
  transition: transform 0.3s ease;
  img {
    height: 16px;
  }

  &.open {
    transform: rotate(180deg);
  }
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, padding 0.3s ease;
  background-color: #ffffff;
  font-family: Jost;
  font-size: 13px;
  color: #9d9d9d;
  text-align: justify;

  &.open {
    max-height: 500px;
    padding: 0 16px;
    padding-bottom: 16px;
  }
}
