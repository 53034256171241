.CheckoutItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .CheckoutItem-left {
    width: 70%;
    display: flex;
    column-gap: 15px;
    .item-image {
      height: 80px;
      width: 80px;
      object-fit: cover;
      object-position: center;
      border-radius: 4px;
      img {
        height: 80px;
        width: 80px;
        object-fit: cover;
        object-position: center;
        border-radius: 4px;
      }
    }
    .item-deatils-container {
      padding-top: 10px;
      .item-name {
        font-family: Jost;
        color: #121212;
        font-size: 18px;
      }
      .item-dec {
        font-size: 13px;
        color: #777777;
        font-family: Roboto;
      }
    }
  }
  .CheckoutItem-right {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    .item-price {
      padding-top: 10px;
      color: #121212;
      font-family: Jost;
    }
  }
}
