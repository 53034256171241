.RestaurantLookbook {
  height: 100%;
  width: 100%;
  margin-top: -67px;
  overflow: hidden;
  .galler-image {
    width: 100%;
    height: 100vh;
    object-fit: cover;
    position: relative;
  }
}
