.EcommerceContact {
  background-color: #12121209;
  height: 100%;
  .EcommerceContact-header {
    width: 100%;
    font-size: 36px;
    color: black;
    font-family: Jost;
    text-align: center;
    padding-top: 5vh;
    margin-bottom: 10vh;
  }
  .EcommerceContact-Container {
    padding: 80px 60px;
    background-color: #fff;
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceContact {
    .EcommerceContact-Container {
      padding: 40px 30px;
    }
  }
}
