.Ecommerce-Footer {
  width: 100%;
  background-color: #f2ede6;
  .Ecommerce-Footer-top {
    display: flex;
    flex: 1;
    padding: 80px 60px 40px 60px;
    border-bottom: 1px solid #e4e4e4;
    .Ecommerce-Footer-left {
      flex-direction: column;
      display: flex;
      flex: 1;

      .Footer-header {
        font-family: Roboto;
        font-size: 20px;
        color: #121212;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 22px;
      }
      .Footer-sub-text {
        font-family: Roboto;
        color: #535353;
        margin-bottom: 14px;
      }
    }
    .Ecommerce-Footer-center {
      flex-direction: column;
      display: flex;
      flex: 1;
      .Footer-header {
        font-family: Roboto;
        font-size: 20px;
        color: #121212;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 22px;
      }
      .Footer-sub-text {
        font-family: Roboto;
        color: #535353;
        margin-bottom: 14px;
        font-size: 15px;
        cursor: pointer;
      }
    }
    .Ecommerce-Footer-right {
      flex-direction: column;
      display: flex;
      flex: 1;
      .Footer-header {
        font-family: Roboto;
        font-size: 20px;
        color: #121212;
        font-weight: 500;
        margin-bottom: 20px;
        line-height: 22px;
      }
      .Footer-sub-text {
        font-family: Roboto;
        color: #8a8a8a;
        margin-bottom: 12px;
        font-size: 15px;
        margin-bottom: 20px;
      }
    }
  }
  .Ecommerce-Footer-bottom {
    display: flex;
    flex: 1;
    padding: 20px 60px;
    .Ecommerce-Footer-bottom-left {
      font-family: Roboto;
      color: #535353;
      margin-bottom: 14px;
      flex: 3;
      display: flex;
    }
    .Ecommerce-Footer-bottom-right {
      font-family: Roboto;
      color: #535353;
      margin-bottom: 14px;
      display: flex;
      justify-content: flex-end;
      flex: 1;
      align-items: center;
      column-gap: 20px;
      .card-content {
        display: flex;
        justify-content: flex-end;
        flex: 1;
        align-items: center;
        column-gap: 20px;
        .card-details {
          height: 20px;
          margin-left: -12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Ecommerce-Footer {
    .Ecommerce-Footer-top {
      display: block;
      .Ecommerce-Footer-left {
        margin-bottom: 20px;
        text-align: center;
      }
      .Ecommerce-Footer-center {
        margin-bottom: 20px;
        text-align: center;
      }
      .Ecommerce-Footer-right {
        text-align: center;
        .Footer-button {
          display: flex;
          justify-content: center;
        }
      }
    }
    .Ecommerce-Footer-bottom {
      display: block;
      .Ecommerce-Footer-bottom-left {
        justify-content: center;
        text-align: center;
        margin-bottom: 30px;
      }
      .Ecommerce-Footer-bottom-right {
        flex-direction: column;
        text-align: center;
        .card-content {
          margin-top: 10px;
          .card-details {
            background-color: #e4e4e4;
            margin-left: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Ecommerce-Footer {
    .Ecommerce-Footer-bottom {
      display: block;
    }
  }
}
