.ContactHero {
  width: 100%;
  height: 100%;
  .Contact-Hero-cop {
    display: flex;
    position: relative;
    width: 100%;
    height: 64vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0.63); /* Add a semi-transparent layer */
    background-blend-mode: overlay;
    .curve {
      width: 100%;
      position: absolute;
      bottom: 0;
      height: 60px;
      background-color: #fff;
      clip-path: ellipse(54% 95% at 50% 100%);
    }
    .text {
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      .header-text-jack {
        font-family: Lustria;
        font-size: 40px;
      }
      .sub-text-content {
        display: flex;
        width: 100%;
        justify-content: center;
        font-family: Lato;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 600;
        letter-spacing: 0.5px;
        margin-top: 20px;
      }
    }
  }
  .card-details-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    padding-bottom: 80px;
    margin-bottom: 80px;
    border-bottom: 1px solid #e3e3e3;
    .card-contact-wrapper {
      padding-bottom: 40px;
      padding-top: 40px;
    }
    .card-contact-wrapper:not(:last-child) {
      border-right: 1px solid #e3e3e3;
    }
  }
  .contact-form-header {
    width: 60%;
    margin: auto;
    margin-bottom: 30vh;
    .header-text-jack {
      color: #b5a46d;
      font-family: Lato;

      font-size: 12px;
      padding: 8px 16px;
      background-color: rgba(181, 164, 109, 0.1);
      border-radius: 16px;
      display: flex;
      width: max-content;
      margin: auto;
    }
    .menu-component-header {
      width: max-content;
      font-family: Lustria;
      color: #111111;
      font-size: 32px;
      margin: auto;

      margin-bottom: 20px;
      margin-top: 20px;
    }
    .menu-comp-sub-header {
      font-family: Lato;
      font-size: 16.5px;
      color: #999;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      text-align: center;
      margin: auto;
      line-height: 25px;
      margin-bottom: 50px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ContactHero {
    .Contact-Hero-cop {
      .text {
        .header-text-jack {
          font-size: 32px;
          text-align: center;
        }
        .sub-text-content {
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
    .card-details-container {
      display: block;
      .card-contact-wrapper {
        border-right: none;
      }
    }
    .contact-form-header {
      width: 90%;
      .menu-comp-sub-header {
        width: 90%;
        margin-bottom: 60px;
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .ContactHero {
    .Contact-Hero-cop {
      .text {
        .header-text-jack {
          font-size: 32px;
          text-align: center;
        }
        .sub-text-content {
          font-weight: 400;
          margin-top: 10px;
        }
      }
    }
    .card-details-container {
      grid-template-columns: auto auto;
      .card-contact-wrapper {
        border-right: none;
      }
      .card-contact-wrapper:not(:last-child) {
        border-right: none;
      }
    }
    .contact-form-header {
      width: 100%;
      .menu-comp-sub-header {
        width: 90%;
        margin-bottom: 60px;
      }
    }
  }
}
