.Input-Jack {
  width: 100%;
  .label {
    font-size: 12px;
    font-family: Lato;
    text-transform: uppercase;
    font-weight: 550;
    letter-spacing: 0.5px;
    color: #111;
    span {
      color: #b5a46d;
      margin-left: 5px;
    }
  }
  .input-container {
    width: 100%;
    margin-top: 20px;
    input {
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-size: 14px;
      padding: 12px 16px;
      border-radius: 4px;
      outline: none;
      font-family: Lato;
    }
    input:focus {
      border-color: #b5a46d;
    }
    input::placeholder {
      color: #7a7a7a;
    }
  }
  .error-input {
    font-size: 12px;
    font-family: Lato;
    font-weight: 550;
    letter-spacing: 0.5px;
    color: #ff5a5a;
  }
}
