.ItemComponent {
  width: 100%;
  height: 420px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  .image-container {
    width: 100%;
    height: 350px;

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
  }

  .item-container {
    width: 100%;
    margin-top: 20px;

    .item-title {
      font-family: Jost;
      font-size: 16px;
    }

    .item-price {
      font-family: Jost;
      font-size: 14px;
      color: #555555;
      line-height: 20px;
    }
  }

  .action-container-position {
    position: absolute;
    top: 60%;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s ease, visibility 0.2s ease;

    .action-container {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 10px;

      .action {
        height: 44px;
        width: 44px;
        border-radius: 50%;
        background-color: #fff;
        border: #555555 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .icon {
          height: 20px;
          width: 20px;

          img {
            height: 20px;
            width: 20px;
          }
        }
        &:hover {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }
      }
    }
  }

  &:hover .action-container-position {
    opacity: 1;
    visibility: visible;
  }
}
.ItemComponent2 {
  width: 100%;
  height: 420px;
  border-radius: 4px;
  cursor: pointer;

  .image-container {
    width: 100%;
    height: 350px;
    position: relative;

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
    }
    .close-container {
      position: absolute;
      height: 28px;
      width: 28px;
      top: 8px;
      right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        height: 28px;
        width: 28px;
        filter: invert(1);
      }
    }
    .action-container-position {
      position: absolute;
      bottom: -2px;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      transition: opacity 0.2s ease, visibility 0.2s ease;
      .action-container {
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ffffffb0;
        font-family: Jost;
        color: #000;
        font-size: 18px;
      }
    }

    &:hover .action-container-position {
      opacity: 1;
      visibility: visible;
    }
    .status {
      position: absolute;
      font-family: Roboto;
      padding: 2px 10px;
      background-color: #fff;
      border-radius: 4px;
      left: 10px;
      top: 10px;
      font-weight: 450;
      font-size: 13px;
    }
  }

  .item-container {
    width: 100%;
    margin-top: 20px;

    .item-title {
      font-family: Jost;
      font-size: 16px;
    }

    .item-price {
      font-family: Jost;
      font-size: 14px;
      color: #555555;
      line-height: 20px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ItemComponent {
    height: 300px;
    .image-container {
      height: 230px;
      img {
        height: 230px;
      }
    }
    .action-container-position {
      top: 50%;
    }
  }
  .ItemComponent2 {
    height: 300px;
    .image-container {
      height: 230px;
      img {
        height: 230px;
      }
    }
  }
}
