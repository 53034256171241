.VoucherDefaultOrderPage {
  padding: 64px;
  background: linear-gradient(#f7f7f7 0px, #f7f7f7 250px, white 250px);
  position: relative;
  .PhoneInput {
    width: calc(100% - 2px);
    .PhoneInputInput {
      flex: 1 1;
      outline: none;
      border: 1px solid #d1d8dd !important;
      border-radius: 4px;
      padding: 8px;
    }
  }
  .order-det {
    .eco-input-text .eco-input-text__wrapper input[type="default"],
    .eco-input-text .eco-input-text__wrapper input[type="email"],
    .eco-input-text .eco-input-text__wrapper input[type="number"],
    .eco-input-text .eco-input-text__wrapper input[type="password"],
    .eco-input-text .eco-input-text__wrapper input[type="tel"],
    .eco-input-text .eco-input-text__wrapper input[type="text"] {
      border: 1px solid #d1d8dd !important;
    }
  }
  .error-text {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
  }
  .overlayer {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10000 !important;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    margin: 0;
    margin-left: -64px;
    .order-placing {
      font-family: Roboto !important;
      font-size: 1.8rem;
      color: #fff;
      font-weight: 400;
    }
  }
  .Order-confirmation {
    .eco-product-add-ctl {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 20px;
      background-color: #f0f1f4;
    }
    .Order-confirmation-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 40px;
      .company-container {
        border: 1px solid #e4e9ee;
        border-radius: 8px;
        padding: 10px 20px;
        background-color: #fff;
        .name {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 800;
          line-height: 22.4px;
          letter-spacing: -0.20000000298023224px;
          text-align: left;
          color: #0b0f0e;
        }
        .address {
          font-family: Roboto !important;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
          color: #818b9c;
        }
      }
      .header-text-cont {
        font-family: Roboto !important;
        font-size: 36px;
        font-weight: 700;
        line-height: 57.6px;
        text-align: left;
        color: #101725;
      }
    }
    .form-header {
      font-family: Roboto !important;
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      text-align: left;
      color: #101725;
      margin-top: 24px;
      .toggle-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: -7px 0px;
        .toggle-tab {
          display: flex;
          align-items: center;
          border: 1px solid #c6c7d2;
          border-radius: 100px;
          .toggle-item {
            padding: 7px 20px;
            width: max-content;
            font-size: 14px;
            font-weight: 600;
            color: #242837;
            cursor: pointer;
          }
          .selected {
            background-color: #77649e;
            color: #fff;
            border-radius: 100px;
          }
        }
      }
    }
    .order-form {
      width: 50%;
      .custom-btn-order {
        padding: 12px 60px;
        background-color: var(--primary-color);
        border-radius: 8px;
        font-family: Roboto !important;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40px;
        cursor: pointer;
      }
      .custom-btn-order-2 {
        padding: 2px 60px;
        background-color: var(--primary-color);
        border-radius: 8px;
        font-family: Roboto !important;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: 40px;
      }
      .order-form-container {
        padding: 50px 40px;
        border: 1px solid #e4e9ee;
        margin-top: 24px;
        border-radius: 12px;
        background-color: #fff;
        .personal-header {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 600;
          line-height: 28px;
          text-align: left;
          color: #3b4159;
          margin-bottom: 24px;
        }
        .setsame-cont {
          margin-top: 30px;
          margin-bottom: 30px;
          .setsame-text {
            font-family: Roboto !important;
            font-size: 14px;
            font-weight: 500;
          }
        }

        .empty-cart {
          display: flex;
          justify-content: center;
          padding: 10% 0;
          .empty {
            .empty-text {
              font-size: 18px;
              font-weight: 400;
              line-height: 16px;
              color: #4d4d4d;
              text-align: center;
              font-family: Roboto !important;
            }
          }
        }
        .details-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #4d4d4d;
          display: flex;
          align-items: center;
        }
        .details-content {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #242837;
          display: flex;
          align-items: center;
          .eco-product-add-ctl {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 20px;
            background-color: #f0f1f4;
          }
        }
        .item-name {
          font-size: 15px;
          font-weight: 600;
          line-height: 7px;
          text-align: left;
        }
        .addon-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 20px;
          .addon-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.1px;
            text-align: left;
            color: #4d4d4d;
          }
          .addon-price {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;
            color: #242837;
          }
        }
      }
      .order-track-container {
        padding: 40px 40px;
        .order-error {
          color: #ff0000;
          font-size: 11px;
        }
        .order-track-left-container {
          .del-container {
            display: flex;
            column-gap: 16px;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            text-align: left;
            color: #0f172a;
            .address-track {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .loc-container {
              height: 32px;
              width: 32px;
              border-radius: 50%;
              background-color: #039be5;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 16px;
                width: 16px;
              }
            }
          }
          .track-steps-container {
            padding: 40px;
            background-color: #f9f9fa;
            width: 90%;
            margin-top: 26px;
            .border-track {
              height: 1px;
              background-color: #e2e8f0;
              width: 95%;
              margin-top: 32px;
              margin-left: 48px;
              margin-bottom: 32px;
            }
            .steps-container {
              display: flex;
              column-gap: 16px;
              font-size: 16px;
              font-weight: 700;
              line-height: 28px;
              text-align: left;
              color: #0f172a;
              position: relative;
              .content-track {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                margin-top: 8px;
              }
              .left-border-step {
                position: absolute;
                width: 2px;
                height: 64px;
                background-color: #039be5;
                top: 42px;
                left: 16px;
              }
              .loc-container {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: #039be5;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                color: #ffffff;
              }
            }
          }
          .trck-btn-container {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 47px;
            column-gap: 12px;
            .sec-btn {
              width: 140px;
              height: 35px;
            }
          }
        }
        .order-track-right-container {
          width: 100%;
          .restaurant-details {
            margin-top: 48px;
            .restp-header {
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0.005em;
              text-align: left;
              color: #111a2c;
              column-gap: 19px;
              background-color: #f1f1f4;
              padding: 12px 24px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              .loc-container {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: #039be5;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  height: 16px;
                  width: 16px;
                }
              }
            }
            .rest-container {
              background-color: #f9f9fa;
              padding: 24px;
              .order-status-rest {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e2e8f0;
                padding-bottom: 18px;
                .status-text {
                  font-size: 15px;
                  font-weight: 700;
                  line-height: 24px;
                  text-align: left;
                  color: #0f172a;
                }
                .rate-btn {
                  cursor: pointer;
                  color: #039be5;
                  font-size: 11px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: right;
                  padding: 0px 10px;
                  border: 1px solid #039be5;
                  border-radius: 20px;
                }
              }
              .order-items {
                .order-item {
                  margin-top: 24px;
                  display: flex;
                  align-items: center;
                  column-gap: 10px;
                  border-bottom: 1px solid #e2e8f0;
                  padding-bottom: 24px;
                  .item-image {
                    width: 70px;
                    border-radius: 4px;
                  }
                  .order-item-details {
                    .item-text {
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .item-qty {
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 20px;
                      text-align: left;
                      color: #475569;
                    }
                    .item-sub {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: #475569;
                    }
                  }
                }
              }
              .total-value-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 16px;
                padding-bottom: 32px;

                .total-value {
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  color: #0f172a;
                }
                .total-value-price {
                  font-family: "Inter";
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 32px;
                  text-align: right;
                  color: #0f172a;
                }
              }
            }
          }
        }
      }
    }
    .Order-confirmation-inner {
      display: flex;
      column-gap: 32px;
      .Order-confirmation-inner-left {
        width: 65%;
        .order-form-summary {
          .custom-btn-order {
            padding: 12px 60px;
            background-color: var(--primary-color);
            border-radius: 8px;
            font-family: Roboto !important;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            cursor: pointer;
          }
          .order-form-container {
            padding: 32px 24px;
            border: 1px solid #e4e9ee;
            margin-top: 24px;
            border-radius: 12px;
            background-color: #fff;
            .header-conater {
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-bottom: -16px;
              .header-conater-left {
                display: flex;
                align-items: center;
                column-gap: 10px;
                .form-header-text-cont {
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 600;
                  line-height: 28px;
                  text-align: left;
                  color: #101725;
                }
                .text-sub {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 500;
                  color: #101725;
                }
              }
              .header-conater-right {
                display: flex;
                align-items: center;
                .arrow-closed {
                  height: 15px;
                  width: 15px;
                  cursor: pointer;
                  transform: rotate(-90deg);
                }
                .arrow-open {
                  height: 15px;
                  width: 15px;
                  cursor: pointer;
                  transform: rotate(-180deg);
                }
              }
            }
            .form-header-text {
              font-family: Roboto !important;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #101725;
              margin-bottom: 16px;
            }
            .track-steps-container {
              margin-top: 32px;
              .steps-container-current-step {
                display: flex;
                column-gap: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
                color: #0f172a;
                position: relative;
                margin-bottom: 30px;
                .content-track {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #818b9c;
                  margin-top: 8px;
                }
                .content-head {
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 600;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: var(--primary-color);
                }
                .left-border-step {
                  position: absolute;
                  width: 2px;
                  height: 44px;
                  background-color: #cbd5e1;
                  left: 9px;
                  top: 28px;
                }
                .loc-container {
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  background-color: var(--primary-color);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 16px;
                  color: #ffffff;
                  .inner-loc {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #fff;
                  }
                }
              }
              .steps-container-completed-step {
                display: flex;
                column-gap: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
                color: #0f172a;
                position: relative;
                margin-bottom: 30px;
                .content-track {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #818b9c;
                  margin-top: 8px;
                }
                .content-head {
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 600;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #101725;
                }
                .left-border-step {
                  position: absolute;
                  width: 2px;
                  height: 44px;
                  background-color: #101725;
                  left: 9px;
                  top: 28px;
                }
                .loc-container {
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  background-color: #101725;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 16px;
                  color: #ffffff;
                  .inner-loc {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #fff;
                  }
                }
              }
              .steps-container-upcoming-step {
                display: flex;
                column-gap: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
                color: #0f172a;
                position: relative;
                margin-bottom: 30px;
                .content-track {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #818b9c;
                  margin-top: 8px;
                }
                .content-head {
                  font-family: Roboto !important;
                  font-size: 18px;
                  font-weight: 600;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #818b9c;
                }
                .left-border-step {
                  position: absolute;
                  width: 2px;
                  height: 44px;
                  background-color: #cbd5e1;
                  left: 9px;
                  top: 28px;
                }
                .loc-container {
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  background-color: #cbd5e1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 14px;
                  font-weight: 700;
                  line-height: 16px;
                  color: #ffffff;
                  .inner-loc {
                    height: 6px;
                    width: 6px;
                    border-radius: 50%;
                    background-color: #fff;
                  }
                }
              }
            }
            .address-conatinar {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .address-conatinar-left {
                display: flex;
                column-gap: 16px;
                .loc-cont {
                  height: 24px;
                  margin-top: 15px;
                }
                .customer-details-cont {
                  .customer-name-cont {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    .name-text {
                      font-family: Roboto !important;
                      font-size: 18px;
                      font-weight: 700;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #101725;
                    }
                    .address-tag {
                      font-family: Roboto !important;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 15.6px;
                      text-align: left;
                      color: #1d9e34;
                      padding: 5px 8px;
                      background: #1d9e341a;
                      border-radius: 4px;
                    }
                  }
                  .phone-number {
                    font-family: Roboto !important;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    color: #818b9c;
                    margin-top: 4px;
                  }
                  .address {
                    font-family: Roboto !important;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    color: #818b9c;
                    margin-top: 8px;
                  }
                }
              }
              .address-conatinar-right {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
                letter-spacing: -0.20000000298023224px;
                text-align: center;
                color: var(--primary-color);
                padding: 10px 16px;
                border: 1px solid #e4e9ee;
                border-radius: 8px;
                a {
                  color: var(--primary-color);
                  text-decoration: none !important;
                }
              }
            }
            .personal-header {
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #3b4159;
              margin-bottom: 24px;
            }
            .setsame-cont {
              margin-top: 30px;
              margin-bottom: 30px;
              .setsame-text {
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .order-cart-list {
              .order-cart-item {
                padding-top: 24px;
                padding-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e4e9ee;
                .order-cart-item-left {
                  display: flex;
                  align-items: center;
                  .cart-item-image-cont {
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f6f6f6;
                    border-radius: 8px;
                    margin-right: 20px;
                    .cart-item-image {
                      width: 84px;
                      height: 84px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center center;
                      border-radius: 4px;
                    }
                  }
                  .item-details-container-left {
                    .item-cart-title {
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #101725;
                    }
                    .modifiers-text {
                      text-transform: capitalize !important;
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 25.6px;
                      text-align: left;
                      color: #818b9c;
                    }
                    .item-cart-price {
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: var(--primary-color);
                      margin-top: 8px;
                    }
                  }
                }
                .order-cart-item-right {
                  display: flex;
                  align-items: center;
                  column-gap: 16px;
                  .item-cart-qty-container {
                    display: flex;
                    align-items: center;
                    border: 1px solid #e4e9ee;
                    width: 90px;
                    border-radius: 8px;
                    height: 35px;
                    .qty-cont {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 35px;
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 25.6px;
                      text-align: center;
                      color: #0b0f0e;
                    }
                    .quantity-ic-cont {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 35px;
                      cursor: pointer;
                      .quantity-ic {
                        filter: brightness(0) saturate(100%) invert(22%)
                          sepia(88%) saturate(1930%) hue-rotate(182deg)
                          brightness(101%) contrast(103%);
                      }
                    }
                  }
                  .cart-action {
                    width: 35px;
                    height: 35px;
                    border-radius: 8px;
                    border: 1px solid #e4e9ee;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                      height: 18px;
                      width: 18px;
                    }
                  }
                }
              }
            }
            .empty-cart {
              display: flex;
              justify-content: center;
              padding: 10% 0;
              .empty {
                .empty-text {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 16px;
                  color: #7a7a7a;
                  text-align: center;
                }
                .custom-btn-order {
                  padding: 12px 60px;
                  background-color: var(--primary-color);
                  border-radius: 8px;
                  font-family: Roboto !important;
                  font-size: 15px;
                  font-weight: 500;
                  line-height: 24px;
                  color: #ffffff;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-top: 40px;
                  cursor: pointer;
                }
              }
            }
            .details-label {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
              color: #4d4d4d;
              display: flex;
              align-items: center;
            }
            .details-content {
              font-size: 14px;
              font-weight: 600;
              line-height: 16px;
              text-align: left;
              color: #242837;
              display: flex;
              align-items: center;
              .eco-product-add-ctl {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 0 20px;
                background-color: #f0f1f4;
              }
            }
            .item-name {
              font-size: 15px;
              font-weight: 600;
              line-height: 7px;
              text-align: left;
            }
            .addon-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 20px;
              .addon-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.1px;
                text-align: left;
                color: #4d4d4d;
              }
              .addon-price {
                font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                text-align: left;
                color: #242837;
              }
            }
          }
          .order-history-container {
            border: 1px solid #e4e9ee;
            margin-top: 24px;
            border-radius: 12px;
            background-color: #fff;
            .order-history-conatiner-header {
              padding: 32px 24px;
              border-bottom: 1px solid #e4e9ee;
              display: grid;
              grid-template-columns: auto auto auto auto;
              .header-cont {
                .header-text {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 22.4px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #101725;
                }
                .sub-text {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 22.4px;
                  text-align: left;
                  color: #818b9c;
                  margin-top: 4px;
                }
                .center {
                  text-align: center;
                }
              }
              .end-cont {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                .details-btn {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 22.4px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: center;
                  color: var(--primary-color);
                  padding: 10px 16px;
                  border: 1px solid #e4e9ee;
                  border-radius: 8px;
                  cursor: pointer;
                  width: 100%;
                }
              }
            }
            .order-history-details-container {
              padding: 32px 24px;
              .status-header {
                font-family: Roboto !important;
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
                text-align: left;
                color: #101725;
                text-transform: capitalize !important;
              }
              .items-list-container {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #e4e9ee;
                padding: 24px 0px;
                .items-list-container-left {
                  display: flex;
                  column-gap: 24px;
                  .image-container {
                    height: 100px;
                    width: 100px;
                    background: #f6f6f6;
                    border-radius: 8px;
                    padding: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .image-div {
                      width: 84px;
                      height: 84px;
                      border-radius: 4px;
                    }
                  }
                  .item-details {
                    .name {
                      font-family: Roboto !important;
                      font-size: 20px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #101725;
                    }
                    .des-val {
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 25.6px;
                      text-align: left;
                      color: #818b9c;
                      margin-top: 4px;
                    }
                    .price {
                      font-family: Roboto !important;
                      font-size: 20px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: var(--primary-color);
                      margin-top: 12px;
                    }
                  }
                }
                .items-list-container-right {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 20px;
                  text-align: left;
                  color: #434e58;
                }
              }
            }
          }
          .order-track-container {
            padding: 40px 40px;
            .order-error {
              color: #ff0000;
              font-size: 11px;
            }
            .order-track-left-container {
              .del-container {
                display: flex;
                column-gap: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
                color: #0f172a;
                .address-track {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 8px;
                }
                .loc-container {
                  height: 32px;
                  width: 32px;
                  border-radius: 50%;
                  background-color: #039be5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
              .track-steps-container {
                padding: 40px;
                background-color: #f9f9fa;
                width: 90%;
                margin-top: 26px;
                .border-track {
                  height: 1px;
                  background-color: #e2e8f0;
                  width: 95%;
                  margin-top: 32px;
                  margin-left: 48px;
                  margin-bottom: 32px;
                }
                .steps-container {
                  display: flex;
                  column-gap: 16px;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: left;
                  color: #0f172a;
                  position: relative;
                  .content-track {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 8px;
                  }
                  .left-border-step {
                    position: absolute;
                    width: 2px;
                    height: 64px;
                    background-color: #039be5;
                    top: 42px;
                    left: 16px;
                  }
                  .loc-container {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    background-color: #039be5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    color: #ffffff;
                  }
                }
              }
              .trck-btn-container {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 47px;
                column-gap: 12px;
                .sec-btn {
                  width: 140px;
                  height: 35px;
                }
              }
            }
            .order-track-right-container {
              width: 100%;
              .restaurant-details {
                margin-top: 48px;
                .restp-header {
                  display: flex;
                  align-items: center;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.005em;
                  text-align: left;
                  color: #111a2c;
                  column-gap: 19px;
                  background-color: #f1f1f4;
                  padding: 12px 24px;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  .loc-container {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    background-color: #039be5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      height: 16px;
                      width: 16px;
                    }
                  }
                }
                .rest-container {
                  background-color: #f9f9fa;
                  padding: 24px;
                  .order-status-rest {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e2e8f0;
                    padding-bottom: 18px;
                    .status-text {
                      font-size: 15px;
                      font-weight: 700;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .rate-btn {
                      cursor: pointer;
                      color: #039be5;
                      font-size: 11px;
                      font-weight: 700;
                      line-height: 28px;
                      text-align: right;
                      padding: 0px 10px;
                      border: 1px solid #039be5;
                      border-radius: 20px;
                    }
                  }
                  .order-items {
                    .order-item {
                      margin-top: 24px;
                      display: flex;
                      align-items: center;
                      column-gap: 10px;
                      border-bottom: 1px solid #e2e8f0;
                      padding-bottom: 24px;
                      .item-image {
                        width: 70px;
                        border-radius: 4px;
                      }
                      .order-item-details {
                        .item-text {
                          font-size: 16px;
                          font-weight: 600;
                          line-height: 24px;
                          text-align: left;
                          color: #0f172a;
                        }
                        .item-qty {
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 20px;
                          text-align: left;
                          color: #475569;
                        }
                        .item-sub {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: left;
                          color: #475569;
                        }
                      }
                    }
                  }
                  .total-value-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 16px;
                    padding-bottom: 32px;

                    .total-value {
                      font-family: "Inter";
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .total-value-price {
                      font-family: "Inter";
                      font-size: 24px;
                      font-weight: 600;
                      line-height: 32px;
                      text-align: right;
                      color: #0f172a;
                    }
                  }
                }
              }
            }
          }
        }
        .order-form-summary-payment {
          .custom-btn-order {
            padding: 12px 60px;
            background-color: var(--primary-color);
            border-radius: 8px;
            font-family: Roboto !important;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;
            cursor: pointer;
          }
          .order-form-container {
            padding: 32px 24px;
            border: 1px solid #e4e9ee;
            margin-top: 24px;
            border-radius: 12px;
            background-color: #fff;
            .form-header-text {
              font-family: Roboto !important;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #101725;
              margin-bottom: 16px;
            }
            .payment-method {
              border-bottom: 1px solid #e4e9ee;
              padding-top: 20px;
              padding-bottom: 20px;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              line-height: 22.4px;
              letter-spacing: -0.20000000298023224px;
              text-align: left;
              color: #101725;
              display: flex;
              align-items: center;
              column-gap: 16px;
              cursor: pointer;
              .radio-btn {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                border: 1.5px solid #c4c8cc;
              }
              .radio-btn-selected {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background-color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                .radio-btn-selected-inner {
                  height: 6px;
                  width: 6px;
                  border-radius: 50%;
                  background: #fff;
                }
              }
              .itm-cont {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 52px;
                width: 100px;
                background-color: #f6f6f6;
                border-radius: 8px;
                img {
                  height: 30px;
                }
              }
            }
            .payment-method-2 {
              padding-top: 20px;
              padding-bottom: 20px;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              line-height: 22.4px;
              letter-spacing: -0.20000000298023224px;
              text-align: left;
              color: #101725;
              display: flex;
              align-items: center;
              column-gap: 16px;
              cursor: pointer;
              .itm-cont {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 52px;
                width: 100px;
                background-color: #f6f6f6;
                border-radius: 8px;
                img {
                  height: 25px;
                }
              }
              .radio-btn {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                border: 1.5px solid #c4c8cc;
              }
              .radio-btn-selected {
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background-color: var(--primary-color);
                display: flex;
                align-items: center;
                justify-content: center;
                .radio-btn-selected-inner {
                  height: 6px;
                  width: 6px;
                  border-radius: 50%;
                  background: #fff;
                }
              }
            }
            .address-conatinar {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .address-conatinar-left {
                display: flex;
                column-gap: 16px;
                .loc-cont {
                  height: 24px;
                  margin-top: 15px;
                }
                .customer-details-cont {
                  .customer-name-cont {
                    display: flex;
                    align-items: center;
                    column-gap: 12px;
                    .name-text {
                      font-family: Roboto !important;
                      font-size: 18px;
                      font-weight: 700;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #101725;
                    }
                    .address-tag {
                      font-family: Roboto !important;
                      font-size: 12px;
                      font-weight: 400;
                      line-height: 15.6px;
                      text-align: left;
                      color: #1d9e34;
                      padding: 5px 8px;
                      background: #1d9e341a;
                      border-radius: 4px;
                    }
                  }
                  .phone-number {
                    font-family: Roboto !important;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    color: #818b9c;
                    margin-top: 4px;
                  }
                  .address {
                    font-family: Roboto !important;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 25.6px;
                    text-align: left;
                    color: #818b9c;
                    margin-top: 8px;
                  }
                }
              }
              .address-conatinar-right {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 700;
                line-height: 22.4px;
                letter-spacing: -0.20000000298023224px;
                text-align: center;
                color: var(--primary-color);
                padding: 10px 16px;
                border: 1px solid #e4e9ee;
                border-radius: 8px;
                a {
                  color: var(--primary-color);
                  text-decoration: none !important;
                }
              }
            }
            .personal-header {
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #3b4159;
              margin-bottom: 24px;
            }
            .setsame-cont {
              margin-top: 30px;
              margin-bottom: 30px;
              .setsame-text {
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .order-cart-list {
              .order-cart-item {
                padding-top: 24px;
                padding-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e4e9ee;
                .order-cart-item-left {
                  display: flex;
                  align-items: center;
                  .cart-item-image-cont {
                    width: 100px;
                    height: 100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f6f6f6;
                    border-radius: 8px;
                    margin-right: 20px;
                    .cart-item-image {
                      width: 84px;
                      height: 84px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center center;
                      border-radius: 4px;
                    }
                  }
                  .item-details-container-left {
                    .item-cart-title {
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #101725;
                    }
                    .modifiers-text {
                      text-transform: capitalize !important;
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 25.6px;
                      text-align: left;
                      color: #818b9c;
                    }
                    .item-cart-price {
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 28px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: var(--primary-color);
                      margin-top: 8px;
                    }
                  }
                }
                .order-cart-item-right {
                  display: flex;
                  align-items: center;
                  column-gap: 16px;
                  .item-cart-qty-container {
                    display: flex;
                    align-items: center;
                    border: 1px solid #e4e9ee;
                    width: 90px;
                    border-radius: 8px;
                    height: 35px;
                    .qty-cont {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 35px;
                      font-family: Roboto !important;
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 25.6px;
                      text-align: center;
                      color: #0b0f0e;
                    }
                    .quantity-ic-cont {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      width: 30px;
                      height: 35px;
                      cursor: pointer;
                      .quantity-ic {
                        filter: brightness(0) saturate(100%) invert(22%)
                          sepia(88%) saturate(1930%) hue-rotate(182deg)
                          brightness(101%) contrast(103%);
                      }
                    }
                  }
                  .cart-action {
                    width: 35px;
                    height: 35px;
                    border-radius: 8px;
                    border: 1px solid #e4e9ee;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    img {
                      height: 18px;
                      width: 18px;
                    }
                  }
                }
              }
            }
            .empty-cart {
              display: flex;
              justify-content: center;
              padding: 10% 0;
              .empty {
                .empty-text {
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 16px;
                  color: #4d4d4d;
                  text-align: center;
                }
              }
            }
            .details-label {
              font-size: 12px;
              font-weight: 400;
              line-height: 16px;
              text-align: left;
              color: #4d4d4d;
              display: flex;
              align-items: center;
            }
            .details-content {
              font-size: 14px;
              font-weight: 600;
              line-height: 16px;
              text-align: left;
              color: #242837;
              display: flex;
              align-items: center;
              .eco-product-add-ctl {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin: 0 20px;
                background-color: #f0f1f4;
              }
            }
            .item-name {
              font-size: 15px;
              font-weight: 600;
              line-height: 7px;
              text-align: left;
            }
            .addon-container {
              display: flex;
              justify-content: space-between;
              align-items: center;
              column-gap: 20px;
              .addon-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 19.1px;
                text-align: left;
                color: #4d4d4d;
              }
              .addon-price {
                font-family: Inter;
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                text-align: left;
                color: #242837;
              }
            }
          }
          .order-track-container {
            padding: 40px 40px;
            .order-error {
              color: #ff0000;
              font-size: 11px;
            }
            .order-track-left-container {
              .del-container {
                display: flex;
                column-gap: 16px;
                font-size: 16px;
                font-weight: 700;
                line-height: 28px;
                text-align: left;
                color: #0f172a;
                .address-track {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  margin-top: 8px;
                }
                .loc-container {
                  height: 32px;
                  width: 32px;
                  border-radius: 50%;
                  background-color: #039be5;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  img {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
              .track-steps-container {
                padding: 40px;
                background-color: #f9f9fa;
                width: 90%;
                margin-top: 26px;
                .border-track {
                  height: 1px;
                  background-color: #e2e8f0;
                  width: 95%;
                  margin-top: 32px;
                  margin-left: 48px;
                  margin-bottom: 32px;
                }
                .steps-container {
                  display: flex;
                  column-gap: 16px;
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: left;
                  color: #0f172a;
                  position: relative;
                  .content-track {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 8px;
                  }
                  .left-border-step {
                    position: absolute;
                    width: 2px;
                    height: 64px;
                    background-color: #039be5;
                    top: 42px;
                    left: 16px;
                  }
                  .loc-container {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    background-color: #039be5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 16px;
                    color: #ffffff;
                  }
                }
              }
              .trck-btn-container {
                width: 90%;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 47px;
                column-gap: 12px;
                .sec-btn {
                  width: 140px;
                  height: 35px;
                }
              }
            }
            .order-track-right-container {
              width: 100%;
              .restaurant-details {
                margin-top: 48px;
                .restp-header {
                  display: flex;
                  align-items: center;
                  font-size: 18px;
                  font-weight: 700;
                  line-height: 20px;
                  letter-spacing: 0.005em;
                  text-align: left;
                  color: #111a2c;
                  column-gap: 19px;
                  background-color: #f1f1f4;
                  padding: 12px 24px;
                  border-top-left-radius: 8px;
                  border-top-right-radius: 8px;
                  .loc-container {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    background-color: #039be5;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                      height: 16px;
                      width: 16px;
                    }
                  }
                }
                .rest-container {
                  background-color: #f9f9fa;
                  padding: 24px;
                  .order-status-rest {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #e2e8f0;
                    padding-bottom: 18px;
                    .status-text {
                      font-size: 15px;
                      font-weight: 700;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .rate-btn {
                      cursor: pointer;
                      color: #039be5;
                      font-size: 11px;
                      font-weight: 700;
                      line-height: 28px;
                      text-align: right;
                      padding: 0px 10px;
                      border: 1px solid #039be5;
                      border-radius: 20px;
                    }
                  }
                  .order-items {
                    .order-item {
                      margin-top: 24px;
                      display: flex;
                      align-items: center;
                      column-gap: 10px;
                      border-bottom: 1px solid #e2e8f0;
                      padding-bottom: 24px;
                      .item-image {
                        width: 70px;
                        border-radius: 4px;
                      }
                      .order-item-details {
                        .item-text {
                          font-size: 16px;
                          font-weight: 600;
                          line-height: 24px;
                          text-align: left;
                          color: #0f172a;
                        }
                        .item-qty {
                          font-size: 14px;
                          font-weight: 600;
                          line-height: 20px;
                          text-align: left;
                          color: #475569;
                        }
                        .item-sub {
                          font-size: 14px;
                          font-weight: 400;
                          line-height: 20px;
                          text-align: left;
                          color: #475569;
                        }
                      }
                    }
                  }
                  .total-value-text {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-top: 16px;
                    padding-bottom: 32px;

                    .total-value {
                      font-family: "Inter";
                      font-size: 16px;
                      font-weight: 400;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .total-value-price {
                      font-family: "Inter";
                      font-size: 24px;
                      font-weight: 600;
                      line-height: 32px;
                      text-align: right;
                      color: #0f172a;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .Order-confirmation-inner-right {
        width: 35%;
        .order-form-summary {
          .order-form-container {
            padding: 32px 24px;
            border: 1px solid #e4e9ee;
            margin-top: 24px;
            border-radius: 12px;
            background-color: #fff;
            .form-header-text {
              font-family: Roboto !important;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #101725;
              margin-bottom: 24px;
            }
            .order-cart-list {
              padding: 16px;
              border: 1px solid #e4e9ee;
              border-radius: 12px;
              .order-cart-item {
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                .order-cart-item-left {
                  display: flex;
                  align-items: center;
                  .cart-item-image-cont {
                    width: 56px;
                    height: 56px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 8px;
                    margin-right: 12px;
                    .cart-item-image {
                      width: 56px;
                      height: 56px;
                      background-repeat: no-repeat;
                      background-size: cover;
                      background-position: center center;
                      border-radius: 4px;
                    }
                  }
                  .item-details-container-left {
                    .item-cart-title {
                      font-family: Roboto !important;
                      font-size: 12px;
                      font-weight: 700;
                      line-height: 16.8px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: #0b0f0e;
                    }
                    .modifiers-text {
                      font-family: Roboto !important;
                      font-size: 10px;
                      font-weight: 400;
                      line-height: 16px;
                      text-align: left;

                      color: #818b9c;
                    }
                    .item-cart-price {
                      font-family: Roboto !important;
                      font-size: 12px;
                      font-weight: 800;
                      line-height: 16.8px;
                      letter-spacing: -0.20000000298023224px;
                      text-align: left;
                      color: var(--primary-color);
                      margin-top: 8px;
                    }
                  }
                }
                .order-cart-item-right {
                  display: flex;
                  align-items: end;
                  .qty-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 25px;
                    width: 25px;
                    background: #1017251a;
                    border-radius: 50%;
                    font-family: Roboto !important;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16.8px;
                    letter-spacing: -0.20000000298023224px;
                    text-align: left;
                    color: #0b0f0e;
                  }
                }
              }
            }
            .header-conater {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding-bottom: 16px;
              .header-conater-left {
                display: flex;
                align-items: center;
                column-gap: 10px;
                img {
                  height: 20px;
                }
                .form-header-text-cont {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 25.6px;
                  text-align: left;
                  color: #101725;
                }
                .text-sub {
                  font-family: Roboto !important;
                  font-size: 14px;
                  font-weight: 500;
                  color: #7c8091;
                }
              }
              .header-conater-right {
                display: flex;
                align-items: center;
                .arrow-closed {
                  height: 15px;
                  width: 15px;
                  cursor: pointer;
                  transform: rotate(-90deg);
                  filter: brightness(0) saturate(100%) invert(9%) sepia(25%)
                    saturate(988%) hue-rotate(180deg) brightness(91%)
                    contrast(99%);
                }
                .arrow-open {
                  height: 15px;
                  width: 15px;
                  cursor: pointer;
                  // transform: rotate(-180deg);
                  filter: brightness(0) saturate(100%) invert(9%) sepia(25%)
                    saturate(988%) hue-rotate(180deg) brightness(91%)
                    contrast(99%);
                }
              }
            }
            .product-summary {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              line-height: 25.6px;
              text-align: left;
              color: #101725;
              margin-bottom: 16px;
            }
            .price-list {
              border-top: 1px solid #e4e9ee;
              margin-top: 8px;
              padding-top: 24px;
              .price-list-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .price-list-inner-left {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #818b9c;
                }
                .price-list-inner-right {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #101725;
                }
              }
              .price-list-inner-total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .total-value-text {
                  font-family: Roboto !important;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 28px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #101725;
                }
              }
            }
            .custom-btn-order {
              padding: 12px 60px;
              background-color: var(--primary-color);
              border-radius: 8px;
              font-family: Roboto !important;
              font-size: 15px;
              font-weight: 500;
              line-height: 24px;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 40px;
              cursor: pointer;
              width: 100%;
            }
          }
        }
      }
    }

    .floor-dropdown {
      margin-bottom: 24px;
      .floor-drop-container {
        display: flex;
        align-items: center;
        column-gap: 16px;
        .label {
          font-family: Roboto !important;
          font-size: 15px;
          font-weight: 500;
          line-height: 20.46px;
          color: #101725;
        }
        .select-floor {
          width: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #fff;
          border: none;
          border-radius: 3px;
          outline: none;
          border: 1px solid #d1d8dd;
        }
      }
    }
  }
}
.Voucher-Order-success-form-deafult {
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .overlayer {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 10;
    background-color: #00000085;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    margin: 0;
    .order-placing {
      font-family: Roboto !important;
      font-size: 1.8rem;
      color: #fff;
      font-weight: 400;
    }
  }
  .sucessIc {
    height: 115px;
    width: 115px;
    margin-bottom: 44px;
  }
  .main-message-text {
    font-family: Roboto !important;
    font-size: 32px;
    font-weight: 600;
    line-height: 44.8px;
    letter-spacing: -0.20000000298023224px;
    text-align: center;
    color: #0b0f0e;
  }
  .sub-message-text-cont {
    font-family: Roboto !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.75px;
    text-align: center;
    color: #434e58;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .card-payment-desc-text {
    font-family: Roboto !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 25.6px;
    text-align: center;
    color: #818b9c;
    margin-top: 10px;
  }
  .btn-conatiner {
    display: flex;
    column-gap: 32px;
    .menu-btn {
      margin-top: 32px;
      padding: 12px 32px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: -0.20000000298023224px;
      text-align: center;
      color: #ffffff;
      background-color: var(--primary-color);
      border-radius: 8px;
      cursor: pointer;
    }
    .menu-btn-sec {
      margin-top: 32px;
      padding: 12px 32px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 22.4px;
      letter-spacing: -0.20000000298023224px;
      text-align: center;
      color: var(--primary-color);
      background-color: #fff;
      border-radius: 8px;
      cursor: pointer;
      border: 1px solid var(--primary-color);
    }
  }
}
.eco-label label {
  font-family: Roboto !important;
  color: #666;
  font-size: 14px !important;
  line-height: 1.6rem;
  padding: 0;
  position: relative;
}
@media only screen and (max-width: 600px) {
  .VoucherDefaultOrderPage {
    padding: 0px;
    .overlayer {
      margin-left: 0px;
    }
    .Order-confirmation {
      margin: 20px;
      .Order-confirmation-header {
        .company-container {
          display: none;
        }
        .header-text-cont {
          font-size: 24px;
        }
      }
      .order-form {
        width: 100%;
        .order-form-container {
          padding: 28px 19px;
        }
      }
      .Order-confirmation-inner {
        .Order-confirmation-inner-left {
          .order-form-summary {
            .order-history-container {
              .order-history-conatiner-header {
                display: block;
                .header-cont {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  margin-bottom: 10px;
                  .center {
                    text-align: left;
                  }
                }
                .end-cont {
                  justify-content: center;
                }
              }
              .order-history-details-container {
                .status-header {
                  font-size: 16px;
                }
                .items-list-container {
                  .items-list-container-left {
                    column-gap: 8px;
                    .image-container {
                      width: 56px;
                      height: 56px;
                      border-radius: 8px;
                      padding: 0px;
                      .image-div {
                        width: 56px;
                        height: 56px;
                        border-radius: 8px;
                      }
                    }
                    .item-details {
                      .name {
                        font-family: Roboto !important;
                        font-size: 12px;
                        font-weight: 700;
                        line-height: 16.8px;
                        letter-spacing: -0.20000000298023224px;
                        text-align: left;
                      }
                      .des-val {
                        font-family: Roboto !important;
                        font-size: 10px;
                        font-weight: 400;
                        line-height: 16px;
                        text-align: left;
                        margin-top: 0px;
                        span {
                          font-size: 10px;
                        }
                      }
                      .price {
                        font-family: Roboto !important;
                        font-size: 12px;
                        font-weight: 800;
                        line-height: 16.8px;
                        letter-spacing: -0.20000000298023224px;
                        text-align: left;
                        margin-top: 0px;
                      }
                    }
                  }
                  .items-list-container-right {
                    font-family: Roboto !important;
                    font-size: 12px;
                    font-weight: 700;
                    line-height: 16.8px;
                    letter-spacing: -0.20000000298023224px;
                    text-align: left;
                  }
                }
              }
            }
          }
        }
      }
    }
    .Order-confirmation-inner {
      display: block !important;
      margin: -20px;
      .Order-confirmation-inner-left {
        width: 94% !important;
        margin: auto;
        .order-form-summary {
          .order-form-container {
            .address-conatinar {
              display: block !important;
              .address-conatinar-right {
                margin-top: 24px;
              }
            }
            .order-cart-list {
              .order-cart-item {
                display: block !important;
                .order-cart-item-right {
                  margin-top: 16px;
                  margin-left: 120px;
                }
              }
            }
          }
        }
        .order-form-summary-payment {
          .order-form-container {
            padding: 32px 24px;
            border: 1px solid #e4e9ee;
            margin-top: 24px;
            border-radius: 12px;
            background-color: #fff;
            .form-header-text {
              font-family: Roboto !important;
              font-size: 18px;
              font-weight: 600;
              line-height: 28px;
              text-align: left;
              color: #101725;
              margin-bottom: 24px;
            }
            .product-summary {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              line-height: 25.6px;
              text-align: left;
              color: #101725;
              margin-bottom: 16px;
            }
            .price-list {
              border-top: 1px solid #e4e9ee;
              margin-top: 8px;
              padding-top: 24px;
              .price-list-inner {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .price-list-inner-left {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #818b9c;
                }
                .price-list-inner-right {
                  font-family: Roboto !important;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 25.6px;
                  text-align: left;
                  color: #101725;
                }
              }
              .price-list-inner-total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 16px;
                .total-value-text {
                  font-family: Roboto !important;
                  font-size: 20px;
                  font-weight: 700;
                  line-height: 28px;
                  letter-spacing: -0.20000000298023224px;
                  text-align: left;
                  color: #101725;
                }
              }
            }
            .custom-btn-order {
              padding: 12px 60px;
              background-color: var(--primary-color);
              border-radius: 8px;
              font-family: Roboto !important;
              font-size: 15px;
              font-weight: 500;
              line-height: 24px;
              color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 40px;
              cursor: pointer;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
      .Order-confirmation-inner-right {
        width: 100% !important;
      }
    }
  }
  .Voucher-Order-success-form-deafult {
    width: 90%;
    margin: auto;
    .sucessIc {
      height: 80px;
      width: 80px;
      margin-bottom: 20px;
    }
    .main-message-text {
      font-size: 20px;
      margin-bottom: 10px;
    }
    .card-payment-desc-text {
      font-size: 14px;
    }
  }
}
