.contact__us__container {
  padding: 12px 24px 24px 24px;
  margin: 48px 220px;

}

.submit__btn {
  float: right;
  background-color: #5E81F4 !important;
  color: aliceblue !important;
  border: #5E81F4 !important;
}

.carousel-img {
  width: 100% !important;
  height: 400px;  
}



.react-video-player {
  width: 100% !important;
  height: 100% !important;
  /* padding: 20px !important; */
}

.react-video-player > video{
  object-fit: fill;
}

/* PRICING PLAN CSS  */
.eco-header {
  height: 66px !important;
}

.add-company-btn .eco-icon {
  fill: #fff !important;
}

.green-icon {
  fill: #2BD67B !important;
}

.change-btn {
  background-color: #81838C !important;
  color: #fff !important;
}

.app-card-content {
  background-color: #fff;
  border-radius: 4px;
  height: 90% !important;
  padding: 10px;
}

.buy-more-btn {
  color: #039BE5 !important;
  border-color: #039BE5 !important;
}

.filter-section {
  background-color: #fff;
  width: 50%;
  height: 70px;
  border-radius: 3px;
  margin: -32px auto auto;
  z-index: 1;
}

.ant-collapse-header-text,
.ant-collapse-content {
  text-align: start;
  font-weight: 600;
}

.eco-app-content {
  max-height: calc(100vh - 100px);
}

.no-border-card {
  border: none !important;
  height: 90%;
  padding: 10px;
}

.org-overview-content {
  min-height: calc(100vh - 100px);
  background-color: #F5F5F5;
  padding: 20px;
  height: auto;
  width: 100%;
}

.category-content {
  min-height: calc(100vh - 100px);
  padding: 20px;
  height: auto;
  width: 40%;
}

.prev-btn {
  color: #039BE5 !important;
}

.prev-btn .eco-icon {
  fill: #039BE5 !important;
}

.next-btn .eco-icon {
  fill: #fff !important;
}

.disabled-label {
  color: #d6d6d6 !important;
}

.squared-pill {
  border-radius: 3px !important;
  color: #039BE5 !important;
}

.detail-page {
  margin: 20px;
}

.add-btn {
  background-color: #EEC446 !important;
  border-color: #EEC446 !important;
  color: #fff !important;
  width: 200px;
}

.image-card {
  width: 75px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.app-card-container {
  width: 70%;
  max-height: 50vh;
  overflow-y: auto;
  padding: 10px;
}

.app-card {
  width: 100%;
  margin: 10px 5px;
}

.app-card-container::-webkit-scrollbar,.accordian-section::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

.plan-card {
  background-color: #FAFAFA;
}

.plan-card-selected {
  background-color: #101C3D;
  color: #fff !important;
}

.green-tag {
  background-color: #2BD67B !important;
  color: #fff !important;
}

.control-arrow {
  /* background-color: #81838C !important;
   */
   opacity: 1!important;
}

.org-tag {
  background-color: #101C3D !important;
  color: #fff !important;
  width: 120px !important;
  height: 30px !important;

  display: flex;
  justify-content: center;
}

.org-tag-update {
  background-color: transparent !important;

  color: rgb(0, 0, 0) !important;
  width: 120px !important;
  height: 30px !important;

  display: flex;
  justify-content: center;
}

.tier-tag {
  background-color: #FFA800 !important;
  color: #fff !important;

  width: 83px !important;
  height: 30px !important;

  display: flex;
  justify-content: center;
}

.tier-tag-card {
  background-color: #2BD67B !important;
  color: #fff !important;

  width: 97px !important;
  height: 27px !important;

  display: flex;
  justify-content: center;
}

.gray-tag {
  background-color: #E9EEF3 !important;
  color: #000 !important;
  font-weight: 600 !important;
}

.pricing-plan-page {
  padding: 40px 10px;
}

.pricing-plan-table td {
  border-right: 1px solid #cecece;
  border-bottom: 1px solid #cecece;
}

.pricing-plan-table th {
  padding: 15px 10px;
}

.pricing-plan-table th {
  padding: 15px 10px;
  border-right: 1px solid #cecece;
  text-align: center;
}

.select-plan-btn {
  color: #039BE5 !important;
  border-color: #039BE5 !important;
  border-radius: 20px !important;
  word-wrap: normal !important;
}

.current-plan-btn {
  color: #fff !important;
  border-color: #039BE5 !important;
  background-color: #039BE5 !important;
  border-radius: 20px !important;
  word-break: normal !important;
}

.select-plan-btn .eco-icon {
  fill: #039BE5 !important;
  word-wrap: normal !important;
}

.current-plan-btn .eco-icon {
  fill: #fff !important;
  word-break: normal !important;
}

.selected-tier {
  background-color: #101C3D !important;
  color: #fff !important;
}

.buy-btn {
  width: 150px;
}


.eco-stack.eco-stack--v-center.eco-stack--row.eco-toggle__control.eco-mb-8 {
  margin-bottom: 0px !important;
}

.table-container {
  margin: auto;
  width: 100%;

  padding: 10px;
}
.ant-tabs-tab{
  width: 100%;
  margin-left: 0px!important;
  text-align: center;
}
.ant-tabs-nav-list{
  width: 50%;
}
.ant-tabs-tab-btn{
  color: #1C1D21!important;
}
.selected-menu{
  background-color: #039BE5;
  border-color: #039BE5!important;
}
.long-btn{
  width: 100px;
}
.font-style-btn{
  width: 40px!important;
}
.font-style-btn .eco-button__content-wrapper{
  padding: 0 1.1rem!important;
}
.ant-popover{
  /* z-index: 200000!important; */
}
.show-layout-btn:focus{
  box-shadow: none!important;
  border-color: transparent!important;
}
.divider:hover{
  background-color: #E6F6FF;
}

/* PRICE PLAN   */
.price-spinner {
  margin: 20px 0 !important;
  margin-bottom: 120px !important;
  padding: 30px 50px !important;
  text-align: center !important;

  display: flex !important;
  justify-content: center !important;
}
/* PRICING PLAN CSS */


/* Text Editor */
.tox.tox-silver-sink.tox-tinymce-aux {
  position: relative;
  z-index: 1000000000
}
.custom-input, .custom-input:hover{
  background-color: #fff!important;
  border-color: transparent!important;
}
.accordian-section{
  max-height: 60vh;
  overflow-y: auto;
  padding: 5px;
}

.order-type-box{
  width: 96px;
  height: 96px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #5A5C72;
  border-radius: 4px;
}
.selected-order-type-box{
  background-color: #039BE5;
  border: none!important;
}

/* .Default .layout div div{ 
  padding-top: 0px !important;
} */

.eco-typography {
  word-break: normal !important;
}
