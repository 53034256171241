.Card {
  width: 100%;
  max-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  .card-container {
    width: 100%;
    .icon-conte {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-conatiner {
        height: 40px;
        width: 40px;
        margin: auto;
      }
    }

    .card-header-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;
      margin: auto;
      font-family: Lato;
      font-size: 16px;
      color: #111;
      font-weight: 600;
      margin-top: 20px;
    }
    .card-sub-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 85%;
      margin: auto;
      font-family: Lato;
      font-size: 15px;
      color: #999;
      margin-top: 10px;
    }
  }
}
