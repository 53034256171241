.HomeComponentThree {
  margin-top: 10vh;
  margin-bottom: 10vh;
  width: 100%;
  .details-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 26px;
    color: black;
    font-weight: 500;
    font-family: Jost;
    margin-bottom: 5vh;
  }
  .bridal-contet {
    width: 100%;
    height: 60vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    .biradal-content-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      width: max-content;
      padding: 8%;
      height: 100%;
      text-align: center;

      .header-casi {
        font-size: 44px;
        color: #f1e6f2;
        font-family: Jost;
        line-height: 70px;
      }
      .sub-text {
        font-size: 36px;
        color: #f1e6f2;
        font-family: Jost;
        font-weight: 300;
      }
      .button-containetr {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 4vh;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .HomeComponentThree {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .bridal-contet {
      height: 30vh;

      .biradal-content-text {
        width: 100%;
        padding: 0px;
        justify-content: center;
        height: 100%;
        .header-casi {
          font-size: 30px;
          line-height: 40px;
        }
        .sub-text {
          font-size: 22px;
        }
        .button-containetr {
          margin-top: 2vh;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .HomeComponentThree {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .bridal-contet {
      height: 30vh;

      .biradal-content-text {
        width: 100%;
        padding: 0px;
        justify-content: center;
        height: 100%;
        .header-casi {
          font-size: 30px;
          line-height: 40px;
        }
        .sub-text {
          font-size: 22px;
        }
        .button-containetr {
          margin-top: 2vh;
        }
      }
    }
  }
}
