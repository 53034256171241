.VoucherDefaultSingleItemPage {
  width: 80%;
  margin: auto;
  column-gap: 22px;
  padding-bottom: 10%;
  margin-top: 64px;
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .back-menu {
    margin-bottom: 32px;
    a {
      color: #0b0f0e;
      font-family: Roboto !important;
    }
  }
  .item-details {
    width: 100%;
    display: flex;
    .item-details-left {
      width: 100%;
      .item-image {
        height: 460px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .description {
        .item-details-tab {
          display: flex;
          width: 100%;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #3b4159;
          margin-top: 32px;
          .det-itm {
            width: 100px;
            padding-bottom: 12px;
            cursor: pointer;
          }
          .selected {
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);
            margin-bottom: -2px;
          }
        }
        .item-tab-desc {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #101725;
          margin-top: 24px;
          .nutri {
            font-family: Roboto !important;
            .nutri-title {
              font-family: Roboto !important;
              font-size: 17px;
              font-weight: 500;
              color: #0b0f0e;
            }
            .servings-container {
              margin-top: 25px;
              padding-bottom: 10px;
              display: flex;
              column-gap: 40px;
              .serving-text-cont {
                display: flex;
                flex-direction: column;
                .serving-text-head {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 30px;
                  letter-spacing: -0.4000000059604645px;
                  text-align: left;
                }
                .serving-text {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  letter-spacing: -0.4000000059604645px;
                  text-align: left;
                  color: #868686;
                }
              }
            }
            .cal-text {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .det {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              .serving-text {
                font-size: 16px;
                color: #818b9c;
              }
            }
          }
          .det {
            display: flex;
            column-gap: 10px;
            .item {
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              border-radius: 4px;
              padding: 6px 16px;
              border: 1px solid #3b4159;
              color: #3b4159;
            }
          }
        }
      }
    }

    .item-details-right {
      width: 100%;
      padding: 0 0 0 8%;
      .item-details-data {
        display: flex;
        justify-content: space-between;
        .vegan {
          padding: 8px 16px;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #fff;
          border: 1px solid #47b872;
          border-radius: 4px;
          background-color: #47b872;
        }
        .spice-cont {
          display: flex;
          align-items: center;
          .spice-level {
            font-family: Roboto !important;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #101725;
            width: 130px;
          }
          .spice-leve-img {
            display: flex;
            align-items: center;
          }
        }
      }
      .modifiers {
        margin-top: 71px;
        .modifier {
          .single-item {
            border-top: 1px solid #cccccc;
            width: 100%;
            padding: 20px 0 0px;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            text-align: left;

            color: #101725;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .left {
              display: flex;
              column-gap: 28px;
              .req {
                font-family: Roboto !important;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
                color: #4d4d4d;
                width: 80px;
                border: 1px solid #4d4d4d;
                border-radius: 20px;
              }
            }
            .open {
              transform: rotate(-180deg);
            }
          }
          .res {
            padding-left: 30px;
            font-size: 16px;
            font-weight: 400;
            color: #3b4159;
            font-family: Roboto !important;
            margin-top: 10px;
          }
          .modifier-option-container {
            position: relative;
            padding-left: 30px;

            .modifier-details {
              padding: 20px 0;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              color: #242837;
              display: flex;
              align-items: center;
              .option-name {
                min-width: 300px;
                display: flex;
                .item-name {
                  min-width: 200px;
                }
                .price-val {
                  min-width: 100px;
                }
              }
              .qlt-option {
                display: flex;
                align-items: center;
                margin-left: 30px;
                .qty-cont {
                  width: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                }
                .icon-cont {
                  height: 20px;
                  width: 20px;
                  border: 1px solid #00000014;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  .icon {
                    height: 12px;
                    width: 12px;
                  }
                }
                .empty-icon-cont {
                  height: 20px;
                  width: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3f3f3;
                  border-radius: 4px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .icon {
                    height: 12px;
                    width: 12px;
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
              }
            }
            .option-modifier {
              .single-item {
                width: 100%;
                padding: 16px 26px;
                font-family: Roboto !important;
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                margin-top: 10px;
                border-top: 1px solid #cccccc;
                .open {
                  transform: rotate(-180deg);
                }
              }
              .modifier-details {
                padding: 16px 0;
                font-family: Roboto !important;
                font-size: 14px;
                font-weight: 500;
                color: #242837;
                display: flex;
                align-items: center;
                .option-name {
                  width: 170px;
                }
                .qlt-option {
                  display: flex;
                  align-items: center;
                  margin-left: 30px;
                  .qty-cont {
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .icon-cont {
                    height: 20px;
                    width: 20px;
                    border: 1px solid #00000014;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    .icon {
                      height: 12px;
                      width: 12px;
                    }
                  }
                  .empty-icon-cont {
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f3f3;
                    border-radius: 4px;
                    cursor: pointer;
                    border: 1px solid #e4e9ee;
                    .icon {
                      height: 12px;
                      width: 12px;
                      filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                        saturate(5%) hue-rotate(315deg) brightness(97%)
                        contrast(88%);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .comments-sec {
        margin-top: 60px;
        .comments-title {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #101725;
        }
        .text-area {
          margin-top: 8px;
          height: 200px;
          border: 1px solid #e4e9ee;
          resize: none;
          font-family: Roboto !important;
        }
      }
      .bottom-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 50px;
        .primary-btn {
          width: 250px;
          height: 36px;
          margin-right: 16px;
          font-family: Jost;
          font-size: 18px;
          font-weight: 700;
        }
        .sec-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 217px;
          height: 44px;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--primary-color);
          border-radius: 4px;
          cursor: pointer;
        }
        .quantity {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          .icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                saturate(1930%) hue-rotate(182deg) brightness(101%)
                contrast(103%);
            }
          }
          .empty-icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
            }
          }
          .qty-cont {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .description {
        display: none;
      }
      .title {
        font-family: Roboto !important;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        color: #101725;
      }
      .review {
        display: flex;
        align-items: center;
        margin-top: 9px;
        font-family: "Jost";
        color: #0b0f0e;
        font-size: 16px;
        font-weight: 400;
        column-gap: 8px;
        .star {
          height: 24px;
          width: 24px;
        }
      }
      .price-conatiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-price {
          font-family: Roboto !important;
          font-size: 24px;
          font-weight: 700;
          line-height: 33.6px;
          text-align: left;
          color: var(--primary-color);
          margin-top: 8px;
        }
        .quantity {
          display: none !important;
          align-items: center;
          justify-content: center;
          .icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(22%) sepia(88%)
                saturate(1930%) hue-rotate(182deg) brightness(101%)
                contrast(103%);
            }
          }
          .empty-icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
            }
          }

          .qty-cont {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      .details {
        font-family: Roboto !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #101725;
        margin-top: 24px;
        word-wrap: break-word;
      }
    }
  }
  .eco-textarea__wrapper .eco-textarea:focus,
  .eco-textarea__wrapper .eco-textarea:focus-visible {
    border-color: none !important;
    box-shadow: none !important;
    outline: none;
  }
}
@media only screen and (max-width: 600px) {
  .VoucherDefaultSingleItemPage {
    width: 90%;
    .item-details {
      display: block;
      .item-details-left {
        .item-image {
          width: 100% !important;
          height: 283px;
        }
        .description {
          display: none;
        }
      }
      .item-details-right {
        padding: 0px;
        margin-top: 32px;
        .title {
          font-family: Roboto !important;
          font-size: 24px;
          font-weight: 700;
          line-height: 28.8px;
          text-align: left;
        }
        .bottom-container {
          .quantity {
            display: none;
          }
          .sec-btn {
            width: 100%;
          }
        }
        .description {
          .item-details-tab {
            display: flex;
            width: 100%;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #3b4159;
            margin-top: 32px;
            .det-itm {
              width: 100px;
              padding-bottom: 12px;
              cursor: pointer;
            }
            .selected {
              color: var(--primary-color);
              border-bottom: 2px solid var(--primary-color);
              margin-bottom: -2px;
            }
          }
          .item-tab-desc {
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #101725;
            margin-top: 24px;
            .nutri {
              font-family: Roboto !important;
              .nutri-title {
                font-family: Roboto !important;
                font-size: 17px;
                font-weight: 500;
                color: #0b0f0e;
              }
              .servings-container {
                margin-top: 25px;
                padding-bottom: 10px;
                display: flex;
                column-gap: 40px;
                .serving-text-cont {
                  display: flex;
                  flex-direction: column;
                  .serving-text-head {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                  }
                  .serving-text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                    color: #868686;
                  }
                }
              }
              .cal-text {
                font-size: 16px;
                margin-bottom: 10px;
              }
              .det {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                .serving-text {
                  font-size: 16px;
                  color: #818b9c;
                }
              }
            }
            .det {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              .item {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                border-radius: 4px;
                padding: 6px 16px;
                border: 1px solid #3b4159;
                color: #3b4159;
              }
            }
          }
        }
        .price-conatiner {
          .quantity {
            display: flex !important;
          }
        }
      }
    }
  }
}
