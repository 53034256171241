.Hospitality-navbar-jack {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  .Hospitality-navbar-fixed-header {
    background-color: transparent;
    width: 100%;
    font-family: Roboto;
    height: 66px;
    display: flex;
    align-items: center;
    flex: 1;
    transition: background 0.3s ease-in-out;
    border-bottom: 1px solid #999;
    &.scrolled {
      background-color: #ffffff;
      .Hospitality-navbarLeft {
        .Hospitality-navbar-center {
          .header-title {
            color: #121212;
          }
        }
      }
      .Hospitality-navbar-right {
        .order-online-tab {
          .bell-container {
            filter: none;
          }
        }
        .order-hotline-tab {
          color: #121212;
          .phone-container {
            filter: none;
          }
        }
      }
      .Hospitality-navbar-mobile-right {
        .menu-icon {
          .menu-container {
            filter: none;
          }
        }
      }
    }
    .Hospitality-navbarLeft {
      display: flex;
      height: 100%;
      align-items: center;
      width: 50%;
      .Hospitality-navbar-left {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0px 15px;
        border-right: 1px solid #999;

        .logo-container {
          height: 40px;
          cursor: pointer;
          width: 60px;
        }
      }
      .Hospitality-navbar-center {
        display: flex;
        height: 100%;
        align-items: center;
        column-gap: 30px;
        padding-left: 30px;

        .header-title {
          font-family: "Lato";
          font-size: 11px;
          color: #fff;
          word-break: break-word;
          font-weight: 600;
          cursor: pointer;
          text-transform: uppercase;
          letter-spacing: 0.5px;
        }
      }
    }
    .Hospitality-navbar-mobile-right {
      height: 100%;
      align-items: center;
      column-gap: 30px;
      padding-right: 30px;
      width: 50%;
      justify-content: flex-end;
      display: none;
      .menu-icon {
        display: flex;
        height: 100%;
        align-items: center;
        cursor: pointer;
        .menu-container {
          height: 24px;
        }
      }
    }
    .Hospitality-navbar-right {
      display: flex;
      height: 100%;
      align-items: center;
      column-gap: 30px;
      padding-right: 30px;
      width: calc(50% - 30px);
      justify-content: flex-end;
      .order-online-tab {
        display: flex;
        height: 100%;
        align-items: center;
        padding: 0px 20px;
        border-left: 1px solid #999;
        border-right: 1px solid #999;
        cursor: pointer;
        .bell-container {
          height: 22px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
            saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
      }
      .order-hotline-tab {
        display: flex;
        height: 100%;
        align-items: center;
        font-family: "Lato";
        font-size: 11px;
        color: #fff;
        word-break: break-word;
        letter-spacing: 0.8px;
        font-weight: 600;
        column-gap: 10px;
        border-left: 1px solid #999;
        padding-left: 20px;
        .phone-container {
          height: 14px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(100%)
            saturate(0%) hue-rotate(288deg) brightness(102%) contrast(102%);
        }
      }
    }
  }
  .Hospitality-mobile-nav-conrainer {
    background-color: #fff;
    width: 100%;
    height: max-content;
    font-family: Roboto;
    flex: 1;
    transition: background 0.3s ease-in-out;
    border-bottom: 1px solid #999;
    padding: 20px 0px;
    .navbar-links {
      font-family: "Lato";
      font-size: 12px;
      color: #121212;
      word-break: break-word;
      font-weight: 500;
      cursor: pointer;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 8px 15px;
      display: flex;
      align-items: center;
      gap: 10px;
      .phone-container {
        height: 14px;
      }
      .bell-container {
        height: 22px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Hospitality-navbar-jack {
    position: fixed;
    .Hospitality-navbar-fixed-header {
      background-color: #fff;
      .Hospitality-navbarLeft {
        .Hospitality-navbar-left {
          border-right: none;
        }
        .Hospitality-navbar-center {
          display: none;
        }
      }
      .Hospitality-navbar-right {
        display: none;
      }
      .Hospitality-navbar-mobile-right {
        display: flex;
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Hospitality-navbar-jack {
    position: fixed;
    .Hospitality-navbar-fixed-header {
      background-color: #fff;
      .Hospitality-navbarLeft {
        .Hospitality-navbar-left {
          border-right: none;
        }
        .Hospitality-navbar-center {
          display: none;
        }
      }
      .Hospitality-navbar-right {
        display: none;
      }
      .Hospitality-navbar-mobile-right {
        display: flex;
      }
    }
  }
}
