.Ecommerce-navbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  .Ecommerce-navbar-fixed-header {
    background-color: #ffffff;
    width: 100%;
    font-family: Roboto;
    height: 86px;
    display: flex;
    align-items: center;
    flex: 1;
    transition: box-shadow 0.3s ease-in-out;
    &.scrolled {
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    .Ecommerce-navbar-left {
      display: flex;
      flex: 1;
      height: 100%;
      align-items: center;
      margin-left: 50px;
      column-gap: 15px;

      .logo-container {
        height: 66px;
        cursor: pointer;
        img {
          height: 66px;
        }
      }
      .action-icon {
        display: none;
        position: relative;
        cursor: pointer;
        .icon {
          height: 18px;
        }
      }
    }
    .Ecommerce-navbar-center {
      display: flex;
      flex: 2;
      height: 100%;
      align-items: center;
      justify-content: center;
      column-gap: 30px;

      .header-title {
        font-family: "Roboto";
        font-size: 16px;
        color: #121212;
        word-break: break-word;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .Ecommerce-navbar-right {
      display: flex;
      flex: 1;
      height: 100%;
      margin-right: 50px;
      align-items: center;
      justify-content: flex-end;
      column-gap: 15px;
      .action-icon {
        position: relative;
        cursor: pointer;
        .count-cont {
          position: absolute;
          top: -6px;
          height: 17px;
          width: 17px;
          right: -10px;
          background-color: #121212;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: Roboto;
          font-size: 11px;
          font-weight: 500;
        }
        .icon {
          height: 18px;
        }
      }
    }
  }
  .Ecommerce-mobile-navbar-drawer {
    position: fixed;
    height: 100dvh;
    width: 90%;
    top: 0;
    z-index: 100000;
    left: -100%;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: none;
    transition: left 0.3s ease;

    &.open {
      left: 0;
    }
    .Ecommerce-mobile-navbar-drawer-header {
      width: calc(100% - 20px);
      padding: 10px;
      background-color: #12121209;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 5vh;

      .logo-container {
        height: 50px;
        cursor: pointer;
        img {
          height: 50px;
        }
      }
      .action-icon {
        position: relative;
        cursor: pointer;
        .icon {
          height: 18px;
        }
      }
    }
    .drawer-menu {
      padding-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e4e4e4;
    }
  }
}
@media only screen and (max-width: 600px) {
  .Ecommerce-navbar {
    .Ecommerce-navbar-fixed-header {
      width: 100%;

      padding-left: 25px;
      padding-right: 25px;
      .Ecommerce-navbar-left {
        margin-left: 0px;
        .logo-container {
          height: 50px;
          img {
            height: 50px;
          }
        }
        .action-icon {
          display: block;
        }
      }
      .Ecommerce-navbar-center {
        display: none;
      }
      .Ecommerce-navbar-right {
        margin-right: 0px;
      }
    }
    .Ecommerce-mobile-navbar-drawer {
      display: block;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Ecommerce-navbar {
    .Ecommerce-navbar-fixed-header {
      width: 100%;
      padding-left: 25px;
      padding-right: 25px;
      .Ecommerce-navbar-left {
        margin-left: 0px;
        .logo-container {
          height: 50px;
          img {
            height: 50px;
          }
        }
        .action-icon {
          display: none;
        }
      }
      .Ecommerce-navbar-right {
        margin-right: 0px;
      }
    }
  }
}
