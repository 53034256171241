.HomeComponentOne {
  width: 100%;
  margin-top: 12vh;
  margin-bottom: 12vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: flex;
  .item-content {
    text-align: center;
    color: #121212;
    font-family: Roboto;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    width: 180px;
    justify-content: center;
    .item-conten-image {
      height: 70px;
      width: 70px;
      margin: auto;
      img {
        height: 70px;
        width: 70px;
      }
    }
    .item-content-text {
      color: #838383;
      font-family: Roboto;
      font-size: 16px;
      margin-top: 10px;
      font-weight: 400;
    }
  }
}
@media only screen and (max-width: 600px) {
  .HomeComponentOne {
    flex-direction: column;
    gap: 40px;
    margin-top: 5vh;
    margin-bottom: 5vh;
  }
}
