.radio-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .radio-input {
    display: none;
  }

  .radio-label {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #707070;
    font-family: Jost;
    cursor: pointer;

    .radio-custom {
      width: 16px;
      height: 16px;
      border: 2px solid #929292;
      border-radius: 50%;
      background-color: white;
      margin-right: 8px;
      position: relative;
      transition: border-color 0.3s, background-color 0.3s;

      &:after {
        content: "";
        width: 10px;
        height: 10px;
        background-color: #121212;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: none;
      }
    }

    &:hover .radio-custom {
      border-color: #121212;
    }
  }

  .radio-input:checked + .radio-label .radio-custom {
    border-color: #121212;
    color: #121212;

    &:after {
      display: block;
    }
  }
  .radio-input:checked + .radio-label {
    color: #121212;
  }
}
