.dropdown-casi {
  position: relative;
  font-family: Jost;
  font-size: 15px;
  color: #121212;
  &-header {
    background-color: #fff;
    border: 1px solid #e4e4e4;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: #f9f9f9;
    }
  }

  &-arrow {
    transition: transform 0.3s ease;
    color: #7c7c7c;
    &.open {
      transform: rotate(180deg);
    }
  }
  &-list {
    position: absolute;
    top: 100%;
    left: 0;
    width: calc(100% - 30px);
    background-color: #fff;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    margin: 5px 0 0;
    padding: 0;
    list-style: none;
    z-index: 1000;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    li {
      padding: 10px;
      cursor: pointer;

      &:hover {
        background-color: #f9f9f9;
      }
    }
  }
}
