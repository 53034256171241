.CodeInput {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  .code-input-styles {
    border: 1px solid #d1d8dd;
    height: 46px;
    width: 46px;
    outline: none;
    text-align: center;
    font-size: 18px;
    font-family: Roboto !important;
    border-radius: 6px;
  }
}
