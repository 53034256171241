.EcommerceSinglePage {
  display: flex;
  flex: 1;
  width: 80%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 60px;
  .EcommerceSinglePage-left {
    width: 50%;
    height: 90vh;
    border-radius: 8px;
    img {
      background-color: #f7f7f7;
      height: 100%;
      width: 100%;
      border-radius: 8px;
      border: 1px solid #e4e4e4;
      object-fit: cover;
      object-position: top;
    }
  }
  .EcommerceSinglePage-right {
    width: 50%;
    padding-left: 5%;
    .item-header {
      width: 100%;
      font-size: 36px;
      color: black;
      font-family: Jost;
      margin-top: 20px;
    }
    .item-price {
      font-size: 22px;
      color: #535353;
      font-family: Jost;
      display: flex;
      .mrp {
        font-family: Jost;
        color: #a5a5a5;
        font-size: 22px;
        margin-right: 15px;
      }
    }
    .item-vendor {
      color: #535353;
      font-family: Jost;
      margin-top: 10px;
      b {
        margin-right: 10px;
      }
    }
    .item-ref {
      font-size: 12px;
      color: #121212;
      margin-top: 10px;
      margin-bottom: 20px;
      font-family: Jost;
    }
    .item-container {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 20px;
      width: 100%;
      .qty-container {
        width: max-content;
        border: 1px solid #e4e4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        .qty-cont-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 36px;
          height: 44px;
          font-family: Jost;
          font-size: 18px;
        }
        .qty-cont-action {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 36px;
          height: 40px;
          img {
            height: 12px;
            width: 10px;
          }
        }
      }
      .btn-container {
        display: flex;
        gap: 20px;
        .btn-item {
          width: 80%;
        }
      }
    }
    .buy-btn {
      margin-top: 20px;
      width: 77%;
    }
    .payment-container {
      margin-top: 30px;
      .payment-text {
        font-family: Jost;
        color: #757575;
      }
      .payment-cards {
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 10px;
        img {
          height: 26px;
        }
      }
    }
    .details-container {
      margin-top: 30px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceSinglePage {
    display: block;
    width: 90%;
    .EcommerceSinglePage-left {
      width: 100%;
      height: 40vh;
    }
    .EcommerceSinglePage-right {
      width: 100%;
      margin-top: 50px;
      padding-left: 0;
      .item-header {
        font-size: 30px;
      }
      .item-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        .btn-container {
          width: 100%;
          .btn-item {
            width: 77%;
          }
        }
      }
      .buy-btn {
        margin-top: 20px;
        width: 90%;
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceSinglePage {
    display: block;
    width: 90%;
    .EcommerceSinglePage-left {
      width: 100%;
      height: 40vh;
    }
    .EcommerceSinglePage-right {
      width: 100%;
      margin-top: 50px;
      padding-left: 0;
      .item-header {
        font-size: 30px;
      }
      .item-container {
        display: flex;
        flex-direction: column;
        align-items: start;
        .btn-container {
          width: 100%;
          .btn-item {
            width: 77%;
          }
        }
      }
      .buy-btn {
        margin-top: 20px;
        width: 90%;
      }
    }
  }
}
