.scroll-to-top-btn {
  position: fixed;
  bottom: 60px;
  right: 30px;
  background-color: #b5a46d;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}
