.SingleItemPage {
  width: 80%;
  margin: auto;
  column-gap: 22px;
  padding-bottom: 10%;
  .item-image {
    height: 460px;
    width: 585px;
    object-fit: cover;
  }
  .item-details-data {
    display: flex;
    justify-content: space-between;
    .vegan {
      padding: 7px 18px;
      color: #79a33c;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      border: 1px solid #1f7b24;
      border-radius: 50px;
    }
    .spice-cont {
      display: flex;
      align-items: center;
      .spice-level {
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        width: 130px;
      }
      .spice-leve-img {
        display: flex;
        align-items: center;
      }
    }
  }
  .item-details {
    padding: 20px;
    .title {
      font-family: "Jost";
      color: #0b0f0e;
      font-size: 28px;
      font-weight: 700;
    }
    .review {
      display: flex;
      align-items: center;
      margin-top: 9px;
      font-family: "Jost";
      color: #0b0f0e;
      font-size: 16px;
      font-weight: 400;
      column-gap: 8px;
      .star {
        height: 24px;
        width: 24px;
      }
    }
    .item-price {
      font-family: "Jost";
      color: #0b0f0e;
      font-size: 20px;
      font-weight: 700;
      margin-top: 16px;
    }
    .details {
      font-family: "Jost";
      color: #0b0f0e;
      font-size: 16px;
      font-weight: 400;
      margin-top: 24px;
      width: 600px;
      word-wrap: break-word;
    }
  }
  .description {
    padding-bottom: 10%;
    border-bottom: 1px solid #e4e9ee;
    .item-details-tab {
      display: flex;
      width: 100%;
      border-bottom: 2px solid #e4e9ee;
      font-family: Jost;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      color: #818b9c;
      margin-top: 56px;
      .det-itm {
        width: 100px;
        padding-bottom: 12px;
        cursor: pointer;
      }
      .selected {
        color: #4d4d4d;
        border-bottom: 2px solid #4d4d4d;
        margin-bottom: -2px;
      }
    }
    .item-tab-desc {
      font-family: Jost;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      text-align: left;
      color: #333333;
      margin-top: 32px;
      .nutri {
        width: 30%;
        .nutri-title {
          font-family: Jost;
          font-size: 17px;
          font-weight: 500;
          color: #0b0f0e;
        }
        .servings-container {
          margin-top: 25px;
          padding-bottom: 10px;
          display: flex;
          column-gap: 40px;
          .serving-text-cont {
            display: flex;
            flex-direction: column;
            .serving-text-head {
              font-size: 16px;
              font-weight: 600;
              line-height: 30px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
            }
            .serving-text {
              font-size: 14px;
              font-weight: 400;
              line-height: 18px;
              letter-spacing: -0.4000000059604645px;
              text-align: left;
              color: #868686;
            }
          }
        }
        .cal-text {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .det {
          display: flex;
          justify-content: space-between;
          margin-bottom: 5px;
          .serving-text {
            font-size: 16px;
            color: #818b9c;
          }
        }
      }
      .det {
        display: flex;
        column-gap: 10px;
        .item {
          border-radius: 16px;
          padding: 8px 20px;
          border: 1px solid #d1d8dd;
          background-color: #fefefe;
        }
      }
    }
  }
  .modifiers {
    margin-top: 47px;
    .single-item {
      background-color: #f0f1f4;
      width: 100%;
      padding: 10px 26px;
      font-family: Jost;
      font-size: 16px;
      color: #242424;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      margin-top: 10px;
      .left {
        display: flex;
        column-gap: 28px;
        .req {
          font-family: Urbanist;
          font-size: 13px;
          font-weight: 600;
          text-align: center;
          color: #4d4d4d;
          width: 80px;
          border: 1px solid #4d4d4d;
          border-radius: 20px;
        }
      }
      .open {
        transform: rotate(-180deg);
      }
    }
    .res {
      padding-left: 30px;
      font-size: 12px;
      font-weight: 500;
      color: #039be5;
      margin-top: 2px;
    }
    .modifier-option-container {
      position: relative;
      padding-left: 30px;

      .modifier-details {
        padding: 16px 0;
        font-family: "Jost";
        font-size: 14px;
        font-weight: 500;
        color: #242837;
        display: flex;
        align-items: center;
        .option-name {
          min-width: 300px;
          display: flex;
          .item-name {
            min-width: 200px;
          }
          .price-val {
            min-width: 100px;
          }
        }
        .qlt-option {
          display: flex;
          align-items: center;
          margin-left: 30px;
          .qty-cont {
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .icon-cont {
            height: 20px;
            width: 20px;
            border: 1px solid #00000014;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .icon {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
      .option-modifier {
        .single-item {
          background-color: #f0f1f4;
          width: 100%;
          padding: 10px 26px;
          font-family: Jost;
          font-size: 16px;
          color: #4d4d4d;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          margin-top: 10px;
          border: 1px solid #c6c7d2;
          .open {
            transform: rotate(-180deg);
          }
        }
        .modifier-details {
          padding: 16px 0;
          font-family: Jost;
          font-size: 14px;
          font-weight: 500;
          color: #242837;
          display: flex;
          align-items: center;
          .option-name {
            width: 170px;
          }
          .qlt-option {
            display: flex;
            align-items: center;
            margin-left: 30px;
            .qty-cont {
              width: 30px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .icon-cont {
              height: 20px;
              width: 20px;
              border: 1px solid #00000014;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              .icon {
                height: 12px;
                width: 12px;
              }
            }
          }
        }
      }
    }
  }
  .comments-sec {
    margin-top: 60px;
    .comments-title {
      font-family: Jost;
      font-size: 17px;
      font-weight: 500;
      color: #0b0f0e;
    }
    .text-area {
      margin-top: 24px;
      height: 200px;
      border: 1px solid #e4e9ee;
      resize: none;
    }
  }
  .bottom-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 50px;
    .primary-btn {
      width: 250px;
      height: 36px;
      margin-right: 16px;
      font-family: Jost;
      font-size: 18px;
      font-weight: 700;
    }
    .sec-btn {
      width: 250px;
      height: 36px;
      font-family: Jost;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: brightness(0) saturate(100%);
        height: 19px;
        width: 19px;
        margin-right: 8px;
      }
    }
    .quantity {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 30px;
      .icon-cont {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #edeff3;
        border-radius: 5px;
        cursor: pointer;
        .icon {
          height: 14px;
          width: 14px;
          filter: brightness(0) saturate(100%) invert(11%) sepia(5%)
            saturate(7%) hue-rotate(336deg) brightness(96%) contrast(76%);
        }
      }
      .qty-cont {
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Urbanist;
        font-size: 18px;
        font-weight: 700;
      }
      .icon-cont:hover {
        background-color: #463569;
        .icon {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(7500%) hue-rotate(114deg) brightness(98%) contrast(98%);
        }
      }
    }
  }
}
