.EcommerceCheckout {
  min-height: 100vh;
  width: 100%;
  background-color: #fff;
  position: relative;
  .EcommerceCheckout-Navbar {
    width: 100%;
    font-family: Roboto;
    height: 86px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border-bottom: 1px solid #e4e4e4;
    background-color: #fff;

    .logo-container {
      height: 66px;
      cursor: pointer;
      img {
        height: 66px;
      }
    }
  }
  .EcommerceCheckout-Container {
    display: flex;
    width: 100%;
    position: relative;
    .EcommerceCheckout-Container-left {
      height: 100%;
      min-height: calc(100vh - 57px);
      border-right: 1px solid #e4e4e4;
      width: calc(50% - 60px);
      padding: 30px;
      display: flex;
      justify-content: flex-end;
      .EcommerceCheckout-container-content {
        width: 70%;
        .Eccomerce-form-container {
          margin-bottom: 40px;
          .header-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 20px;
            .login-text {
              display: flex;
              font-family: Roboto;
              font-size: 14px;
              text-decoration: underline;
              color: #c7750a;
              cursor: pointer;
            }
          }
          .input-container {
            margin-bottom: 10px;
          }
          .input-grid-container {
            display: grid;
            grid-template-columns: calc(50% - 10px) 50%;
            column-gap: 10px;
            height: 100%;
            margin-bottom: 10px;
          }
          .input-container-accrodion {
            padding: 20px 20px;
            width: calc(100% - 40px);
            border: 1px solid #e4e4e4;
            border-radius: 4px;
            display: flex;
            flex-direction: column;
            .accrodion-top {
              border-bottom: 1px solid #e4e4e4;
              padding-bottom: 15px;
              display: flex;
              flex-direction: column;
              row-gap: 20px;
            }
            .accrodion-bottom {
              padding-top: 15px;
            }
          }
        }
        .Eccomerce-btn-container {
          margin-bottom: 40px;
        }
      }
    }
    .EcommerceCheckout-Container-right {
      height: calc(100vh - 60px);
      width: calc(50% - 60px);
      position: sticky;
      top: 0;
      padding: 30px;
      .EcommerceCheckout-container-content {
        width: 70%;
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        .header-container {
          display: none;
          margin-bottom: 20px;
        }
        .EcommerceCheckout-container-total {
          height: 40vh;
          margin-top: 40px;
          .total-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px;
            color: #121212;
            font-family: Roboto;
          }
          .value-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            line-height: 30px;
            color: #121212;
            font-family: Roboto;
            font-size: 22px;
            margin-top: 10px;
          }
          .Eccomerce-btn-container {
            margin-top: 40px;
            display: none;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceCheckout {
    .EcommerceCheckout-Container {
      display: block;
      .EcommerceCheckout-Container-left {
        width: calc(100% - 60px);
        justify-content: start;
        border-right: none;
        .EcommerceCheckout-container-content {
          width: 100%;
          .Eccomerce-btn-container {
            display: none;
          }
        }
      }
      .EcommerceCheckout-Container-right {
        width: calc(100% - 60px);
        justify-content: start;
        border-right: none;
        height: max-content;
        position: relative;
        .EcommerceCheckout-container-content {
          width: 100%;
          .header-container {
            display: block;
          }
          .EcommerceCheckout-container-total {
            .Eccomerce-btn-container {
              display: block;
            }
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceCheckout {
    .EcommerceCheckout-Container {
      display: block;
      .EcommerceCheckout-Container-left {
        width: calc(100% - 60px);
        justify-content: start;
        border-right: none;
        .EcommerceCheckout-container-content {
          width: 80%;
          margin: auto;
          .Eccomerce-btn-container {
            display: none;
          }
        }
      }
      .EcommerceCheckout-Container-right {
        width: calc(100% - 60px);
        justify-content: start;
        border-right: none;
        height: max-content;
        position: relative;
        .EcommerceCheckout-container-content {
          width: 80%;
          margin: auto;
          .header-container {
            display: block;
          }
          .EcommerceCheckout-container-total {
            .Eccomerce-btn-container {
              display: block;
            }
          }
        }
      }
    }
  }
}
