.Voucher-navbar {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  .Voucher-navbar-fixed-header {
    background-color: var(--primary-color);
    padding: 0px 66px;
    width: 100%;
    font-family: Roboto !important;
    .Voucher-navbar-menu-logo {
      display: flex !important;
      align-items: center !important;
      column-gap: 24px;
      .menu-icon-cont {
        height: 18px;
        width: 18px;
        cursor: pointer;
        margin-right: -20px;
      }
      .popover {
        position: relative;
        display: inline-block;
        .popover-content {
          width: 200px;
          background-color: #fff;
          color: #333;
          text-align: center;
          padding: 10px 10px;
          border-radius: 4px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          position: absolute;
          z-index: 3;
          top: 20px;
          left: -40px;
          height: max-content;
          margin: 0;
          visibility: visible;
          .track-order-ntag {
            font-family: Roboto !important;
            font-size: 14px;
            font-weight: 500;
            line-height: 15.23px;
            text-align: left;
            color: #001529;
            cursor: pointer;
            padding: 10px;
          }
        }
        .popover-content-close {
          width: 200px;
          background-color: #fff;
          color: #333;
          text-align: center;
          padding: 10px 10px;
          border-radius: 4px;
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
          position: absolute;
          z-index: 3 !important;
          top: 20px;
          left: -40px;
          height: max-content;
          margin: 0;
          visibility: hidden;
        }
      }
      .Voucher-navbar-logo {
        padding: 10px 0;
        position: relative;
      }
      .Voucher-navbar-menu-tab {
        display: flex !important;
        align-items: center !important;
        font-family: Roboto !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        column-gap: 30px;
      }
    }

    .Voucher-navbar-menu {
      padding: 14px 0;
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end;
      font-family: Roboto !important;
      .dropdown {
        width: 200px;
      }
      .Voucher-navbar-menu-tab-right {
        display: flex !important;
        align-items: center !important;
        column-gap: 30px;
        .Voucher-navbar-menu-order-type {
          display: flex;
          align-items: center;
          column-gap: 7.5px;
          font-family: Roboto !important;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.23px;
          text-align: left;
          color: var(--light-primary-color);
          cursor: pointer;
          .menu-order-type-radio {
            height: 18px;
            width: 18px;
            border-radius: 50%;
            border: 2px solid var(--light-primary-color);
            display: grid;
            align-items: center;
            justify-content: center;
          }
          .menu-order-type-radio-selected {
            border: 2px solid #fff;
          }
          .menu-order-type-selected {
            height: 10px;
            width: 10px;
            background-color: #fff;
            border-radius: 50%;
          }
        }
        .Voucher-navbar-menu-order-type-selected {
          color: #fff;
        }
      }
      .search-wrapper-cont {
        width: 60%;
        position: relative;
        background-color: #fff;
        margin-left: 30px;
        border-radius: 6px;
        .search-container {
          background-color: #fff;
          padding: 6px 12px;
          display: flex;
          align-items: center;
          border-radius: 40px;
          .search-image {
            height: 16px;
            width: 16px;
            margin-right: 10px;
          }
          .search-input {
            border: none;
            outline: none;
            width: 100%;
          }
          .close-image {
            height: 22px;
            width: 22px;
            cursor: pointer;
          }
        }
        .searched-container {
          position: absolute;
          z-index: 10;
          background-color: #fff;
          width: 100%;
          margin-top: -4px;
          padding: 12px;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px;
          max-height: 60vh;
          overflow-y: auto;
          -ms-overflow-style: none;
          scrollbar-width: none;
          box-shadow: 0px 6px 14px -6px #18274b1f;
          border: 1px solid #e0e0e0;
          border-top: none;
          .empty-search {
            padding: 20px;
            text-align: center;
            font-family: Roboto !important;
            font-size: 15px;
            color: #3b4159;
            text-wrap: wrap;
          }
          .searched-item-cont {
            display: flex;
            align-items: center;
            column-gap: 20px;
            padding: 10px 8px;
            cursor: pointer;
            border-bottom: 1px solid #f1f1f1;
            .searched-image-cont {
              height: 40px;
              width: 40px;
              object-fit: cover;
              border-radius: 2px;
            }
            .searched-title {
              font-family: Roboto !important;
              font-size: 14px;
              font-weight: 500;
              color: #101725;
            }
            .searched-cat {
              font-family: Roboto !important;
              font-size: 13px;
              color: #7c8091;
            }
          }
        }
        .searched-container::-webkit-scrollbar {
          display: none;
        }
      }
    }
    .Voucher-navbar-user-prof {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-end !important;
      .Voucher-navbar-sign-in-menu {
        height: 100%;
        padding: 14px 24px;
        border-right: 1px solid var(--dark-primary-color);
        border-left: 1px solid var(--dark-primary-color);

        display: flex;
        align-items: center;
        column-gap: 15.5px;
        .Voucher-navbar-sign-in-menu-icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          .Voucher-navbar-sign-in-menu-icon-profile {
            height: 16px;
            width: 16px;
            filter: var(--filter);
          }
        }
        .my-account-text {
          font-family: Roboto !important;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.23px;
          text-align: left;
          color: #ffffff;
        }
        .log-text {
          display: flex;
          font-family: Roboto !important;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.23px;
          text-align: left;
          color: var(--light-primary-color);
          align-items: center;
          margin-top: 2.5px;
          a {
            color: var(--light-primary-color);
          }
        }
        .border-line-sign {
          height: 13px;
          width: 1px;
          margin-top: 2px;
          margin-left: 5px;
          margin-right: 5px;
          background-color: var(--light-primary-color);
        }
      }
      .Voucher-navbar-order {
        display: flex;
        justify-content: center;
        align-items: center;
        .searchIC {
          height: 24px;
          width: 24px;
          cursor: pointer;
          display: none;
        }
        .Voucher-navbar-cart-nav-item {
          display: flex;
          justify-content: center;
          align-items: center;
          .Voucher-navbar-cart-icon {
            position: relative;
            display: flex;
            justify-content: center;
            cursor: pointer;
            .Voucher-navbar-noti-item {
              width: 16px;
              height: 16px;
              position: absolute;
              background-color: #e04124;
              display: flex;
              justify-content: center;
              align-items: center;
              right: 12px;
              border-radius: 50%;
              font-size: 10px;
              font-weight: 600;
              line-height: 12.66px;
              text-align: center;
              color: #fff;
            }
          }
          .Voucher-navbar-history-icon {
            height: 12px;
            width: 12px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: -10px;
            .Voucher-navbar-history {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
  }
  .mob-order-selection {
    width: 100%;
    display: none;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    .Voucher-navbar-menu-mob {
      background-color: #f0f0f0;
      border-radius: 4px;
      padding: 6px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 16px;
      .Voucher-navbar-menu-order-type-mob {
        padding: 6px 18px;
        border-radius: 8px;
        cursor: pointer;
        font-family: Roboto !important;
      }
      .Voucher-navbar-menu-order-type-mob-selected {
        background-color: #fff;
        color: var(--primary-color);
        font-weight: 500;
      }
    }
  }
}
@media (max-width: 600px) {
  .Voucher-navbar {
    .Voucher-navbar-fixed-header {
      padding: 0px 16px;
      .Voucher-navbar-menu-logo {
        .Voucher-navbar-menu-tab {
          visibility: hidden !important;
        }
      }
      .Voucher-navbar-menu {
        visibility: hidden !important;
      }
      .Voucher-navbar-user-prof {
        .Voucher-navbar-sign-in-menu {
          display: none !important;
        }
        .Voucher-navbar-order {
          .searchIC {
            display: block;
          }
          .Voucher-navbar-cart-nav-item {
            .Voucher-navbar-history-icon {
              display: none !important;
            }
          }
        }
      }
    }
    .mob-order-selection {
      display: flex;
    }
  }
}
.eco-select .eco-select__control.eco-select__control--is-focused {
  background-color: #fff !important;
}
