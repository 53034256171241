.VoucherMenuDefaultSingleItemPage-Menu {
  width: 80%;
  margin: auto;
  column-gap: 22px;
  padding-bottom: 10%;
  margin-top: 64px;
  font-family: Roboto !important;
  :where(.css-dev-only-do-not-override-19lec04).ant-radio-wrapper
    .ant-radio-checked
    .ant-radio-inner {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: var(--primary-color) !important;
    background-color: var(--primary-color) !important;
  }
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }
  .res {
    font-size: 13px;
    font-weight: 400;
    color: #62687e;
    font-family: Roboto !important;
  }
  .modal-menu {
    position: fixed; /* Stay in place */
    z-index: 10000000; /* Sit on top */
    left: 0;
    top: -62px;
    width: 100%; /* Full width */
    height: calc(100% + 62px); /* Full height */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  }
  .modal-content {
    background-color: #fefefe;
    margin: 6% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 600px; /* Could be more or less, depending on screen size */
    height: calc(100vh - 5% - 40px);
    .modal-content-menu-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      .mod-header {
        font-family: Roboto !important;
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #101725;
      }
      .close-icon {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          height: 30px;
          width: 30px;
        }
      }
    }
    .modifiers-popup {
      height: 70vh;
      overflow-y: auto;
      .modifier-menu {
        .modifiers-container {
          font-family: Roboto !important;
          font-size: 16px;
          background-color: #f1f1f1;
          padding: 15px 20px;
          margin-top: 10px;
          margin-bottom: 10px;
          border-radius: 4px;
          .selected-mod-container {
            padding-bottom: 10px;
            border-radius: 1px;
            .addon-title-mod {
              font-family: Roboto !important;
              font-size: 14px;
              text-transform: capitalize;
              color: #101725;
              margin-bottom: 5px;
              font-weight: 600;
              border-bottom: 2px solid #e6e6e6;
              padding-bottom: 10px;
              padding-top: 10px;

              .modi-add {
                font-family: Roboto !important;
                font-size: 13px;
                color: #3b4159;
                font-weight: 400;
                margin-top: 4px;
              }
            }
          }
          .edit-container {
            display: flex;
            align-items: center;
            cursor: pointer;
            background-color: var(--opacity-primary-color);
            padding: 9px 17px;
            width: max-content;
            border-radius: 30px;
            gap: 8px;
            img {
              height: 16px;
              width: 16px;
              filter: var(--filter);
            }
            .edit-text {
              font-family: Roboto !important;
              font-size: 14px;
              color: var(--primary-color);
            }
            .arrow-edit {
              filter: brightness(0) saturate(100%) invert(69%) sepia(1%)
                saturate(0%) hue-rotate(135deg) brightness(89%) contrast(83%);
              transform: rotate(-90deg);
            }
          }
        }
        .single-item-menu {
          margin-top: 10px;
          border-top: 1px solid #cccccc;
          width: 100%;
          padding: 15px 10px;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 500;
          text-align: left;
          color: #101725;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .left {
            display: flex;
            column-gap: 28px;
            .req {
              font-family: Roboto !important;
              font-size: 13px;
              font-weight: 500;
              text-align: center;
              color: #4d4d4d;
              width: 100px;
              border: 1px solid #4d4d4d;
              border-radius: 20px;
              display: flex;
              gap: 5px;
              justify-content: center;
              align-items: center;
              &.selected {
                color: #1bac4b;
                border: 1px solid #1bac4b;
                background-color: #1bac4b1a;
                img {
                  height: 15px;
                }
              }
            }
          }
          .open {
            transform: rotate(-180deg);
          }
        }

        .modifier-option-container {
          position: relative;
          padding: 0px 20px;
          .modifier-details {
            padding: 10px 15px;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 500;
            color: #242837;
            display: flex;
            align-items: center;
            border: 1px solid #cccccc;
            border-radius: 4px;
            margin-top: 10px;
            height: 56px;
            justify-content: space-between;
            .mod-arrow {
              margin-left: 10px;
              transform: rotate(-90deg);
              filter: brightness(0) saturate(100%);
            }
            .option-name {
              width: 300px;
              display: flex;
              gap: 20px;
              .item-name {
                font-family: Roboto !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 200px;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase !important;
              }
              .price-val {
                font-family: Roboto !important;
                min-width: 100px;
                font-size: 16px;
              }
            }
            .qlt-option {
              display: flex;
              align-items: center;
              margin-left: 30px;
              .qty-cont {
                height: 34px;
                width: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-family: Outfit;
                font-size: 22px;
                font-weight: 500;
                margin-top: -3px;
              }
              .icon-cont {
                height: 34px;
                width: 34px;
                border: 1px solid #00000014;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .icon {
                  height: 16px;
                  width: 16px;
                }
                &.disabled {
                  background-color: #f3f3f3;
                  border: 1px solid #e4e9ee;
                  cursor: default;
                  .icon {
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
              }
              .empty-icon-cont {
                height: 34px;
                width: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f3f3f3;
                border-radius: 4px;
                cursor: pointer;
                border: 1px solid #e4e9ee;
                .icon {
                  height: 16px;
                  width: 16px;
                  filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                    saturate(5%) hue-rotate(315deg) brightness(97%)
                    contrast(88%);
                }
              }
            }
          }
          .option-modifier {
            .modifiers-container {
              font-family: Roboto !important;
              font-size: 16px;
              background-color: #f1f1f1;
              padding: 10px 15px;
              margin: 20px;
              border-radius: 4px;
              .selected-mod-container {
                padding-bottom: 10px;
                border-radius: 1px;
                .addon-title-mod {
                  font-family: Roboto !important;
                  font-size: 14px;
                  text-transform: capitalize;
                  color: #101725;
                  margin-bottom: 5px;
                  font-weight: 600;
                  border-bottom: 2px solid #e6e6e6;
                  padding-bottom: 10px;
                  padding-top: 10px;

                  .modi-add {
                    font-family: Roboto !important;
                    font-size: 13px;
                    color: #3b4159;
                    font-weight: 400;
                    margin-top: 4px;
                  }
                }
              }
              .edit-container {
                display: flex;
                align-items: center;
                cursor: pointer;
                background-color: var(--opacity-primary-color);
                padding: 9px 15px;
                width: max-content;
                border-radius: 30px;
                gap: 8px;
                img {
                  height: 16px;
                  width: 16px;
                  filter: var(--filter);
                }
                .edit-text {
                  font-family: Roboto !important;
                  font-size: 14px;
                  color: var(--primary-color);
                }
                .arrow-edit {
                  filter: brightness(0) saturate(100%) invert(69%) sepia(1%)
                    saturate(0%) hue-rotate(135deg) brightness(89%)
                    contrast(83%);
                  transform: rotate(-90deg);
                }
              }
            }
            .single-item {
              width: 100%;
              font-family: Roboto !important;
              font-size: 18px;
              font-weight: 600;
              line-height: 27px;
              text-align: left;
              display: flex;
              align-items: center;
              justify-content: space-between;
              cursor: pointer;
              border-top: 1px solid #cccccc;
              .open {
                transform: rotate(-180deg);
              }
            }
            .modifier-details {
              padding: 15px 0;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              color: #242837;
              display: flex;
              align-items: center;

              .option-name {
                width: 320px;
                display: flex;
                gap: 20px;
                .item-name {
                  font-family: Roboto !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 200px;
                  font-size: 14px;
                  font-weight: 500;
                  text-transform: uppercase !important;
                }
                .price-val {
                  font-family: Roboto !important;
                  min-width: 100px;
                  font-size: 16px;
                }
              }
              .qlt-option {
                display: flex;
                align-items: center;
                margin-left: 30px;
                .qty-cont {
                  height: 34px;
                  width: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: Outfit;
                  font-size: 22px;
                  font-weight: 500;
                  margin-top: -3px;
                }
                .icon-cont {
                  height: 34px;
                  width: 34px;
                  border: 1px solid #00000014;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  .icon {
                    height: 16px;
                    width: 16px;
                  }
                  &.disabled {
                    background-color: #f3f3f3;
                    border: 1px solid #e4e9ee;
                    cursor: default;
                    .icon {
                      filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                        saturate(5%) hue-rotate(315deg) brightness(97%)
                        contrast(88%);
                    }
                  }
                }
                .empty-icon-cont {
                  height: 34px;
                  width: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3f3f3;
                  border-radius: 4px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .icon {
                    height: 16px;
                    width: 16px;
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
              }
            }
          }
        }
      }
    }
    .requierd-text {
      font-family: Roboto !important;
      color: rgb(255, 41, 41);
      font-size: 14px;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    .save-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 217px;
      height: 44px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      text-align: left;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--primary-color);
      border-radius: 4px;
      cursor: pointer;
      margin: auto;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .back-menu {
    margin-bottom: 32px;
    a {
      color: #0b0f0e;
      font-family: Roboto !important;
    }
  }
  .item-details {
    width: 100%;
    display: flex;
    .item-details-left {
      width: 100%;
      .item-image {
        height: 460px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
      }
      .description {
        .item-details-tab {
          display: flex;
          width: 100%;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: center;
          color: #3b4159;
          margin-top: 32px;
          .det-itm {
            width: 100px;
            padding-bottom: 12px;
            cursor: pointer;
          }
          .selected {
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);
            margin-bottom: -2px;
          }
        }
        .item-tab-desc {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #101725;
          margin-top: 24px;
          .nutri {
            font-family: Roboto !important;
            .nutri-title {
              font-family: Roboto !important;
              font-size: 17px;
              font-weight: 500;
              color: #0b0f0e;
            }
            .servings-container {
              margin-top: 25px;
              padding-bottom: 10px;
              display: flex;
              column-gap: 40px;
              .serving-text-cont {
                display: flex;
                flex-direction: column;
                .serving-text-head {
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 30px;
                  letter-spacing: -0.4000000059604645px;
                  text-align: left;
                }
                .serving-text {
                  font-size: 14px;
                  font-weight: 400;
                  line-height: 18px;
                  letter-spacing: -0.4000000059604645px;
                  text-align: left;
                  color: #868686;
                }
              }
            }
            .cal-text {
              font-size: 16px;
              margin-bottom: 10px;
            }
            .det {
              display: flex;
              justify-content: space-between;
              margin-bottom: 5px;
              .serving-text {
                font-size: 16px;
                color: #818b9c;
              }
            }
          }
          .det {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            .item {
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              text-align: center;
              border-radius: 4px;
              padding: 6px 16px;
              border: 1px solid #3b4159;
              color: #3b4159;
            }
          }
        }
      }
    }

    .item-details-right {
      width: 100%;
      padding: 0 0 0 8%;
      .item-details-data {
        display: flex;
        justify-content: space-between;
        .veg-cont {
          display: flex;
          align-items: center;
          gap: 10px;
          .vegan {
            height: 42px;
            width: 40px;
          }
        }
        .spice-cont {
          display: flex;
          align-items: center;
          .spice-level {
            font-family: Roboto !important;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color: #101725;
            width: 130px;
          }
          .spice-leve-img {
            display: flex;
            align-items: center;
          }
        }
      }
      .modifiers {
        margin-top: 71px;
        .modifier {
          .single-item {
            margin-top: 10px;
            border-top: 1px solid #cccccc;
            width: 100%;
            padding: 20px 0 0px;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
            text-align: left;

            color: #101725;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
            .left {
              display: flex;
              column-gap: 28px;
              .req {
                font-family: Roboto !important;
                font-size: 13px;
                font-weight: 500;
                text-align: center;
                color: #4d4d4d;
                width: 100px;
                border: 1px solid #4d4d4d;
                border-radius: 20px;
                display: flex;
                gap: 5px;
                justify-content: center;
                align-items: center;
                &.selected {
                  color: #1bac4b;
                  border: 1px solid #1bac4b;
                  background-color: #1bac4b1a;
                  img {
                    height: 15px;
                  }
                }
              }
            }
            .open {
              transform: rotate(-180deg);
            }
          }

          .modifier-option-container {
            position: relative;

            .modifier-details {
              padding: 10px 15px;
              font-family: Roboto !important;
              font-size: 16px;
              font-weight: 500;
              color: #242837;
              display: flex;
              align-items: center;
              border: 1px solid #cccccc;
              border-radius: 4px;
              margin-top: 10px;
              height: 56px;
              justify-content: space-between;
              .option-name {
                width: 320px;
                display: flex;
                gap: 20px;
                .item-name {
                  font-family: Roboto !important;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  width: 200px;
                  font-size: 14px;
                  font-weight: 500;
                  text-transform: uppercase !important;
                }
                .price-val {
                  font-family: Roboto !important;
                  min-width: 100px;
                  font-size: 16px;
                }
              }
              .mod-arrow {
                transform: rotate(-90deg);
                filter: brightness(0) saturate(100%);
              }
              .qlt-option {
                display: flex;
                align-items: center;
                margin-left: 30px;
                .qty-cont {
                  height: 34px;
                  width: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-family: Outfit;
                  font-size: 22px;
                  font-weight: 500;
                  margin-top: -3px;
                }
                .icon-cont {
                  height: 34px;
                  width: 34px;
                  border: 1px solid #00000014;
                  border-radius: 4px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;

                  .icon {
                    height: 16px;
                    width: 16px;
                    filter: var(--filter);
                  }
                  &.disabled {
                    background-color: #f3f3f3;
                    border: 1px solid #e4e9ee;
                    cursor: default;
                    .icon {
                      filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                        saturate(5%) hue-rotate(315deg) brightness(97%)
                        contrast(88%);
                    }
                  }
                }
                .empty-icon-cont {
                  height: 34px;
                  width: 34px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #f3f3f3;
                  border-radius: 4px;
                  cursor: pointer;
                  border: 1px solid #e4e9ee;
                  .icon {
                    height: 16px;
                    width: 16px;
                    filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                      saturate(5%) hue-rotate(315deg) brightness(97%)
                      contrast(88%);
                  }
                }
              }
            }
            .option-modifier {
              .modifiers-container {
                font-family: Roboto !important;
                font-size: 16px;
                background-color: #f1f1f1;
                padding: 15px 20px;
                margin: 20px;
                border-radius: 4px;
                .selected-mod-container {
                  padding-bottom: 10px;
                  border-radius: 1px;
                  .addon-title-mod {
                    font-family: Roboto !important;
                    font-size: 14px;
                    text-transform: capitalize;
                    color: #101725;
                    margin-bottom: 5px;
                    font-weight: 600;
                    border-bottom: 2px solid #e6e6e6;
                    padding-bottom: 10px;
                    padding-top: 10px;

                    .modi-add {
                      font-family: Roboto !important;
                      font-size: 13px;
                      color: #3b4159;
                      font-weight: 400;
                      margin-top: 4px;
                    }
                  }
                }
                .edit-container {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  background-color: var(--opacity-primary-color);
                  padding: 9px 17px;
                  width: max-content;
                  border-radius: 30px;
                  gap: 8px;
                  img {
                    height: 16px;
                    width: 16px;
                    filter: var(--filter);
                  }
                  .edit-text {
                    font-family: Roboto !important;
                    font-size: 14px;
                    color: var(--primary-color);
                  }
                  .arrow-edit {
                    filter: brightness(0) saturate(100%) invert(69%) sepia(1%)
                      saturate(0%) hue-rotate(135deg) brightness(89%)
                      contrast(83%);
                    transform: rotate(-90deg);
                  }
                }
              }
              .single-item {
                width: 100%;
                padding: 16px 26px;
                font-family: Roboto !important;
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
                text-align: left;
                display: flex;
                align-items: center;
                justify-content: space-between;
                cursor: pointer;
                margin-top: 10px;
                border-top: 1px solid #cccccc;
                .open {
                  transform: rotate(-180deg);
                }
              }
              .modifier-details {
                padding: 15px 0;
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 500;
                color: #242837;
                display: flex;
                align-items: center;
                .option-name {
                  width: 320px;
                  display: flex;
                  gap: 20px;
                  .item-name {
                    font-family: Roboto !important;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    width: 200px;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase !important;
                  }
                  .price-val {
                    font-family: Roboto !important;
                    min-width: 100px;
                    font-size: 16px;
                  }
                }
                .qlt-option {
                  display: flex;
                  align-items: center;
                  margin-left: 30px;
                  .qty-cont {
                    height: 34px;
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-family: Outfit;
                    font-size: 22px;
                    font-weight: 500;
                    margin-top: -3px;
                  }
                  .icon-cont {
                    height: 34px;
                    width: 34px;
                    border: 1px solid #00000014;
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    .icon {
                      height: 16px;
                      width: 16px;
                      filter: var(--filter);
                    }
                    &.disabled {
                      background-color: #f3f3f3;
                      border: 1px solid #e4e9ee;
                      cursor: default;
                      .icon {
                        filter: brightness(0) saturate(100%) invert(77%)
                          sepia(8%) saturate(5%) hue-rotate(315deg)
                          brightness(97%) contrast(88%);
                      }
                    }
                  }
                  .empty-icon-cont {
                    height: 34px;
                    width: 34px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #f3f3f3;
                    border-radius: 4px;
                    cursor: pointer;
                    border: 1px solid #e4e9ee;
                    .icon {
                      height: 16px;
                      width: 16px;
                      filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                        saturate(5%) hue-rotate(315deg) brightness(97%)
                        contrast(88%);
                    }
                  }
                }
              }
            }
          }
        }
      }
      .comments-sec {
        margin-top: 60px;
        .comments-title {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #101725;
        }
        .text-area {
          margin-top: 8px;
          height: 200px;
          border: 1px solid #e4e9ee;
          resize: none;
          font-family: Roboto !important;
        }
      }
      .bottom-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 50px;
        .primary-btn {
          width: 250px;
          height: 36px;
          margin-right: 16px;
          font-family: Jost;
          font-size: 18px;
          font-weight: 700;
        }
        .sec-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 217px;
          height: 44px;
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: var(--primary-color);
          border-radius: 4px;
          cursor: pointer;
        }
        .quantity {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 30px;
          .icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: var(--filter);
            }
            &.disabled {
              background-color: #f3f3f3;
              border: 1px solid #e4e9ee;
              cursor: default;
              .icon {
                filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                  saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
              }
            }
          }
          .empty-icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
            }
          }
          .qty-cont {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .description {
        display: none;
      }
      .title {
        font-family: Roboto !important;
        font-size: 40px;
        font-weight: 700;
        line-height: 48px;
        text-align: left;
        color: #101725;
      }
      .review {
        display: flex;
        align-items: center;
        margin-top: 9px;
        font-family: "Jost";
        color: #0b0f0e;
        font-size: 16px;
        font-weight: 400;
        column-gap: 8px;
        .star {
          height: 24px;
          width: 24px;
        }
      }
      .price-conatiner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .item-price {
          font-family: Roboto !important;
          font-size: 24px;
          font-weight: 700;
          line-height: 33.6px;
          text-align: left;
          color: var(--primary-color);
          margin-top: 8px;
        }
        .quantity {
          display: none !important;
          align-items: center;
          justify-content: center;
          .icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: var(--filter);
            }
            &.disabled {
              background-color: #f3f3f3;
              border: 1px solid #e4e9ee;
              cursor: default;
              .icon {
                filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                  saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
              }
            }
          }
          .empty-icon-cont {
            height: 44px;
            width: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f3f3f3;
            border-radius: 8px;
            cursor: pointer;
            border: 1px solid #e4e9ee;
            .icon {
              height: 14px;
              width: 14px;
              filter: brightness(0) saturate(100%) invert(77%) sepia(8%)
                saturate(5%) hue-rotate(315deg) brightness(97%) contrast(88%);
            }
          }

          .qty-cont {
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Roboto !important;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }

      .details {
        font-family: Roboto !important;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #101725;
        margin-top: 24px;
        word-wrap: break-word;
      }
      .edit-mod {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 10px;
        color: var(--primary-color);
        font-family: Roboto !important;
        cursor: pointer;
        // border: 1px solid #00000014;
        // border-radius: 4px;
        // padding: 5px 10px;
        border-bottom: 1px solid var(--primary-color);
        width: max-content;
        img {
          height: 16px;
          filter: brightness(0) saturate(100%) invert(19%) sepia(94%)
            saturate(3207%) hue-rotate(188deg) brightness(94%) contrast(101%);
        }
      }
      .modifiers-text {
        font-family: Roboto !important;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        color: #818b9c;
        .dot {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          background-color: var(--primary-color);
        }
        .qty-box {
          padding: 1px 3px;
          background-color: #eeeeee;
          color: #b4b4b4;
          font-size: 12px;
          margin-left: 3px;
          margin-right: 10px;
        }
      }
    }
  }
  .eco-textarea__wrapper .eco-textarea:focus,
  .eco-textarea__wrapper .eco-textarea:focus-visible {
    border-color: none !important;
    box-shadow: none !important;
    outline: none;
  }
}
@media only screen and (max-width: 600px) {
  .VoucherMenuDefaultSingleItemPage-Menu {
    width: 90%;

    .modal-menu {
      top: 0px;
    }
    .modal-content {
      width: 100%;
      margin: 0;
      height: 100dvh;
      padding: 10px;
      .modal-content-menu-header {
        padding: 10px 20px;
      }
      .modifiers-popup {
        height: 80vh;
        .modifier-menu {
          .modifier-option-container {
            .modifier-details {
              .option-name {
                width: 180px;
                .item-name {
                  width: 100px;
                }
                .price-val {
                  min-width: 50px;
                }
              }
            }
          }
        }
      }
    }
    .item-details {
      display: block;
      .item-details-left {
        .item-image {
          width: 100% !important;
          height: 283px;
        }
        .description {
          display: none;
        }
      }
      .item-details-right {
        padding: 0px;
        margin-top: 32px;
        .title {
          font-family: Roboto !important;
          font-size: 24px;
          font-weight: 700;
          line-height: 28.8px;
          text-align: left;
        }
        .bottom-container {
          .quantity {
            display: none;
          }
          .sec-btn {
            width: 100%;
          }
        }
        .description {
          .item-details-tab {
            display: flex;
            width: 100%;
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            color: #3b4159;
            margin-top: 32px;
            .det-itm {
              width: 100px;
              padding-bottom: 12px;
              cursor: pointer;
            }
            .selected {
              color: var(--primary-color);
              border-bottom: 2px solid var(--primary-color);
              margin-bottom: -2px;
            }
          }
          .item-tab-desc {
            font-family: Roboto !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: #101725;
            margin-top: 24px;
            .nutri {
              font-family: Roboto !important;
              .nutri-title {
                font-family: Roboto !important;
                font-size: 17px;
                font-weight: 500;
                color: #0b0f0e;
              }
              .servings-container {
                margin-top: 25px;
                padding-bottom: 10px;
                display: flex;
                column-gap: 40px;
                .serving-text-cont {
                  display: flex;
                  flex-direction: column;
                  .serving-text-head {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                  }
                  .serving-text {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: -0.4000000059604645px;
                    text-align: left;
                    color: #868686;
                  }
                }
              }
              .cal-text {
                font-size: 16px;
                margin-bottom: 10px;
              }
              .det {
                display: flex;
                justify-content: space-between;
                margin-bottom: 5px;
                .serving-text {
                  font-size: 16px;
                  color: #818b9c;
                }
              }
            }
            .det {
              display: flex;
              gap: 10px;
              flex-wrap: wrap;
              .item {
                font-family: Roboto !important;
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                border-radius: 4px;
                padding: 6px 16px;
                border: 1px solid #3b4159;
                color: #3b4159;
                background-color: var(--primary-color);
              }
            }
          }
        }
        .modifiers {
          .modifier {
            .modifier-option-container {
              padding-left: 0px;
              .modifier-details {
                .option-name {
                  width: 240px;
                  .item-name {
                    width: 130px;
                  }
                  .price-val {
                    min-width: 50px;
                  }
                }
              }
            }
          }
        }
        .price-conatiner {
          .quantity {
            display: flex !important;
          }
        }
      }
    }
  }
}
