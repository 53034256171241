/* colour theme settings */

:root {

    /* Body background and text color */
    --surface-color: rgb(255, 255, 255); 
    --on-surface-color: rgb(49, 49, 49); 
  
    /* Primary color */
    --primary-color: rgb(54, 208, 214);
  
    --on-primary-color: rgb(0, 0, 0); 
    --primary-color-opacity-05: rgba(54, 208, 214, 0.05);
    --primary-color-opacity-15: rgba(54, 208, 214, 0.15);
    --primary-color-opacity-25: rgba(54, 208, 214, 0.25);
    --primary-color-opacity-35: rgba(54, 208, 214, 0.35);
    --primary-color-opacity-85: rgba(54, 208, 214, 0.85);
  
    /* Text/Bg neutral colors */
    --neutral-color-1: rgb(51, 51, 51);
    --neutral-color-2: rgb(69, 69, 69);
    --neutral-color-3: rgb(88, 88, 88);
    --neutral-color-4: rgb(115, 115, 115);
    --neutral-color-5: rgb(195, 195, 195);
    --neutral-color-6: rgb(241, 241, 241);
  
    /* Button primary */ 
    --button-primary-color: rgb(54, 208, 214);
    --button-on-primary-text-color: rgb(0, 0, 0);
    --button-primary-hover-color: rgba(54, 208, 214, 0.85);
  
    /* Border color */
     --border-color-1: rgb(232, 232, 232);
  
    /* Hero area */
    --hero-transparent-bg: rgba(0,0,0, 0.35);
  
    /* Box shadows*/
    --box-shadow-1: 0px 3px 6px rgb(0 0 0 / 10%);
    --box-shadow-2: 0px 3px 18px rgb(0 0 0 / 20%);
    --primary-box-shadow: 0 0 0 0.2rem rgb(23 163 184 / 25%);
  
    /* Variants */ 
    --white-color: rgb(255, 255, 255);
    --black-color: rgb(0, 0, 0);
    
    --medium-grey-color: rgb(110, 110, 110);
    --light-grey-color: rgb(222, 228, 229);
    --light-grey-color: rgb(225, 225, 225);
    --bright-color: rgb(245, 157, 43);
    --light-bg-color: rgb(248, 248, 248);
  
  }
  
  /* Helper classes */
  .Stationary .mlr5 {margin-left: 5px; margin-right: 5px;}
  .Stationary .mt20 {margin-top: 20px;}
  .Stationary .mb0 {margin-bottom: 0;}
  .Stationary .pl0 {padding-left: 0 !important;}
  .Stationary .pdg-bot30 {padding-bottom: 30px;}
  .Stationary .min-w100 {min-width: 100px;}
  
  .Stationary .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
  
  /* Google Font - Poppins */
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
  /* Google Font - Roboto */
  @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
  
  @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0/css/all.min.css');
  /* Body */
  /* ---------------------------------------------------------------------- */
  .Stationary body {
    font-family: "Poppins","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 16px;
    color: var(--on-surface-color);
    background-color: var(--surface-color);
  }
  /* Header */
  /* ---------------------------------------------------------------------- */
  .Stationary .header {
    padding-top: 15px;
  }
  .Stationary .brand-header {
      display: flex;
      justify-content: center;
      align-items: center;
  }
  .Stationary .brand-header .nav-btn {margin-right: 15px;}
  .Stationary .header-top {
    display: flex; 
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: var(--white-color);
    padding-bottom: 15px;
  }
  .Stationary .promo-banner {
    display: flex;
    margin-bottom: 30px;
    display: none;
  }
  .Stationary .promo-banner .promo-item {
    border-right: 1px solid;
    border-color: var(--neutral-color-6);
    padding: 0 16px;
    text-align: center;
  }
  .Stationary .promo-banner .promo-item h6 {
    color: var(--neutral-color-4);
  }
  .Stationary .promo-banner .promo-item p {
    margin-bottom: 0;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 600;
  }
  .Stationary .nbr {border-right: 0 !important;}
  .Stationary .nav-btn {width: 30px;}
  .Stationary .navbar {
    padding: 0;
    position: static;
  }
  .Stationary .navbar-collapse {
    position: absolute;
    background-color: var(--light-bg-color);
    width: 100%;
    top: 70px;
    left: 0;
    z-index: 100;
  }
  .Stationary .header-top .nav-btn {
    display: flex;
    align-items: center;
  }
  .Stationary .header-top .nav-btn button {
    color: var(--primary-color);
  }
  .Stationary .login-right-wrapper {
    display: flex;
  }
  .Stationary .header-top .contact-wrapper {
    display: flex;
    color: var(--white-color);
    font-size: 16px;
    font-weight: 500;
    margin-right: 8px;
  }
  .Stationary .header-top .brand {
    display: flex;
    align-items: center;
  }
  .Stationary .header-top .brand a {
    padding: 0;
    margin-right: 0;
  }
  .Stationary .header-top .brand a img {
    height: 30px;
    width: auto;
  }
  .Stationary .header-top .brand h6 {
    margin-bottom: 0;
    margin-left: 8px;
    font-size: 24px;
    font-weight: 600;
    color: var(--primary-color);
  }
  .Stationary .acl-long {display:none;}
  .Stationary .search-wrapper.search-large {display:none;}
  .Stationary .search-wrapper {
    margin-bottom: 30px;
  }
  .Stationary .serachbar {
    position: relative;
  }
  .Stationary a.search-icon {
    position: absolute;
    right: 0;
    padding: 0 10px;
    line-height: 40px;
    color: var(--primary-color);
  }
  .Stationary .serachbar input {
    padding: 4px;
    height: 40px;
    font-size: 14px;
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }
  .Stationary .login-right-wrapper {
    min-width: 80px;
    display: flex;
    justify-content: end;
  }
  
  /* mobile navigation */
  .Stationary .ico-chev::after {
    position: absolute;
    right: 30px;
    top: 22px;
    font-size: 20px;
  }
  .Stationary .nav-item.dropdown.show .ico-chev::after {transform: rotate(180deg);}
  .Stationary .nav-menu {
    border-radius: 0;
  }
  .Stationary .navbar-toggler {
    border: 0;
    font-size: 24px;
    padding-left: 0;
    padding-right: 8px;
    margin-right: 6px;
  }
  .Stationary .navbar-light .navbar-toggler i.fa-bars {
    color: var(--primary-color);
  }
  .Stationary .navbar-light .navbar-toggler i.fa-times {
    color: var(--primary-color);
  }
  .Stationary .navbar-light .navbar-nav .nav-item {
    border-bottom: 1px solid;
    border-color: var(--light-grey-color);
  }
  .Stationary .navbar-light .navbar-nav .nav-link {
    padding: 10px 16px;
    color: var(--on-surface-color);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    text-transform: uppercase;
  }
  .Stationary .navbar-light .navbar-nav .nav-link:hover {
    text-decoration: none; 
    color: var(--primary-color);
    opacity: 0.85;
  }
  .Stationary .navbar-light .navbar-nav .nav-link.active {
    color: var(--primary-color);
    font-weight: 600;
  }
  .Stationary .navbar-light .navbar-nav .nav-link:active, 
  .Stationary .navbar-light .navbar-nav .nav-link:focus {color: var(--primary-color);}
  .Stationary .opt-btns {
    display: flex;
    align-items: center;
  }
  .Stationary .mob-ddl {
    padding-left: 25px;
    padding-top: 0;
    padding-bottom: 10px;
    margin: 0;
    background-color: transparent; 
    border: 0;
  }
  .Stationary .mob-ddl .dropdown-item.active, .Stationary .mob-ddl .dropdown-item:active {
    background-color: transparent !important;
    color: var(--primary-color);
  }
  .Stationary .mob-ddl a {padding-top:8px; padding-bottom: 8px;}
  /* /- mobile navigation */
  
  .Stationary a.shopping-cart {
    font-size: 22px;
    height: 40px;
    color: var(--primary-color);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .5rem;
  }
  .Stationary a.shopping-cart:hover {
      transform: translateY(1px);
  }
  .Stationary .shopping-cart .cart-count {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    font-weight: 700;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    line-height: 19px;
    text-align: center;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 2px solid;
    border-color: var(--primary-color);
  }
  .Stationary a.login-register {
    color: var(--on-surface-color);
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 8px 4px;
  }
  .Stationary a.login-register:hover {
    transform: translateY(1px);
    text-decoration: none;
  } 
  .Stationary .login-register .user-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: var(--white-color);
    background-color: var(--primary-color);
    display: inline-block;
    line-height: 30px;
    text-align: center;
  }
  .Stationary .login-register .user-name {display: none;}
  .Stationary .login-right-wrapper .drop-down-icon-hide::after{display: none;}
  .Stationary .login-right-wrapper .dropdown-menu.show {
      margin-top: 3px;
      border-radius: 0;
      padding: 5px 0;
      border: 0;
    font-size: 14px;
    box-shadow: var(--box-shadow-1);
  }
  .Stationary .login-right-wrapper .dropdown-menu.show::after {
    border-bottom: 8px solid;
    border-color: var(--white-color);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top:-7px;
    content: "";
    display: inline-block;
    right:8px;
    position: absolute;
    z-index: 1001; 
  }
  .Stationary .loggedin-user {
    padding: 5px 10px;
    text-align: center;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .Stationary .hr-sep {
    display: flex;
    justify-content: center;
  }
  .Stationary .hr-sep hr {
    border-color: var(--neutral-color-6);
    margin: 5px 0;
    width: 75%;
  }
  .Stationary .view-cart-wrapper .dropdown-item {
    color: var(--on-surface-color);
    background-color: var(--light-bg-color);
  }
  .Stationary .view-cart-wrapper .dropdown-item.active, .Stationary .view-cart-wrapper .dropdown-item:active {
    color: var(--primary-color);
    text-decoration: none;
    background-color: var(--white-color);
  }
  .Stationary .view-cart-wrapper .dropdown-item:focus, .Stationary .view-cart-wrapper .dropdown-item:hover {
    color: var(--on-surface-color);
    text-decoration: none;
    background-color: var(--primary-color-opacity-35);
  }
  .Stationary .user-actions i {margin-right: 10px;}
  .Stationary .view-cart-wrapper {
    width: 260px;
    padding: 10px;
  }
  .Stationary .view-cart-wrapper .cart-item {
    display: flex;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid;
    border-color: var(--border-color-1);
  }
  .Stationary .view-cart-wrapper .cart-item .cart-item-image {
    margin-right: 10px;
  }
  .Stationary .view-cart-wrapper .cart-item .cart-item-image .cart-img-thumb {
    width: 50px;
    height: auto;
  }
  .Stationary .view-cart-wrapper .cart-item .cart-item-text {
    width: 100%;
  }
  .Stationary .view-cart-wrapper .cart-item .cart-item-text p {
    font-size: 14px;
    margin-bottom: 0;
    color: var(--on-surface-color);
  }
  .Stationary .view-cart-wrapper .cart-item .cart-item-text .cart-item-qty {
    display: flex;
    justify-content: space-between;
  }
  .Stationary .view-cart-wrapper .cart-sub-total {
    margin-bottom: 0;
    padding-top: 10px;
    font-weight: 500;
    display: flex;
    justify-content: end;
  }
  .Stationary .view-cart-wrapper .cart-sub-total span {
    margin-right: 10px;
  }
  .Stationary .view-cart-wrapper button.checkout-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--button-on-primary-text-color);
    background-color: var(--primary-color);
    padding: 8px 16px;
    border: 1px solid;
    border-color: var(--primary-color);
    border-radius: 3px;
    width: auto;
    height: 30px;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
  .Stationary .view-cart-wrapper button.checkout-btn:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .Stationary .view-cart-wrapper button.view-cart-btn {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    color: var(--primary-color);
    background-color: var(--white-color);
    border: 1px solid;
    border-color: var(--neutral-color-5);
    padding: 8px 16px;
    border-radius: 3px;
    width: auto;
    height: 30px;
    width: 100%;
    text-align: center;
    margin-top: 20px;
  }
  .Stationary .view-cart-wrapper button.view-cart-btn:hover {
    color: var(--primary-color-opacity-85);
  }
  .Stationary .search-wrapper .form-wrapper {
    width: 100%;
  }
  .Stationary .search-wrapper form input {
    border-color: var(--primary-color);
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  
  /* Hero Banner */
  /* ---------------------------------------------------------------------- */
  .Stationary .hero-banner  {
    background-image: linear-gradient( rgba(0,0,0,0.0), rgba(0,0,0, 0.25)), 
    url("../images/acd_hero_img.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    padding: 20px;
    margin-bottom: 30px;
  }
  .Stationary .hero-banner h1, h3 {
    color: var(--white-color);
    text-align: center;
  }
  .Stationary .hero-banner h1 {
    font-weight: 700; 
    margin-bottom: 15px;
    text-transform: uppercase;
    text-align: right;
    font-size: 28px;
    line-height: 150%;
    max-width: 250px;
  }
  .Stationary .hero-banner h1 span.disco-wrap {color: var(--black-color);}
  .Stationary .hero-banner h3 {
    color: var(--white-color);
    text-align: center;
    font-weight: 600;
    text-transform: none;
  }
  .Stationary .hero-text-wrapper {
    display: flex;
    align-items: center;
  }
  .Stationary .hero-banner button {
    color: var(--button-on-primary-text-color);
    background-color: var(--button-primary-color);
    border-radius: 4px;
    font-weight: 700;
    margin-top: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
  }
  .Stationary .hero-banner button:hover {
    background-color: var(--button-primary-hover-color);
  }
  /* Hero Other Pages */
  /* ---------------------------------------------------------------------- */
  .Stationary .hero-banner-inner {
    background-image: linear-gradient(
    rgba(0,0,0,0.0), 
    rgba(0,0,0, 0.35)), 
    url("../images/acd_producthero.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  .Stationary .hero-banner-inner h1 {
    color: var(--white-color);
    font-weight: 700; 
    text-transform: uppercase;
    font-size: 2.5rem;
    letter-spacing: 3px;
    word-spacing: 10px;
    text-shadow: -1px -1px 6px rgb(0 0 0 / 84%);
  }
  
  /* Site Breadcrumb */
  /* ---------------------------------------------------------------------- */
  .Stationary .breadcrumb-container {
    padding: 20px;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb {
      margin-bottom: 0;
      padding:0;
      list-style:none;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb li {
      display: inline-block;
      padding-right: 20px;
      position: relative;
    font-size: 14px;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb li a {
    color: var(--on-surface-color);
      font-size: 14px;
      text-decoration: none;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb li a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
     text-underline-offset: 3px;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb .current {
    color: var(--primary-color);
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb li::after {
    content: '>';
    font-size: 14px;
    position: absolute;
    right: 6px;
    top: 1px;
  }
  .Stationary .breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}
  
  /* Product Carousel */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .section {
    padding-top: 30px;
    background-color: var(--white-color);
  }
  .Stationary .header-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
  .Stationary .section.products .header-wrapper h2 {color: var(--white-color);}
  .Stationary h2 {
    text-align: center;
    word-spacing: 3px;
    margin-bottom: 10px;
  } 
  .Stationary .bottom-hr {
    display: inline-block;
    width: 80px;
    height: 4px;
    border-radius: 4px;
    background-color: var(--primary-color);
  }
  .Stationary .bdr-bot {
    border-bottom: 1px solid;
    border-color: var(--neutral-color-6);
  }
  /* Carousel Styles */
  .Stationary .section .owl-theme .owl-dots .owl-dot.active span, .Stationary .section .owl-theme .owl-dots .owl-dot:hover span {
    background: var(--primary-color);
  }
  .Stationary .owl-theme .owl-nav [class*='owl-']:hover {
    background-color: var(--primary-color) !important;
  }
  .Stationary .owl-dots {margin-top: 10px;}
  .Stationary .owl-nav i {font-size: 14px;}
  /**/
  .Stationary .prd-item {
    margin-bottom: 30px;
    text-align: center;
    background-color: var(--white-color);
    border: 1px solid;
    border-color: var(--neutral-color-5);
  }
  .Stationary .prd-item:hover {
    box-shadow: var(--box-shadow-1);
  }
  .Stationary .prd-item img {width: 100%;}
  .Stationary .prd-item .prd-item-link {
    background-color: var(--white-color);
    text-decoration: none;
    display: inline-block;
    width: 100%;
  }
  .Stationary .prd-item .prd-item-link .img-wrap{
    min-width: 100%;
    height: auto;
  }
  .Stationary .prd-item .prd-item-link img {padding: 10px;}
  .Stationary .prd-item .prd-item-text {
    text-align: left;
    padding: 10px;
    background-color: var(--light-bg-color);
  }
  .Stationary .prd-count-inner {
    display: flex;
    justify-content: space-between;
  }
  .Stationary .prd-count-inner.sing-btn {justify-content: end;}
  .Stationary .prd-count-inner button {
    font-size: 12px;
    padding: 5px 10px;
    background-color: var(--neutral-color-6);
    border: 0;
    width: 40px;
    height: 30px;
  }
  .Stationary .icon-sub, .icon-add {
    font-size: 12px;
    padding: 5px 20px;
    background-color: var(--neutral-color-6);
    border: 0;
    width: 40px;
    height: 30px;
  }
  .Stationary .qty-no input {
    width: 50px;
    height: 30px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
    background-color: var(--white-color);
    font-size: 14px;
    text-align: center;
    font-weight: 500;
  }
  /* remove scroll in number input */
  .Stationary .qty-no input[type=number]::-webkit-inner-spin-button, 
  .Stationary .qty-no input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
  .Stationary .prd-count-inner button.add-btn {
    font-size: 14px;
    font-weight: 500;
    color: var(--white-color);
    background-color: var(--primary-color);
    padding: 5px 8px;
    border: 0;
    width: auto;
    height: 30px;
  }
  .Stationary .prd-count-inner button.add-btn:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .Stationary .section.products .prd-item .prd-item-link:hover {
    transform: translateY(1px);
  }
  .Stationary a.prd-item-link:hover {
    text-decoration: none;
  }
  .Stationary .prd-item .prd-item-text h5 {
    font-size: 16px;
    color: var(--on-surface-color);
    margin-bottom: 8px;
    text-transform: uppercase;
  }
  .Stationary .prd-item .prd-item-text h6 {
    font-size: 16px; 
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 0;
  }
  .Stationary .section.products button {
    color: var(--white-color);
    background-color: var(--primary-color);
    border-radius: 0;
    font-weight: 500;
    margin-top: 20px;
  }
  .Stationary .section.products button:hover {
    background-color: var(--primary-color-opacity-85);
  }
  .Stationary .section.products button span i {
    margin-left: 10px;
    font-size: 14px;
  }
  /* Featured Products */
  /* ---------------------------------------------------------------------- */
  .Stationary .featured-products .prd-item .prd-item-link .img-wrap {min-width: 100%;}
  .Stationary .featured-products .prd-item .prd-item-text h5 {margin-bottom: 0;}
  /* Email Signup */
  /* ---------------------------------------------------------------------- */
  .Stationary .email-signup  {margin-bottom: 30px;}
  .Stationary .email-signup .promo-wrapper {
    background-color: var(--primary-color-opacity-35);
    min-height: 220px;
    padding: 20px;
  }
  .Stationary .email-signup .promo-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
  }
  .Stationary .email-signup .promo-text h4 {
    margin-bottom: 20px;
  }
  .Stationary .email-signup .promo-text p {
    font-size: 16px;
    columns: var(--on-surface-color);
    margin-bottom: 20px;
  }
  .Stationary .email-signup button {
    color: var(--button-on-primary-text-color);
    background-color: var(--button-primary-color);
    border-radius: 0;
    font-weight: 400;
    padding: 6px 12px;
    text-transform: normal;
  }
  .Stationary .email-signup button:hover {
    background-color: var(--button-primary-hover-color);
  }
  .Stationary .email-signup #emailAddress {
    border-color: var(--primary-color);
  }
  /* Testimonial */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .testimonial-wrapper {
    background-color: var(--white-color);
    padding: 20px;
    margin-bottom: 30px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
  }
  .Stationary .testimonial-header {display: flex;justify-content: center;align-items: center;}
  .Stationary .avt-img {margin-right: 10px;}
  .Stationary .avt-img-name {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  }
  .Stationary .avt-img-name h6 {
    margin-bottom: 3px;
    font-weight: 600;
  }
  .Stationary .avt-img-name span {font-size: 14px;}
  .Stationary .ratings {
    font-size: 11px;
    color: var(--bright-color);
  }
  .Stationary .ratings i {margin: 0 1px;}
  .Stationary .testimonial-body p {
    margin-bottom: 0;
    padding-top: 20px;
    text-align: justify;
  }
  
  /* Footer */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .pg-footer {
    margin-top: 30px;
    padding: 30px 0;
    background-color: var(--primary-color);
    background-color: var(--neutral-color-6);
    position: relative;
  }
  .Stationary .pg-footer .footer-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .Stationary .pg-footer .social-icons {margin-bottom: 20px;}
  .Stationary .pg-footer .social-icons a {
    font-size: 20px;
    display: inline-block;
    color: var(--neutral-color-2);
    padding: 5px 0;
    margin-right: 30px;
  }
  .Stationary .pg-footer .social-icons a:last-child {margin-right: 0;}
  .Stationary .pg-footer .social-icons a:hover {transform: translateY(1px);}
  .Stationary .pg-footer .social-icons a i {font-size: 24px;}
  .Stationary .pg-footer .footer-wrapper .addr-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: var(--neutral-color-2);
      margin-bottom: 20px;
  }
  .Stationary .pg-footer .footer-wrapper .addr-wrap .addr-lines {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 12px;
  }
  .Stationary .pg-footer .footer-wrapper .addr-wrap h6 {text-align: center;font-size: 14px;}
  .Stationary .pg-footer .copy {
      display: flex;
      justify-content: center;
      flex-direction: column;
      font-size: 10px;
      color: var(--neutral-color-3);
      align-items: center;
  }
  .Stationary .pg-footer .copy .copy-text {margin-bottom: 10px; text-align: center;}
  .Stationary .pg-footer .copy .copy-sep {padding: 0 10px;}
  .Stationary .pg-footer .contact-info {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      font-size: 12px;
  }
  .Stationary .pg-footer .contact-info span {margin: 5px 0;}
  .Stationary .pg-footer .copy a {
    color: var(--neutral-color-3);
    font-size: 10px;
  }
  .Stationary .pg-footer .copy a:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
  } 
  /* Product Range */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .section.productRange {
    background-color: var(--white-color);
    padding-bottom: 60px;
  }
  .Stationary .section.productlisting {
    background-color: var(--white-color);
  }
  .Stationary .item-count {
    margin: 10px 0;
  }
  .Stationary .section.productRange .prd-item .prd-item-text h5 {
    margin-bottom: 0;
  }
  .Stationary .item-count h6 {margin-bottom: 0;}
  .Stationary .section.productlisting {
    background-color: var(--white-color);
    padding-top: 10px;
  }
  .Stationary .items-list a {
    padding: 8px 12px;
    color: var(--on-surface-color);
  }
  .Stationary .items-list a:hover {
    background-color: var(--primary-color-opacity-35);
  }
  .Stationary .items-list a.active {
    background-color: var(--primary-color);
    border: 0;
  }
  .Stationary .mbl-prd-cat {
    margin-bottom: 20px;
  }
  .Stationary .mbl-prd-cat .btn-cat {
    background-color: var(--primary-color);
    color: var(--white-color);
    border: none;
  }
  .Stationary .mbl-prd-cat .dropdown-menu button {
    background-color: var(--white-color);
  }
  .Stationary .mh-at {min-height: auto !important; text-align: center !important;}
  .Stationary .load-more {
    display: flex;
    justify-content: end;
    text-align: right;
    margin: 20px 0 50px 0;
  }
  .Stationary .load-more a.load-more-link {
    color: var(--primary-color);
    margin-bottom: 0;
    width: 200px;
  }
  .Stationary .load-more a.load-more-link:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: 3px;
  }
  .Stationary .prd-pagination {
    margin:10px 0 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .Stationary .prd-pagination .pagination {justify-content: end;}
  .Stationary .page-link {color: var(--primary-color);}
  .Stationary .page-item.active .page-link {
    color: var(--black-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);  
  }
  .Stationary .page-link:hover {
    z-index: 2;
    color: var(--black-color);
    text-decoration: none;
    background-color: var(--primary-color-opacity-35);
    border-color: var(--primary-color-opacity-35);
  }
  
  /* Product Details */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .pro-img-wrapper {
    display: flex;
    flex-direction: column;
  }
  .Stationary .pro-img-wrapper .prd-img-large img {
    border: 1px solid;
    border-color: var(--neutral-color-5);
    margin-bottom: 10px;
    padding: 20px;
    width: 100%;
  }
  .Stationary .pro-img-wrapper .prd-img-thumb {display: flex;}
  .Stationary .pro-img-wrapper .prd-img-thumb img {
    max-width: 100px;
    height: auto;
    margin-right: 10px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
    opacity: .75;
  }
  .Stationary .pro-img-wrapper .prd-img-thumb img.img-active {
    border-color: var(--primary-color);
    opacity: 1;
  }
  .Stationary .prd-details-text {margin-top: 20px;}
  .Stationary .prd-details-text .prd-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 5px;
  }
  .Stationary .prd-details-text .prd-more-details p {margin-bottom: 10px;}
  .Stationary .prd-details-text .prd-price {
    font-size: 16px;
    color: var(--primary-color);
    font-weight: 600;
    margin-bottom: 20px;
  }
  .Stationary .prd-count-inner.p-det {justify-content: flex-start;}
  .Stationary .select-qty {display: inline-block;}
  .Stationary .select-qty p {margin-bottom: 8px;}
  .Stationary .select-qty .qty-wrapper {height: 40px;background-color: var(--neutral-color-6);border-radius: 4px;}
  .Stationary .select-qty .qty-wrapper .a-fix {
      height: 38px;
      line-height: 38px;
  }
  .Stationary .ft-bld {font-weight: 500;}
  .Stationary .prd-buttons {margin-top: 30px;}
  .Stationary .prd-buttons .prd-add {
    border: 2px solid;
    border-color: var(--primary-color);
    border-radius: 4px;
    color: var(--primary-color);
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
  }
  .Stationary .prd-buttons .prd-add:hover {text-decoration: none;}
  .Stationary .prd-buttons .prd-checkout {
    border: 2px solid;
    border-color: var(--primary-color);
    background: var(--primary-color);
    border-radius: 4px;
    color: var(--button-on-primary-text-color);
    font-weight: 600;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 0;
    text-align: center;
    width: 100%;
  }
  .Stationary .prd-buttons .prd-checkout:hover {
    text-decoration: none;
    background: var(--primary-color-opacity-85);
  }
  .Stationary .comm-details {margin-top: 50px; margin-bottom: 20px;}
  .Stationary .h2-sim-items {margin-top: 30px;}
  
  /* Accordion */
  .Stationary .accordion .card {
      border-radius: 0;
      border-top:0;
    background-color: var(--neutral-color-6);
      margin-bottom: 3px;
  }
  .Stationary .accordion .card-header {
    background-color: var(--neutral-color-5);
      cursor: pointer;
      padding: 0;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      border-radius: 0;
  }
  .Stationary .accordion .card-header button {
      margin: 0;
      padding: 10px 15px 10px;
      font-size: 14px;
    font-weight: 600;
    color: var(--neutral-color-3);
      display: block;
  }
  .Stationary .accordion .card-header button:focus {outline: 0; box-shadow: none; text-decoration: none;}
  .Stationary .accordion .card-header .btn-link:hover  {text-decoration: none;}
  .Stationary .accordion .card-header button:after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: '\f068';
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 12px;
  }
  .Stationary .accordion .card-header button.collapsed:after {
      content: '\f067';
  }
  /* Promotions */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .section.promotions {
    padding: 0;
    margin-top: 30px;
  }
  .Stationary .promo-container {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .Stationary .promotions .bgimg1 {
    background-image: linear-gradient(
      rgba(0,0,0,0.10), 
      rgba(0,0,0, 0.75)), 
      url("../images/acd_promo1.png");
  }
  .Stationary .promotions .bgimg2 {
    background-image: linear-gradient(
      rgba(0,0,0,0.10), 
      rgba(0,0,0, 0.85)), 
      url("../images/acd_promo2.png");
  }
  .Stationary .promotions .promo-text {
    color: var(--white-color);
  }
  .Stationary .promotions .promo-text h6 {
    color: var(--white-color);
    font-size: 28px;
  }
  .Stationary .promotions .promo-text p {
    font-size: 14px;
  }
  .Stationary .promo-btn button {
    color: var(--button-on-primary-text-color);
    background-color: var(--button-primary-color);
    border-radius: 4px;
    font-weight: 700;
    margin-top: 15px;
    padding: 10px 20px;
    text-transform: uppercase;
  }
  .Stationary .promo-btn button:hover {
    background-color: var(--button-primary-hover-color);
  }
  /* Product Checkout */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .sp-cart .search-wrapper {display: none;}
  .Stationary .container.checkout {
    padding-bottom: 30px;
  }
  .Stationary .checkout .table-responsive {margin-bottom: 30px;}
  .Stationary .checkout .table tr th {font-weight: 600;}
  .Stationary .checkout .table thead th {border: 0;}
  .Stationary .checkout .table td, .checkout .table th {
    border-color: var(--neutral-color-6);
  }
  .Stationary .checkout .prd-item-wrap {
    display: flex;
    width: 360px;
  }
  .Stationary .checkout .img-small {
    width: 100px;
    height: auto;
    margin-right: 10px;
  }
  .Stationary .checkout .prd-item-name {
    font-weight: 400;
  }
  .Stationary .checkout .select-qty {min-width: 160px;}
  .Stationary .checkout .qty-wrapper {display: inline-block;}
  
  .Stationary .checkout .header-wrapper {margin-bottom: 30px;}
  .Stationary .cart-details {
    display: flex; 
    margin-bottom: 30px;
    background-color: var(--primary-color-opacity-25);
    padding: 20px;
    border-radius: 8px;
    box-shadow: var(--box-shadow-1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Stationary .cart-details .align-option {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .Stationary .cart-details .cart-sub-total .st-item {
      min-width: 165px;
      padding: 10px;
  }
  .Stationary .mr10 {margin-right: 10px;}
  .Stationary .mr20 {margin-right: 20px;}
  .Stationary .cart-details .st-item {font-weight: 500;}
  .Stationary .cart-details .total-to-pay .st-item {font-size: 16px; font-weight: 700;}
  .Stationary .cart-details .cart-button-wrapper {
      margin-top: 30px;
      display:flex;
      flex-direction: column;
  }
  .Stationary .cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}
  .Stationary .cart-item-count {margin-bottom: 20px;}
  .Stationary .cart-badge {
    width: 30px;
      height: 30px;
      border-radius: 15px;
      line-height: 24px;
      background-color: var(--primary-color);
      color: var(--on-surface-color);
  }
  .Stationary .cart-buttons {
    display: flex;
    flex-direction: column;
    align-items: self-end;
  }
  .Stationary .cart-buttons a {
    text-align: center;
    padding: 10px 0;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }
  .Stationary .cart-buttons a.crt-btn-checkout {
    border: 2px solid;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: var(--button-on-primary-text-color);
    margin-top: 15px;
    border-radius: 4px;
  }
  .Stationary .cart-buttons a.crt-btn-cont {
    border: 2px solid;
    border-color: var(--primary-color);
    background-color: var(--white-color);
    color: var(--primary-color);
    border-radius: 4px;
  }
  .Stationary .cart-buttons a.crt-btn-checkout:hover {
    background-color: var(--primary-color-opacity-85);
  }
  
  /* Product Listing */
  /* ---------------------------------------------------------------------- */ 
  .Stationary .filter-header {
      background-color: var(--neutral-color-6);
      padding: 15px;
      margin-bottom: 20px;
  }
  .Stationary .filter-header h6 {margin-bottom: 0;}
  .Stationary .filter-wrapper {
    padding: 15px;
    border: 1px solid;
    border-color: var(--neutral-color-5);
    margin-bottom: 20px;
  
    display:block;
  }
  .Stationary .filter-inner-wrapper {
    padding-left: 15px;
    padding-bottom: 15px;
    font-size: 14px;
  }
  .Stationary .filter-item label {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
  .Stationary .filter-item label span {
    margin-left: 7px;
  }
  .Stationary .filter p {
      font-size: 14px;
      font-weight: 500;
  }
  .Stationary .filter-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .Stationary .filter-panel .icon-filter {margin-left: 10px;}
  .Stationary .filter-panel .btn.focus, .Stationary .filter-panel .btn:focus {
    outline: 0;
    box-shadow: var(--primary-box-shadow);
  }
  .Stationary .filter-wrapper {display:block;}
  .Stationary .menu-open .filter-wrapper {display: none;}
  .Stationary .prd-plus {display: none;}
  .Stationary .menu-open .prd-plus {display: block;}
  .Stationary .menu-open .prd-minus {display: none;}
  .Stationary .filtered-index {display: flex;flex-wrap: wrap;}
  .Stationary .filtered-index .filter-chip {
    font-size: 14px;
    font-weight: 500;
    background-color: var(--primary-color-opacity-35);
    font-size: 14px;
    padding: 5px 16px;
    height: 30px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  .Stationary .filtered-index .filter-chip i {margin-left: 15px;}
  .Stationary .filter-buttons {
      display: flex;
      justify-content: flex-end;
  }
  .Stationary .filter-buttons a {
    color: var(--on-surface-color);
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
    margin-left: 30px;
  }
  .Stationary .rm-hdr-txt .promo-banner {
    display: none;
  }
  
  /* ====================================================================== */
  /* Responsive Media Query */
  /* ====================================================================== */
  @media (min-width: 576px) {
  
    .Stationary .hero-banner h1 {max-width: 350px;}
   
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 768px) {
    .Stationary .navbar-nav {margin-bottom: 20px;}
    .Stationary .hero-banner {padding-right: 40px; height: 60vh;}
    .Stationary .promo-banner {display: flex;}
    .Stationary .hero-banner h1 {max-width: 400px;}
    /* mobile navigation */
    .Stationary .navbar-light .navbar-nav .nav-item {border-bottom: 0;}
    .Stationary .mob-ddl {padding-left: 0;}
    .Stationary .mob-ddl {box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;}
    .Stationary .mob-ddl .dropdown-item.active, .Stationary .mob-ddl .dropdown-item:active {
      background-color: var(--white-color) !important;
      color: var(--primary-color);
    }
  
    .Stationary .header {padding-top: 30px;}
    .Stationary .header-top {justify-content: space-between;}
    .Stationary .header-top .nav-btn {display: none;}
    .Stationary .header-top .brand h6 {font-size: 16px;}
    .Stationary .header-top .brand a img {
      height: 40px;
      width: auto;
    }
    .Stationary .ico-chev {padding-right: 36px !important;}
    .Stationary .ico-chev::after {
      top: 22px;
      right: 16px;
    }
    .Stationary .mob-ddl {margin-right: 0;}
    .Stationary .login-register .user-icon {margin-right: 5px;}
    .Stationary .navbar-collapse {
      position: relative;
      width: auto;
      top: unset;
      left: unset;
      padding-left: 0;
      z-index: 1;
    }
    .Stationary .header-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .Stationary .search-wrapper.search-small {
      display: flex;
      width: 100%;
      justify-content: center;
    }
    .Stationary .search-wrapper .form-wrapper {width: 70%;}
    .Stationary .navbar-collapse {background-color: var(--white-color);}
    .Stationary .dropdown-item {
      color: var(--on-surface-color);
      background-color: var(--white-color);
    }
    .Stationary .login-register .user-name {display: block;}
    .Stationary .owl-nav {margin-top: 0 !important;}
    .Stationary .pg-footer .help {bottom: 0;top: -20px;}
    .Stationary .pg-footer .top-up  {top: -70px;}
    .Stationary .pg-footer .top-up .icon-up {box-shadow: var(--box-shadow-2); }
    .Stationary .prd-details-text {margin-top: 0;}
    .Stationary .comm-details {margin-top: 50px; margin-bottom: 0;}
    .Stationary .cart-buttons {flex-direction: row;}
    .Stationary .cart-buttons a.crt-btn-cont {margin-right: 15px;}
    .Stationary .cart-buttons a.crt-btn-checkout {margin-left: 15px;}
    
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 992px) {
    .Stationary .hero-banner {
      padding-right: 80px;
      height: 85vh;
      margin-bottom: 30px;
    }
    .Stationary .hero-banner h1 {font-size: 36px;line-height: 175%;}
    .Stationary .section.products {padding: 60px 0;}
    .Stationary .promo-bg-img {display: block;}
    .Stationary .pg-footer {padding: 60px 0 30px 0; margin-top: 60px;}
    .Stationary .pg-footer .brand-wrapper {flex-direction: row;}
    .Stationary .pg-footer .brand-wrapper .logo-wrap {margin-bottom: 0;margin-right: 10px;}
    .Stationary .section.blog .in-text-wrapper {min-height: 215px;}
    .Stationary .pg-footer .pay_cards {margin-top: 0;}
    .Stationary .prd-buttons .prd-add, .prd-buttons .prd-checkout {width: 47%;}
    .Stationary .prd-buttons .prd-add {margin-right: 20px;}
    .Stationary .cart-buttons a {width: 260px;}
    .Stationary .prd-pagination {flex-direction: row;}
    .Stationary .search-wrapper.search-small {
      display: none;
    }
    .Stationary .search-wrapper.search-large {
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      min-width: 600px;
    }
    .Stationary .brand {width: 30%;}
    .Stationary .search-wrapper.search-large {width: 40%;}
    .Stationary .login-right-wrapper {width: 30%;}
  }
  /* ----------------------------------------------------------------------- */
  @media (min-width: 1200px) {
    .Stationary .hero-banner h1 {font-size: 42px;max-width: 600px;}
    .Stationary .hero-banner button {margin-top: 24px;}
    .Stationary .search-wrapper .form-wrapper {width: 100%;}
    .Stationary .mob-order {order: 2;}
    .Stationary .acl-long {display: block;}
    .Stationary .acl-small {display: none;}
  }
  
  @media (min-width: 1400px) {
    .Stationary .hero-banner h1 {
      font-size: 54px;
      max-width: 800px;
    }
  
  }
  /* ----------------------------------------------------------------------- */