.fixed-header {
  background-color: #fff;
  padding: 8x 0px;
  color: #001529;
  width: 100%;
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.menu-toggle {
  text-align: right;
}

.menu-toggle button {
  color: #001529;
  font-size: 18px;
}

.search-bar,
.sign-in-menu {
  text-align: right;
}
.cart-nav-item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart-icon {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.noti-item {
  width: 16px;
  height: 16px;
  position: absolute;
  background-color: rgb(253, 143, 14);
  display: flex;
  justify-content: center;
  align-items: center;
  right: 12px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: 600;
  line-height: 12.66px;
  text-align: center;
  color: #fff;
}
.history-icon {
  height: 36px;
  width: 36px;
  margin-right: -5px !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.history {
  height: 36px;
  width: 36px;
}
.eco-button.eco-button--link:focus {
  background-color: transparent;
  /* border-color: #039be5; */
  box-shadow: 0 0 5px 0 rgba(3, 155, 229, 0.15);
  color: #039be5;
  text-decoration: underline;
  border: none !important;
  border-color: transparent;
}
/* Media Query for Small Screens (xs) */
@media (max-width: 575px) {
  .menu-toggle,
  .sign-in-menu {
    text-align: right;
  }

  /* .search-bar,
    .sign-in-menu {
      display: none;
    }
   */
  .menu-toggle button {
    font-size: 24px;
  }

  .fixed-header .ant-menu-item {
    font-size: 14px;
  }
}
