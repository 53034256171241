@font-face {
  font-family: 'casipillai';
  src: url('black_no.7_black_label-webfont.woff2') format('woff2'),
       url('black_no.7_black_label-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
.Cloths, .Cloths .accordion__title{
  font-family: 'casipillai' !important;
    letter-spacing: 0.025em;
    line-height: 1.6;    
}
.Cloths .accright{
  font-family: 'Futura', sans-serif !important;
  letter-spacing: 0.025em;
  line-height: 1.6;    
}
.Cloths .ant-divider-horizontal{
    margin: 5px 0 !important;  
}
 
.Cloths ul.headbanner, .Cloths ul.headicons {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.Cloths ul.headbanner li , .Cloths ul.headicons li{
  float: left;
}

.Cloths ul.headbanner li a, .Cloths ul.headicons li a {
  display: block;
  color: #000;
  text-align: center;
  padding: 8px;
  text-decoration: none;
}
.Cloths ul.headbanner li a:hover{
    background-color: #b8b8b8;
    color: #000;
  }
  .Cloths ul.headicons li a:hover{
  background-color:#FFF !important;
  color: #000;
}
.Cloths .navbar-light .navbar-nav .nav-link {
    color: #000 !important;
}

.Cloths ul.headcarts li{
margin-right: 25px;;
}

.Cloths .announcement-text {
    font-weight: bold;
}
.Cloths .announcement-bar {
    font-size: 14.45px;
    position: relative;
    text-align: center;
    background: rgb(195,150,34);
    background: linear-gradient(0deg, rgba(195,150,34,1) 0%, rgba(253,205,45,1) 100%); color: #000;
    padding: 10px;
    margin-right: 15px !important;
    margin-left: 15px !important;
}
.Cloths .linkGroup_3qKNM li a {
    color: #000;
    font-family: MontrealSerial-Bold, sans-serif;
    font-size: 12px;
}
.Cloths .carousel-caption h3{
  font-size: 3.75rem;
 }
 .Cloths .carousel-caption p{
  font-size: 2.75rem;
 }
 .Cloths .main > div > div {
  margin-bottom: 20px;
}
.Cloths .main .col-sm-6:first-child{
  padding-right: 10px;
}
.Cloths .main .col-sm-6{
  padding-left: 10px;
}
.Cloths .Mobilelogo{
  display: none !important;
}
.Cloths.navbar{
  color:#000;
}
.Cloths .card{
  min-height: 400px;
}
.Cloths .card.details {
  min-height: 100px;
}
.Cloths .bg-light {
    background-color: #FFF!important;  
}
.Cloths .stuck img{
  opacity:1;
  transition:opacity 0.5s linear;
}
.classlogo{
  display: none;
}
.Cloths .stuck .classlogo {
  display: block;
}
.Cloths .stuck .logo {
  display: none;
}
@media screen and (max-width: 990px) {
  .Cloths .logo{
    display: none !important;
}
.Cloths .stuck .logo {
  display: block;
}
.Cloths ul.headcarts li {
  margin-right: 0px;  
}
}