.OrderPage {
  .order-det {
    .eco-input-text .eco-input-text__wrapper input[type="default"],
    .eco-input-text .eco-input-text__wrapper input[type="email"],
    .eco-input-text .eco-input-text__wrapper input[type="number"],
    .eco-input-text .eco-input-text__wrapper input[type="password"],
    .eco-input-text .eco-input-text__wrapper input[type="tel"],
    .eco-input-text .eco-input-text__wrapper input[type="text"] {
      background-color: #f4f4f4 !important;
      border: none !important;
    }
  }
  .error-text {
    color: #ff0000;
    font-size: 12px;
    margin-top: 10px;
  }
  .Order-confirmation {
    .eco-product-add-ctl {
      width: 24px;
      height: 24px;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 0 20px;
      background-color: #f0f1f4;
    }

    .form-header {
      background-color: #f0f1f4;
      padding: 20px 30px;
      color: #4d4d4d;
      font-weight: 600;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .toggle-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin: -7px 0px;
        .toggle-tab {
          display: flex;
          align-items: center;
          border: 1px solid #c6c7d2;
          border-radius: 100px;
          .toggle-item {
            padding: 7px 20px;
            width: max-content;
            font-size: 14px;
            font-weight: 600;
            color: #242837;
            cursor: pointer;
          }
          .selected {
            background-color: #77649e;
            color: #fff;
            border-radius: 100px;
          }
        }
      }
    }
    .order-form {
      border: 1px solid #c6c7d2;
      border-radius: 8px;
      .order-form-container {
        padding: 20px 40px;
        .empty-cart {
          display: flex;
          justify-content: center;
          padding: 10% 0;
          .empty {
            .empty-text {
              font-size: 18px;
              font-weight: 400;
              line-height: 16px;
              color: #4d4d4d;
              text-align: center;
            }
          }
        }
        .details-label {
          font-size: 12px;
          font-weight: 400;
          line-height: 16px;
          text-align: left;
          color: #4d4d4d;
          display: flex;
          align-items: center;
        }
        .details-content {
          font-size: 14px;
          font-weight: 600;
          line-height: 16px;
          text-align: left;
          color: #242837;
          display: flex;
          align-items: center;
          .eco-product-add-ctl {
            width: 24px;
            height: 24px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin: 0 20px;
            background-color: #f0f1f4;
          }
        }
        .item-name {
          font-size: 15px;
          font-weight: 600;
          line-height: 7px;
          text-align: left;
        }
        .addon-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          column-gap: 20px;
          .addon-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 19.1px;
            text-align: left;
            color: #4d4d4d;
          }
          .addon-price {
            font-family: Inter;
            font-size: 16px;
            font-weight: 500;
            line-height: 26px;
            text-align: left;
            color: #242837;
          }
        }
      }
      .order-track-container {
        padding: 40px 40px;
        .order-error {
          color: #ff0000;
          font-size: 11px;
        }
        .order-track-left-container {
          .del-container {
            display: flex;
            column-gap: 16px;
            font-size: 16px;
            font-weight: 700;
            line-height: 28px;
            text-align: left;
            color: #0f172a;
            .address-track {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              margin-top: 8px;
            }
            .loc-container {
              height: 32px;
              width: 32px;
              border-radius: 50%;
              background-color: #039be5;
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 16px;
                width: 16px;
              }
            }
          }
          .track-steps-container {
            padding: 40px;
            background-color: #f9f9fa;
            width: 90%;
            margin-top: 26px;
            .border-track {
              height: 1px;
              background-color: #e2e8f0;
              width: 95%;
              margin-top: 32px;
              margin-left: 48px;
              margin-bottom: 32px;
            }
            .steps-container {
              display: flex;
              column-gap: 16px;
              font-size: 16px;
              font-weight: 700;
              line-height: 28px;
              text-align: left;
              color: #0f172a;
              position: relative;
              .content-track {
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                margin-top: 8px;
              }
              .left-border-step {
                position: absolute;
                width: 2px;
                height: 64px;
                background-color: #039be5;
                top: 42px;
                left: 16px;
              }
              .loc-container {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: #039be5;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 700;
                line-height: 16px;
                color: #ffffff;
              }
            }
          }
          .trck-btn-container {
            width: 90%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 47px;
            column-gap: 12px;
            .sec-btn {
              width: 140px;
              height: 35px;
            }
          }
        }
        .order-track-right-container {
          width: 100%;
          .restaurant-details {
            margin-top: 48px;
            .restp-header {
              display: flex;
              align-items: center;
              font-size: 18px;
              font-weight: 700;
              line-height: 20px;
              letter-spacing: 0.005em;
              text-align: left;
              color: #111a2c;
              column-gap: 19px;
              background-color: #f1f1f4;
              padding: 12px 24px;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
              .loc-container {
                height: 32px;
                width: 32px;
                border-radius: 50%;
                background-color: #039be5;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                  height: 16px;
                  width: 16px;
                }
              }
            }
            .rest-container {
              background-color: #f9f9fa;
              padding: 24px;
              .order-status-rest {
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-bottom: 1px solid #e2e8f0;
                padding-bottom: 18px;
                .status-text {
                  font-size: 15px;
                  font-weight: 700;
                  line-height: 24px;
                  text-align: left;
                  color: #0f172a;
                }
                .rate-btn {
                  cursor: pointer;
                  color: #039be5;
                  font-size: 11px;
                  font-weight: 700;
                  line-height: 28px;
                  text-align: right;
                  padding: 0px 10px;
                  border: 1px solid #039be5;
                  border-radius: 20px;
                }
              }
              .order-items {
                .order-item {
                  margin-top: 24px;
                  display: flex;
                  align-items: center;
                  column-gap: 10px;
                  border-bottom: 1px solid #e2e8f0;
                  padding-bottom: 24px;
                  .item-image {
                    width: 70px;
                    border-radius: 4px;
                  }
                  .order-item-details {
                    .item-text {
                      font-size: 16px;
                      font-weight: 600;
                      line-height: 24px;
                      text-align: left;
                      color: #0f172a;
                    }
                    .item-qty {
                      font-size: 14px;
                      font-weight: 600;
                      line-height: 20px;
                      text-align: left;
                      color: #475569;
                    }
                    .item-sub {
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      text-align: left;
                      color: #475569;
                    }
                  }
                }
              }
              .total-value-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 16px;
                padding-bottom: 32px;

                .total-value {
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 24px;
                  text-align: left;
                  color: #0f172a;
                }
                .total-value-price {
                  font-family: "Inter";
                  font-size: 24px;
                  font-weight: 600;
                  line-height: 32px;
                  text-align: right;
                  color: #0f172a;
                }
              }
            }
          }
        }
      }
    }
    .floor-dropdown {
      margin-bottom: 24px;
      .floor-drop-container {
        display: flex;
        align-items: center;
        column-gap: 16px;
        .label {
          font-size: 15px;
          font-weight: 600;
          line-height: 20.46px;
          color: #4d4d4d;
        }
        .select-floor {
          width: 100%;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #f4f4f4;
          border: none;
          border-radius: 3px;
          outline: none;
        }
      }
    }
  }
}
