.Button {
  width: max-content;
  padding: 15px 30px;
  background-color: #111;
  color: #fff;
  font-family: Lato;
  font-size: 11px;
  letter-spacing: 1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  .Jack-btn-loader {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.Secondary {
  background-color: #b5a46d;
}
