.InputComponent-Wrapper {
  height: max-content;
  .InputComponent-layout1 {
    width: 100%;
    height: 45px;
    input {
      height: 100%;
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: Jost;
      color: #121212;
      font-size: 15px;
      border-bottom: 1px solid #e4e4e4;
      padding-bottom: 5px;
    }
    input:focus {
      border-bottom: 1px solid #121212;
    }
    &.InputComponent-error {
      input {
        border-bottom: 1px solid rgb(235, 44, 44);
      }
    }
  }
  .InputComponent-layout2 {
    width: 100%;
    height: 100%;
    input {
      height: 100%;
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-family: Jost;
      color: #121212;
      font-size: 15px;
      border: 1px solid #e4e4e4;
      padding: 10px 15px;
      border-radius: 4px;
    }
    input:focus {
      border: 1px solid #121212;
    }
    &.InputComponent-error {
      input {
        border: 1px solid rgb(235, 44, 44);
      }
    }
  }
  /* For Chrome, Safari, Edge, and other WebKit browsers */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* For Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .InputComponent-error {
    font-family: Jost;
    font-size: 14px;
    color: rgb(235, 44, 44);
    padding-bottom: 5px;
  }
}
