.Ecommorce-Button {
  padding: 14px 20px;
  border-radius: 4px;
  background-color: #121212;
  color: #f1e6f2;
  font-family: Roboto;
  font-size: 16px;
  width: max-content;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #121212;
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #ffffff;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.Ecommorce-Button-invert {
  padding: 14px 20px;
  border-radius: 4px;
  background-color: #fff;
  color: #121212;
  font-family: Roboto;
  font-size: 16px;
  width: max-content;
  line-height: 16px;
  cursor: pointer;
  border: 1px solid #121212;
  display: flex;
  align-items: center;
  justify-content: center;
  .loader-btn {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-top: 2px solid #121212;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 0.7s linear infinite;
    margin-right: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
