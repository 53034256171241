.Popup-Menu {
  width: 450px !important;
  border-radius: 16px !important;
  .popup-inner {
    padding: 30px 0px;
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-container {
        position: relative;
        height: 102px;
        width: 102px;
        border-radius: 50%;
        background: var(--opacity-primary-color);
        display: flex;
        align-items: center;
        justify-content: center;
        .close-cont {
          position: absolute;
          height: 20px;
          width: 20px;
          background-color: #f44336;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-family: Roboto !important;
          font-size: 12px;
          text-align: center;
          color: #fff;
          right: 10px;
          top: 2px;
        }
        img {
          height: 42px;
          filter: var(--filter);
        }
      }
    }
    .delete-text {
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 600;
      line-height: 18.75px;
      color: #252b42;
      text-align: center;
      margin-top: 32px;
      margin-bottom: 32px;
    }
    .delete-btn-conatiner {
      width: 250px;
      background-color: var(--primary-color);
      height: 42px;
      border-radius: 8px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
      .loader-btn {
        border: 2px solid rgba(255, 255, 255, 0.3);
        border-top: 2px solid #ffffff;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 0.7s linear infinite;
        margin-right: 10px;
      }
    }
    .cancel-btn-conatiner {
      width: 250px;
      height: 42px;
      border-radius: 8px;
      font-family: Roboto !important;
      font-size: 16px;
      font-weight: 500;
      color: var(--primary-color);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      border: 1px solid var(--primary-color);
      cursor: pointer;
      margin-top: 8px;
    }
  }
}
