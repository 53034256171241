.EcommerceLogin {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .EcommerceLogin-container {
    width: 30%;
    margin: auto;
    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .logo {
        height: 80px;
      }
    }
    .login-form {
      margin-top: 40px;
      .login-sub-text {
        font-family: Jost;
        letter-spacing: -0.2px;
        color: #4b4b4b;
        margin-bottom: 30px;
        margin-top: 10px;
      }
      .input-container {
        margin-bottom: 15px;
      }
      .login-btn-container {
        margin-top: 40px;
      }
      .sign-up-content {
        margin-top: 30px;
        font-family: Inter;
        font-size: 14px;
        text-align: left;
        color: #6f767e;
        span {
          color: #000;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceLogin {
    .EcommerceLogin-container {
      width: 90%;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceLogin {
    .EcommerceLogin-container {
      width: 60%;
    }
  }
}
