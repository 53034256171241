.EcommerceWishlist {
  background-color: #12121209;
  height: 100%;
  padding-bottom: 10vh;
  .EcommerceWishlist-header {
    width: 100%;
    font-size: 36px;
    color: black;
    font-family: Jost;
    padding-top: 5vh;
    width: 80%;
    margin: auto;
    margin-bottom: 5vh;
  }
  .EcommerceWishlist-Container {
    width: calc(80% - 30px);
    padding: 30px;
    margin: auto;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: calc(25% - 22px) calc(25% - 22px) calc(25% - 22px) calc(
        25% - 22px
      );
    gap: 30px;
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceWishlist {
    .EcommerceWishlist-Container {
      width: calc(100% - 20px);
      padding: 10px;
      gap: 10px;
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceWishlist {
    .EcommerceWishlist-Container {
      width: calc(100% - 40px);
      padding: 10px;
      gap: 15px;
      grid-template-columns: calc(33% - 7.5px) calc(33% - 7.5px) calc(
          33% - 7.5px
        );
    }
  }
}
