.EcommerceProduct {
  width: 90%;
  margin: auto;
  height: 100%;
  position: relative;
  .EcommerceFilter-drawer {
    position: fixed;
    height: calc(100vh - 40px);
    background-color: #ffffff;
    z-index: 100000;
    top: 0;
    right: -100%;
    width: calc(90% - 40px);
    transition: right 0.3s ease;
    padding: 20px;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    &.open {
      right: 0;
    }

    .filter-drawer-header {
      font-size: 24px;
      color: black;
      font-family: Jost;
      text-align: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 4vh;
      align-items: center;
      .close-container {
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          cursor: pointer;
          height: 26px;
          width: 26px;
          filter: brightness(0) saturate(100%) invert(67%) sepia(4%)
            saturate(0%) hue-rotate(179deg) brightness(93%) contrast(93%);
        }
      }
    }
  }
  .EcommerceProduct-header {
    width: 100%;
    font-size: 36px;
    color: black;
    font-family: Jost;
    text-align: center;
    padding-top: 5vh;
    margin-bottom: 10vh;
    text-transform: capitalize;
  }
  .EcommerceContact-content {
    display: flex;
    width: 100%;
    column-gap: 50px;
    .EcommerceProduct-left {
      width: 20%;
      height: 100;
      display: block;
      .EcommerceProduct-left-header {
        font-family: Jost;
        color: #121212;
        font-size: 20px;
        margin-bottom: 20px;
        letter-spacing: -0.75px;
      }
    }
    .EcommerceProduct-right {
      width: 80%;
      font-family: Jost;
      height: 100%;
      padding-bottom: 10%;
      .header-content {
        .header-text {
          font-family: Jost;
          font-size: 22px;
          font-weight: 450;
          color: #000;
          margin-bottom: 15px;
        }
        .subtext-content {
          font-family: Jost;
          font-size: 14px;
          color: #252525;
          text-align: justify;
        }
      }
      .product-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 50px;
        .filter-container {
          font-family: Jost;
          font-size: 16px;
          color: #ffffff;
          background-color: #aaaaaa;
          padding: 2px 20px;
          border-radius: 2px;
          cursor: pointer;
          display: none;
          column-gap: 10px;
          align-items: center;
          img {
            height: 14px;
            filter: invert(1);
          }
        }
        .product-count {
          font-family: Jost;
          font-size: 16px;
          color: #6b6b6b;
        }
      }

      .products-list-container {
        gap: 20px;
        display: grid;
        grid-template-columns: auto auto auto auto;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceProduct {
    .EcommerceProduct-header {
      margin-bottom: 5vh;
    }
    .EcommerceContact-content {
      column-gap: 0px;
      .EcommerceProduct-left {
        display: none;
      }
      .EcommerceProduct-right {
        width: 100%;
        .product-content {
          margin-top: 30px;
          margin-bottom: 30px;
          .filter-container {
            display: flex;
          }
        }
        .products-list-container {
          gap: 10px;
          grid-template-columns: auto auto;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceProduct {
    .EcommerceFilter-drawer {
      width: calc(50% - 40px);
    }
    .EcommerceProduct-header {
      margin-bottom: 5vh;
    }
    .EcommerceContact-content {
      column-gap: 0px;
      .EcommerceProduct-left {
        display: none;
      }
      .EcommerceProduct-right {
        width: 100%;
        .product-content {
          margin-top: 30px;
          margin-bottom: 30px;
          .filter-container {
            display: flex;
          }
        }
        .products-list-container {
          gap: 10px;
          grid-template-columns: auto auto auto;
        }
      }
    }
  }
}
