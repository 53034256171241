.jumbled-container {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .jumbled-container-top {
    display: flex;
    gap: 10px;
    margin-left: 46px;

    .image-1 {
      height: 246px;
      width: 246px;
      object-fit: cover;
    }
    .image-2 {
      margin-top: 46px;
      height: 200px;
      width: 188px;
      object-fit: cover;
    }
  }

  .jumbled-container-bottom {
    display: flex;
    gap: 10px;

    .image-1 {
      height: 246px;
      width: 246px;
      object-fit: cover;
    }
    .image-2 {
      height: 200px;
      width: 188px;
      object-fit: cover;
    }
  }
}
@media only screen and (max-width: 700px) {
  .jumbled-container {
    display: block;
    .jumbled-container-top {
      display: block;
      margin-left: 0px;

      .image-1 {
        width: 100%;
        height: 300px;
      }
      .image-2 {
        margin-top: 0px;
        width: 100%;
        height: 300px;
      }
    }
    .jumbled-container-bottom {
      display: block;
      .image-1 {
        width: 100%;
        height: 300px;
      }
      .image-2 {
        width: 100%;
        height: 300px;
      }
    }
  }
}
@media only screen and (min-width: 700px) and (max-width: 900px) {
  .jumbled-container {
    display: block;
    .jumbled-container-top {
      .image-1 {
        width: calc(60% - 10px);

        height: 400px;
      }
      .image-2 {
        margin-top: 100px;
        width: calc(40% - 10px);
        height: 300px;
      }
    }
    .jumbled-container-bottom {
      width: 100%;
      margin-top: 10px;
      .image-1 {
        width: calc(60% - 10px);
        height: 400px;
      }
      .image-2 {
        width: calc(40% - 10px);
        height: 300px;
      }
    }
  }
}
