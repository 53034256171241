.CartItem {
  width: 100%;
  display: flex;
  column-gap: 15px;
  margin-bottom: 15px;
  .CartItem-Image {
    width: 33%;
    height: 140px;
    background-color: aqua;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .CartItem-details {
    width: 60%;
    .CartItem-title {
      font-size: 18px;
      font-family: Jost;
      color: #474747;
      font-weight: 500;
      margin-top: 10px;
    }
    .CartItem-price {
      font-size: 15px;
      color: #696969;
      margin-top: 5px;
    }
    .item-container {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 20px;
      .qty-container {
        width: max-content;
        border: 1px solid #e4e4e4;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        .qty-cont-item {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 36px;
          height: 40px;
          font-family: Jost;
          font-size: 18px;
        }
        .qty-cont-action {
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1;
          width: 36px;
          height: 40px;
          cursor: pointer;
          img {
            height: 12px;
            width: 10px;
          }
        }
      }
      .delete {
        color: #ff4343;
        cursor: pointer;
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .CartItem {
    .CartItem-Image {
      height: 190px;
    }
  }
}
