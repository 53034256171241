.RestaurantMenu {
  height: 100%;
  width: 100%;
  margin-top: -67px;
  .header-menu {
    height: 70vh;
    width: 100%;
    position: relative;
    .image-back {
      position: absolute;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      transition: opacity 0.8s ease-in-out;
      background-color: rgba(0, 0, 0, 0.63); /* Add a semi-transparent layer */
      background-blend-mode: overlay;
      background-position: center;
      .text {
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-family: Lustria;
        font-size: 56px;
      }
    }
  }
  .menu-container {
    width: 80%;
    margin: auto;
    margin-bottom: 20vh;
  }
}
@media only screen and (max-width: 600px) {
  .RestaurantMenu {
    .menu-container {
      width: 90%;
    }
  }
}
