.HomeHeroComponent {
  width: 100%;
  margin-top: 10vh;
  margin-bottom: 10vh;
  .details-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 26px;
    color: black;
    font-weight: 500;
    font-family: Jost;
  }
  .details-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    color: #535353;
    text-align: justify;
    line-height: 28px;
    margin-top: 30px;
    font-family: Roboto;
  }
}
@media only screen and (max-width: 600px) {
  .HomeHeroComponent {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .details-header {
      font-size: 24px;
      text-align: center;
    }
  }
}
