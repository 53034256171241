.SearchItem {
  width: 100%;
  display: flex;
  column-gap: 15px;
  margin-bottom: 15px;
  cursor: pointer;
  .SearchItem-Image {
    width: 60px;
    height: 60px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .SearchItem-details {
    width: 60%;
    .SearchItem-title {
      font-size: 15px;
      font-family: Jost;
      color: #474747;
      font-weight: 500;
      margin-top: 10px;
    }
    .SearchItem-price {
      font-size: 13px;
      color: #696969;
    }
  }
}
