.ContactUs {
  width: 100%;
  background-color: #fff;
  height: max-content;
  display: flex;
  padding-bottom: 10vh;
  .succes-alert {
    font-family: Lato;
    font-size: 14px;
    color: rgb(76, 173, 76);
    margin-top: 20px;
    opacity: 0;
    animation: fadeInalert 0.5s ease-in-out forwards;
  }
  .succes-alert.fade-out {
    animation: fadeOutalert 0.5s ease-out forwards;
  }

  @keyframes fadeInalert {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOutalert {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .ContactUs-left {
    flex: 1;
    padding: 50px;
    .ContactUs-left-header {
      display: flex;
      width: 100%;
      font-size: 26px;
      color: #121212;
      font-weight: 500;
      font-family: Jost;
    }
    .ContactUs-left-sub {
      display: flex;
      width: 80%;
      font-size: 16px;
      color: #535353;
      text-align: justify;
      line-height: 24px;
      margin-top: 30px;
      font-family: Roboto;
    }
    .contact-content {
      .contact-left {
        .contact-container {
          margin-top: 20px;
          .Contact-header {
            display: flex;
            font-size: 18px;
            color: #777777;
            font-weight: 500;
          }
          .contact-detail {
            margin-top: 10px;
            color: #969696;
            line-height: 24px;
          }
        }
      }
      .contact-right {
        .contact-container {
          margin-top: 20px;
          .Contact-header {
            display: flex;
            font-size: 18px;
            color: #777777;
            font-weight: 500;
          }
          .contact-detail {
            margin-top: 10px;
            color: #969696;
            line-height: 24px;
          }
        }
      }
    }
  }
  .ContactUs-right {
    flex: 1;
    height: 100%;
    background-color: #1212120c;
    border-radius: 8px;
    padding: 50px;
    .ContactUs-right-header {
      display: flex;
      width: 100%;
      font-size: 26px;
      color: #121212;
      font-weight: 500;
      font-family: Jost;
    }
    .form-input-container {
      margin-top: 30px;
      width: 100%;
    }
    .btn-container {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .ContactUs {
    flex-direction: column;
    .ContactUs-left {
      padding: 0px;
      margin-bottom: 50px;
    }
    .ContactUs-right {
      padding: 30px;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .ContactUs {
    flex-direction: column-reverse;
    .ContactUs-left {
      padding: 0px;
      margin-bottom: 50px;
      .ContactUs-left-header {
        justify-content: center;
      }
      .ContactUs-left-sub {
        justify-content: center;
        text-align: center;
        width: 90%;
        margin: auto;
        margin-top: 30px;
      }
      .contact-content {
        display: flex;
        width: 90%;
        margin: auto;
        justify-content: space-between;
        .contact-right {
          .contact-container {
            .Contact-header {
              justify-content: flex-end;
            }
            .contact-detail {
              text-align: right;
            }
          }
        }
      }
    }
    .ContactUs-right {
      padding: 30px;
      margin-bottom: 50px;
    }
  }
}
