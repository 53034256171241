.sliding-drawer-container {
  .drawer {
    position: fixed;
    top: 0;
    right: -100%;
    width: calc(25% - 40px);
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    transition: right 0.3s ease;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 20px;
    &.open {
      right: 0;
    }

    .drawer-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 70%;
      .header-conatiner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;

        .close-cont {
          height: 18px;
          width: 18px;
          cursor: pointer;
          img {
            height: 18px;
            width: 18px;
          }
        }
      }
      .items-container {
        margin-top: 10px;
        height: 70%;
        overflow-y: auto;
      }
      .empty-cart {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        width: calc(100% - 30px);
      }
    }
    .bottom-container-div {
      width: 100%;
      .price-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        margin-top: 10px;
        .price-cont {
          font-family: Jost;
          font-size: 18px;
          color: #000;
        }
      }
      .button-container {
        width: 100%;
        display: flex;
        column-gap: 20px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .sliding-drawer-container {
    .drawer {
      width: 100%;
      height: 100dvh;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .sliding-drawer-container {
    .drawer {
      width: 80%;
      height: 100dvh;
    }
  }
}
