.SearchComponent {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 50%;
  background: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: top 0.3s ease;
  z-index: 1000;
  display: none;
  justify-content: center;
  align-items: center;
  .search-input-container {
    width: 33.2%;
    display: flex;
    position: relative;
    .middle-container {
      display: flex;
      align-items: center;
      width: 100%;
      .search-input {
        width: calc(100% - 30px);
        height: 100%;
        border: 1px solid #e4e4e4;
        border-radius: 4px;
        display: flex;
        align-items: center;
        input {
          padding: 15px 15px;
          width: 100%;
          background-color: transparent;
          border: none;
          outline: none;
          font-family: Jost;
          color: #121212;
          font-size: 15px;
        }
        input::placeholder {
          font-family: Jost;
          font-weight: 300;
          color: #474747;
        }

        &:focus-within {
          border: 1px solid #474747; /* change border of the parent when input is focused */
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.171); /* add box-shadow to the parent */
        }
        .search-icon {
          height: 20px;
          width: 40px;
          display: flex;
          img {
            height: 20px;
            filter: brightness(0) saturate(100%) invert(30%) sepia(3%)
              saturate(5%) hue-rotate(21deg) brightness(102%) contrast(89%);
          }
        }
      }
      .close-container {
        width: 30px;
        height: 30px;
        margin-left: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 20px;
          width: 20px;
        }
      }
    }

    .search-results {
      position: fixed;
      border-left: 1px solid #e4e4e4;
      border-right: 1px solid #e4e4e4;
      border-bottom: 1px solid #e4e4e4;
      margin-top: 55px;
      width: calc(30% - 41.5px);
      background-color: #fff;
      padding: 20px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      .search-header {
        font-family: Jost;
        color: #646464;
        font-size: 14px;
        letter-spacing: -0.3px;
        font-weight: 450;
        padding-bottom: 5px;
        border-bottom: 1px solid #e4e4e4;
        margin-bottom: 20px;
      }
      .search-results-container {
        max-height: 40vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        .empty-result {
          font-family: Jost;
          font-size: 14px;
        }
      }
    }
  }
}

.SearchComponent.open {
  top: 0;
  display: flex;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  z-index: 999;
}

.overlay.visible {
  opacity: 1;
  visibility: visible;
}
@media only screen and (max-width: 600px) {
  .SearchComponent {
    height: 30%;
    .search-input-container {
      width: 90%;
      .search-results {
        width: calc(77.5% - 41.5px);
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .SearchComponent {
    height: 40%;
    .search-input-container {
      width: 90%;
      .search-results {
        width: calc(77.5% - 41.5px);
      }
    }
  }
}
