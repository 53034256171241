.TextareaComponent {
  width: 100%;
  height: 100px;
  textarea {
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-family: Jost;
    color: #121212;
    font-size: 15px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 5px;
    resize: none;
  }
  textarea:focus {
    border-bottom: 1px solid #121212; /* Replace with your desired color */
  }
  &.TextareaComponent-error {
    textarea {
      border-bottom: 1px solid rgb(235, 44, 44);
    }
  }

  .TextareaComponent-error {
    font-family: Jost;
    font-size: 14px;
    color: rgb(235, 44, 44);
    padding-bottom: 5px;
  }
}
