.HomeComponentFour {
  margin-top: 10vh;
  margin-bottom: 10vh;
  .details-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 26px;
    color: black;
    margin-bottom: 5vh;
    font-weight: 500;
    font-family: Jost;
  }
  .collection-row-one {
    width: 100%;
    margin: auto;
    display: grid;
    align-items: center;
    gap: 20px;
    grid-template-columns: auto auto auto auto;
    .item-collection {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      height: 58vh;
      flex: 1;
      background-color: antiquewhite;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 5vh;
      border-radius: 4px;
      .title-text {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        font-family: Jost;
        color: #fff;
        font-size: 24px;
      }
      .button-container {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 15px;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .HomeComponentFour {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .collection-row-one {
      grid-template-columns: auto auto;
      gap: 10px;
      .item-collection {
        height: 30vh;
        padding-bottom: 2vh;
        .title-text {
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .HomeComponentFour {
    margin-top: 5vh;
    margin-bottom: 5vh;
    .collection-row-one {
      grid-template-columns: auto auto;
      gap: 10px;
      .item-collection {
        height: 30vh;
        padding-bottom: 2vh;
        .title-text {
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }
}
