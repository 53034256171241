.EcommerceAboutus {
  width: 100%;
  padding-bottom: 10%;
  .EcommerceAboutus-header {
    width: 100%;
    padding-top: 5vh;
    margin-bottom: 5vh;
  }
  .EcommerceHome-hearo-video {
    height: 70vh;
    width: 80%;
    margin: auto;
    border-radius: 20px;
  }
  .EcommerceAboutus-Content {
    width: 80%;
    margin: auto;
    margin-top: 10vh;
    .content {
      margin-top: 20px;
    }
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceAboutus {
    .EcommerceHome-hearo-video {
      width: 90%;
      height: 30vh;
    }
    .EcommerceAboutus-Content {
      width: 90%;
      margin-top: 5vh;
    }
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceAboutus {
    .EcommerceHome-hearo-video {
      width: 90%;
      height: 30vh;
    }
    .EcommerceAboutus-Content {
      width: 90%;
      margin-top: 5vh;
    }
  }
}
