.finder {
  width: 600px !important;
  border-radius: 16px !important;
  .finder-inner {
    padding: 30px 0px;
    .inner-container {
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-container {
        height: 102px;
        width: 102px;
      }
    }
    .finder-header-text {
      margin-top: -20px;
      display: flex;
      justify-content: space-between;

      .header-text {
        text-align: left;
        font-family: Roboto !important;
        font-size: 18px;
        font-weight: 600;
        line-height: 23.44px;
        color: #252b42;
        display: flex;
        align-items: center;
        gap: 5px;
        img {
          height: 20px;
          filter: brightness(0) saturate(100%) invert(15%) sepia(27%)
            saturate(767%) hue-rotate(189deg) brightness(95%) contrast(95%);
        }
      }

      .close-icon {
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          height: 30px;
          width: 30px;
        }
      }
    }
    .finder-sub-text {
      font-family: Roboto !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 25.6px;
      text-align: left;
      color: #818b9c;
      margin-top: 12px;
    }
    .formfinder {
      margin-top: 30px;
    }
    .btn-container {
      width: 100%;
      display: flex;
      margin-top: 32px;
      justify-content: flex-end;

      .btn-cony {
        font-family: Roboto !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: -0.20000000298023224px;
        text-align: center;
        color: #ffffff;
        background: var(--primary-color);
        padding: 10px 20px;
        border-radius: 8px;
        width: 200px;
        cursor: pointer;
        .loader-btn {
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-top: 2px solid #ffffff;
          border-radius: 50%;
          width: 20px;
          height: 20px;
          animation: spin 0.7s linear infinite;
          margin: auto;
        }

        @keyframes spin {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
