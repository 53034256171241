.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .checkbox-input {
    display: none;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-size: 13px;
    color: #707070;
    font-family: Roboto;
    .checkbox-custom {
      width: 14px;
      height: 14px;
      border: 2px solid #929292;
      border-radius: 3px;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      transition: background-color 0.3s, border-color 0.3s;

      &:after {
        content: "";
        width: 4px;
        height: 9px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
        display: none;
        margin-top: -3px;
      }
    }

    &:hover .checkbox-custom {
      border-color: #121212;
    }
  }

  .checkbox-input:checked + .checkbox-label .checkbox-custom {
    background-color: #121212;
    border-color: #121212;

    &:after {
      display: inline-block;
    }
  }
}
