.Layout {
  .layout3 {
    display: grid;
    grid-template-columns: 50% 50%;
    column-width: 50%;
  }
  .layout4 {
    display: grid;
    grid-template-columns: 50% 50%;
    column-width: 50%;
  }
  .layout5 {
    display: grid;
    grid-template-columns: 50% 50%;
    column-width: 50%;
  }
  .title-menu {
    font-size: 18px;
    color: #fff;
  }
  .column-styles {
    padding: 0.625rem;
    .column {
      display: flex;
      justify-content: space-between;
      background-color: #f5f5f5;
      border-radius: 0.5rem;
      padding: 0.313rem 0 0.313rem 0;
      .item-details {
        display: flex;
        padding: 0.5rem 1rem;
        column-gap: 0.6235rem;
        width: 80%;
        .item-dela-cont {
          height: 10rem;
          width: 10rem;
          border-radius: 0.5rem;
          position: relative;
          .item-image {
            height: 10rem;
            width: 10rem;
            border-radius: 0.5rem;
            object-fit: cover;
            background-color: #dddbdd;
          }
          .deal {
            position: absolute;
            // height: 2rem;
            // width: 2rem;
            top: 12px;
            transform: rotate(-35deg);
            margin-left: -8px;
            .trp {
              background-color: #158cff;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 12px;
              transform: perspective(1px) rotateX(1deg);
              height: 14px;
              width: 60px;
              padding-top: 2px;
            }
          }
        }

        .item-des {
          padding: 0.5rem;
          .name {
            font-size: 16px;
          }
          .details {
            padding: 0.313rem 0;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
          }
        }
      }
      .price-details {
        padding: 0.5rem 1.5rem;
        text-align: right;
        .price {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
}
