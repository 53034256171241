.ContactForm {
  width: 80%;
  margin: auto;
  .succes-alert {
    font-family: Lato;
    font-size: 14px;
    color: rgb(76, 173, 76);
    margin-top: 20px;
    opacity: 0;
    animation: fadeInalert 0.5s ease-in-out forwards;
  }
  .succes-alert.fade-out {
    animation: fadeOutalert 0.5s ease-out forwards;
  }

  @keyframes fadeInalert {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fadeOutalert {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .input-container-flex {
    display: flex;
    gap: 50px;
    margin-top: 40px;
  }
  .btn-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
}
@media only screen and (max-width: 600px) {
  .ContactForm {
    width: 100%;
    .input-container-flex {
      flex-direction: column;
    }
  }
}
