.submit__btn {
    float: right;
    background-color: #5E81F4 !important;
    color: aliceblue !important;
    border: #5E81F4 !important;
    text-align: center;
    margin: 0 auto;
}

/* p{
    color: #fff;
    padding: 0;
    line-height: 0px !important;
    text-align: center;
    margin-bottom: 0 !important;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield !important; /* Firefox */
}


/* VALIDATION MESSAGE */
.help-text-with-errors{
    color: red;
    
     
  }

  .publish-btn{
    width: 150px;
    float: right;
    background-color: #5E81F4 !important;
    color: aliceblue !important;
    border: #5E81F4 !important;
    text-align: center;
    margin: 0 auto;
  }