.Hospitality-navbar-MenuTabs {
  position: relative;
  .tab-headers {
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    background: #fff;
    position: sticky;
    top: 62px;
    z-index: 1;
    border-bottom: 1px solid #eeeeee;
    font-family: Roboto !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.06px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;
    color: #7c8091;
  }
  .tab-item {
    padding: 10px;
    cursor: pointer;
  }
  .selected-tab-item {
    color: #1a7cb0;
    font-weight: 800;
    font-size: 16px;
  }
  .tab-content-container {
    padding: 10px 0px;
    height: 100%;
    overflow-y: auto;
    max-width: 1200px;
    margin: auto;
    width: 100%;
  }
  .tab-popup {
    max-height: 60vh !important;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .tab-popup::-webkit-scrollbar {
    width: 0px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #808080;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #808080;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #808080;
  }
  h2.eco-tab-headers-title {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    color: #333333;
    font-family: "Jost";
  }
}
@media only screen and (max-width: 600px) {
  .Hospitality-navbar-MenuTabs {
    .tab-headers {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
      padding-left: 10px;
      padding-right: 10px;
      top: 126px;
    }
  }
}
