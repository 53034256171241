@import url('https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css');
@import url('https://use.fontawesome.com/releases/v5.13.0/css/all.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
 
html {
	/* font-size: 100%; */
  }
body {
 font-size: 14px;
 font-family: 'Poppins', sans-serif;
 padding: 0;
 margin: 0;
 background-color: #ffffff;
}
.Apparel-casi .react-grid-item{
	width: 100% important;
}
.Apparel-casi .ant-slider-track {
    background-color:transparent !important;
}
.Apparel-casi .ant-slider-handle {
    border: solid 2px #e7dcc4 !important;
}
.Apparel-casi .container-fluid{
    padding-right: 0px;
    padding-left: 0px;
}
/* Overlay Background -------------------------------------------------------------- */
.showSearch  .site-overlay {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 1010;
	display: block;
	background-color: rgba(206, 157, 55, 0.55);
}
/* Site Brand ---------------------------------------------------------------------- */
.Apparel-casi .site-brand {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
	background-color: #faf5e9;
}
.Apparel-casi .site-brand .inner-right {
    display: flex;
    font-size: 16px;
}

.Apparel-casi .image-gallery-slides img{
	width: 100%;
}
.Apparel-casi .ant-input-affix-wrapper > input.ant-input{
	position: absolute;
	width: 85%;
}
.Apparel-casi .ant-input-suffix{
	float: right;
}
/* Site Header Inside -------------------------------------------------------------- */
.Apparel-casi .search-control {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    padding: 20px 10px;
	z-index: 1011;
	display: none;
}
.showSearch .search-control {display: block;}
.Apparel-casi .search-control input {
	border: 0;
	font-weight: lighter;
}
.Apparel-casi .search-control input:focus, .search-control input:active {
	border-color: #ce9d37;
	box-shadow: none;
}
.Apparel-casi .search-control button {
	background-color: #ce9d37;
	height: 100%;
	border: 1px solid #ce9d37;
    border-radius: .25rem;
	border-top-left-radius: 0;
    border-bottom-left-radius: 0;
	color: #ffffff;
	font-weight: 500;	
}
.Apparel-casi .search-control button:hover {color: #ffffff;}
.Apparel-casi .search-control button:focus {box-shadow: none;}
.Apparel-casi .inner-left {display: flex; align-items: center;}
.Apparel-casi .logo img {height: 75px;}
.Apparel-casi .header-right {display: flex;align-items: center;}
.Apparel-casi .btn-search {
	color:#ce9d37;
	font-size: 20px;
	margin-right: 5px;
}
.Apparel-casi .header-right .Apparel-casi .top-link-wrapper {position: relative;}
.Apparel-casi .header-right ul {transition: 0.3s;}
.Apparel-casi .header-right ul a {
 text-decoration: none;
 padding: 0 5px; 
 display: flex;
 align-items: center;
 height: 100%;
 color: #000000;
}
.Apparel-casi .header-right ul a .favourite-items i,
.Apparel-casi .header-right ul a .shopping-cart-items i,
.Apparel-casi .header-right ul a .user-icon i
{font-size: 20px;}
.Apparel-casi .bg-round {
	color: #ce9d37;
	background-color: #ffffff;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
	transition: all .3s;
}
.Apparel-casi .bg-round:hover {background-color: #fcf3e1;}
.Apparel-casi .header-right ul a.currency-switch {
	padding-right: 0;
	margin-right: 5px;
}
.Apparel-casi .currency-switch .currency-name {
 font-size: 14px;
 display: inline-block;
 max-width: 70px;		
 white-space: nowrap; 
 text-overflow:ellipsis;
 overflow: hidden;
 text-align: right;
 color: #ce9d37;
} 
.Apparel-casi .currency-switch .currency-text {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .mobile-menu {display:flex;margin-left: 10px;}
.Apparel-casi .mobile-menu a {
	text-decoration: none;
    padding: 0 5px;
    color: #ce9d37;
}
.Apparel-casi .mobile-menu a i {font-size: 20px;}
.Apparel-casi .count {
	position: absolute;
	right: 0;
	bottom: -3px;
	font-size: 10px;
	font-weight: 700;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	line-height: 16px;
	text-align: center;
	color: #ffffff;
	background-color: #f60303;
}
.Apparel-casi .loggedin-user {
	padding: 5px 10px;                            
	text-align: center;
	color: #ce9d37;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
}
.Apparel-casi .hr-sep {display: flex; justify-content: center;}
.Apparel-casi .hr-sep hr{
	border-color: #d9d9d9;
	margin: 5px 0;
	width: 75%;
}
.Apparel-casi .header-right ul .dropdown-menu  a {padding:7px 10px;border-radius: .25rem;}
.Apparel-casi .header-right ul li .dropdown-menu a {
	font-size: 14px;
	padding: 7px 20px;
	position: relative;
    white-space: initial;
    line-height: 1.25;
	color: #8d6717;
}
.Apparel-casi .header-right ul .dropdown-menu a:hover {color: #8d6717;background-color: #f9f9f9;}
.Apparel-casi .header-right ul li .dropdown-menu .dropdown-item i {font-size: 16px;}
.Apparel-casi .profile-name {margin-left:5px; display:none;}
/* overriding default bootstrap values */
.Apparel-casi .row {margin:0;padding:0}
.Apparel-casi .dropdown-menu {
	z-index: 1040;
	background-color: #ffffff;
}
.Apparel-casi .dropdown-menu.show {
	margin-top: 3px;
	border-radius: 0;
	box-shadow: 0 1px 3px rgba(0,0,0,.2);
	padding:5px 0;
	border:0;
}
.Apparel-casi .dropdown-menu.show::after {
	border-bottom: 8px solid;
	border-color: #ffffff;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
	top:-8px;
   	content: "";
    display: inline-block;
    right:8px;
    position: absolute;
    z-index: 1001; 
}
.Apparel-casi .drop-down-icon-hide::after{display: none;}
.Apparel-casi .dropdown-toggle::after {color: #ce9d37;}


/* new navigation styles */
.Apparel-casi .navbar {
	background-color: #faf5e9;
	padding-top: 0;
	padding-bottom: 0;
}
.Apparel-casi .navbar button.navbar-toggler {
    position: absolute;
    top: -65px;
    left: 63px;
    color: #ce9d37;
    font-weight: 900;
}
.Apparel-casi .navbar button.navbar-toggler:focus {outline: none;}
.Apparel-casi .navbar ul li a {
	font-size: 16px;
    font-weight: 600;
	color: #ce9d37;
}
.Apparel-casi .navbar .dropdown-menu.show::after {display: none;}
.Apparel-casi .navbar ul li .dropdown-menu a.dropdown-item {font-weight: 400;}
.Apparel-casi .navbar ul li .dropdown-menu a.dropdown-item:hover {
	background-color: #f6f6f6;
    color: #ce9d37;
}

/* Welcome Message ----------------------------------------------------------------- */
.Apparel-casi .welcome-message {
	margin-top: 20px;
	padding: 15px;
}
.Apparel-casi .welcome-message .welcome-message-inner {
	box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
    border-radius: 8px;
	width: 100%;
	margin: 0 auto;
	background-color: #ffffff;
	background-repeat: no-repeat;
    background-position: left bottom;
}
.Apparel-casi .welcome-message h4 {
	text-align: center;
	margin: 0;
	padding: 15px 0;
}
.Apparel-casi .welcome-message .message-wrapper {padding-bottom: 10px;}
.Apparel-casi .welcome-message .message-wrapper p {
	font-size: 14px;
	line-height: 1.5rem;
	margin: 0;
	padding-left: 0;
	text-align: justify;
}
/* Our Collections Large ----------------------------------------------------------- */
.Apparel-casi .our-collections {display:none !important;}
.Apparel-casi .our-collections .collection-imgs {position: relative;}
.Apparel-casi .our-collections .collection-imgs .img-desc {
	position: absolute;
	top: 10px;
	left: 10px;
	right: 10px;
	padding: 10px;
	background-color: rgba(206,157,55,0.57);
	border-radius: 4px;
	color:#ffffff;
}
.Apparel-casi .our-collections a {text-decoration: none;}
.Apparel-casi .our-collections h4 {text-align: center;}
.Apparel-casi .our-collections .collection-text {
	width: 100%;
	background-color: #ce9d37;
	color: #ffffff;
	padding: 10px;
}
.Apparel-casi .mt15 {margin-top: 20px !important;}
.Apparel-casi .our-collections .collection-text h6 {font-size: 16px; color: #ffffff;}
.Apparel-casi .our-collections .collection-text p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 0;
	min-height: 55px;
	max-height: 55px;
	overflow: hidden;
}
.Apparel-casi .our-collections .collection-text p.full-w {
	min-height: unset;
	max-height: unset;
}
.Apparel-casi .mr15 {margin-right: 15px;}
.Apparel-casi .our-collections .collection-title a:hover {background-color: rgba(206,157,55,1);}
/* Our Collections / Image Gallery ------------------------------------------------- */
.Apparel-casi .our-collections .collection-img .zoom-img {overflow: hidden;}
.Apparel-casi .our-collections .collection-img img {
	width: 100%;
	max-width: 100%;
	height: auto;
	transition: all 0.5s;
	height: 100%;
}
/* Our Collections Mobile ---------------------------------------------------------- */
.Apparel-casi .our-collections-mobile {margin-top: 20px;}
.Apparel-casi .our-collections-mobile h4 {text-align: center;}
.Apparel-casi .our-collections-mobile .item-text {
	width: 100%;
    background-color: #ce9d37;
    color: #ffffff;
    padding: 10px;
}
.Apparel-casi .our-collections-mobile .item-text h6 {font-size: 14px;font-weight: 600;}
.Apparel-casi .our-collections-mobile .item-text p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0;
    min-height: 70px;
    max-height: 70px;
    overflow: hidden;
}
.Apparel-casi .our-collections-mobile a {text-decoration: none;}
/* Promotions ---------------------------------------------------------------------- */
.Apparel-casi .promotions {
	margin-top: 20px;
}
.Apparel-casi .promotions h4 {text-align: center;}
.Apparel-casi .promotions .promo-item a {
	width: 100%;
	height: 100;
}
/* image zoom */
.Apparel-casi .promotions .promo-item a img {
	transition: all 0.3s;
	transform: scale(1);
}
.Apparel-casi .promotions .promo-item a img:hover {
	transform: scale(1.3);
}
/* /image zoom */
.Apparel-casi .promotions .promo-item .promo-item-wrapper {position: relative;overflow: hidden;}
.Apparel-casi .promotions .promo-item .discount {
	position: absolute;
    top: 20px;
    right: 20px;
    z-index: 3;
    background-color: rgba(206,157,55,.7);
	color: #ffffff;
    width: 50px;
    height: 50px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
	font-size: 14px;
	line-height: 18px;
    font-weight: 700;
    text-align: center;
    -webkti-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
	display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.Apparel-casi .promotions .promo-item .promo-name {
	position: absolute;
	bottom: 20px;
	left: 0;
	background-color: rgba(206,157,55,.7);
	padding: 10px;
	color:#fff;
	font-size: 14px;
	font-weight: 600;
	width:100%;
}
/* owl carousel style overriding */
.Apparel-casi .owl-theme .owl-dots .owl-dot span {
	background: none;
	border: 1px solid #ce9d37;
}
.Apparel-casi .owl-theme .owl-dots .owl-dot.active span, .Apparel-casi  .owl-theme .owl-dots .owl-dot:hover span {
	background: #ce9d37;
}
.Apparel-casi .owl-dots button:focus {outline: none !important;}
.Apparel-casi .owl-theme .owl-nav [class*=owl-]:hover {
	background: none;
	color: #ce9d37;
	text-decoration: none;
}
.Apparel-casi .owl-nav button:focus {outline: none !important;}
.Apparel-casi .owl-nav button i {font-size: 20px;}
.Apparel-casi .owl-nav {margin-bottom:10px;}
/* Make Appointment/Weaving Process ------------------------------------------------ */
.Apparel-casi .appointment {
	margin-top: 20px;
}
.Apparel-casi .appointment h4 {
	text-align: center;
	font-size: 20px;
}
.Apparel-casi .appointment .inner-wrap {
	display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
	border-radius: 8px;
	background-color: #fff;
	padding: 20px;
	margin-bottom: 20px;
	box-shadow: 0px 1px 10px 0px rgb(0 0 0 / 10%);
}
.Apparel-casi .appointment .inner-wrap p {
	padding: 10px 0;
	margin-bottom: 0;
	text-align: center;
	font-size: 16px;
	line-height: 24px;
}
/* Testimonials - Home Page -------------------------------------------------------- */
.Apparel-casi .testimonials {
	margin-top: 20px;
}
.Apparel-casi .testimonials h4 {text-align: center;}
.Apparel-casi .testimonials .testimonials-item {background-color: #ffffff;}
.Apparel-casi .testimonials .testimonials-item-inner {
	border:1px solid #e8e8e8;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.Apparel-casi .testimonials .testimonials-user {
	display: flex;
}
.Apparel-casi .testimonials .testimonials-item img  {
	border-radius: 50%;
	width: 50px;
}
.Apparel-casi .testimonials .user-name {font-weight: 600;font-size: 14px;}
.Apparel-casi .testimonials .user-rating { padding: 0 10px;}
.Apparel-casi .testimonials .user-rating .rating-starts {
 color: #FDCC0D;
 font-size: 10px;
}
.Apparel-casi .testimonials .testimonials-text {
	padding: 10px 0;
	margin-bottom: 0;
	color: #818181;
}
.Apparel-casi .testimonials .btn-view {
	display: flex;
    justify-content: center;
}
/* Site Footer --------------------------------------------------------------------- */
.Apparel-casi .footer-hr {border-top: 1px solid #d9d9d9;}
.Apparel-casi .site-footer {
	background-color: #faf5e9;
	margin-top: 50px;
}
.Apparel-casi .site-footer .text-footer {
	margin: 20px 0;
	font-weight: 700;
	color: #ce9d37;
}
.Apparel-casi .site-footer .contact-info {color:#818181;}
.Apparel-casi .site-footer .copytext {
	text-align: center;
	color: #818181;
	font-size: 12px;
	padding-bottom: 20px;
	padding-top: 5px;
}
.Apparel-casi .site-footer .contact-info ul {
	list-style: none;
	padding-left: 0;
	margin-bottom: 0;
}
.Apparel-casi .site-footer .contact-info ul li {
	line-height: 24px;
	font-size: 14px;
}
.Apparel-casi .site-footer .contact-info ul.cont-direct {
	margin-top: 20px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links {
	margin-top: 20px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li {
	display: inline-block;
	
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li a {
	padding: 0 10px 10px 0;
	color: #818181;
	font-size: 30px;
}
.Apparel-casi .site-footer .contact-info ul.social-media-links li a:hover {color: #000000;}
.Apparel-casi .site-footer .contact-info .cards-text {
	margin-top:20px;
	margin-bottom: 5px;
	font-size: 14px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards li {
	display: inline-block;
	margin-right: 5px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards i {
	font-size: 40px;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .visa_cd {
	color: #0066b1;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .mast_cd {
	color: #374961;
}
.Apparel-casi .site-footer .contact-info ul.pay-cards .amex_cd {
	color: #006ecf;
}
.Apparel-casi .site-footer .user-agreement {
	text-align: center;
    font-size: 12px;
    padding-bottom: 20px;
    padding-top: 5px;
}
.Apparel-casi .site-footer .user-agreement a {
	text-decoration: none;
	color:#818181;
	margin-right: 10px;
}
.Apparel-casi .site-footer .user-agreement a:hover {text-decoration: underline;}
.Apparel-casi .mt20 {margin-top: 20px;}
/* Map */
.Apparel-casi .site-footer .map-responsive{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
}
.Apparel-casi .site-footer .map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}
/* Common Styles ------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------- */
/* required fields */
.Apparel-casi .required::after {
    content: " *";
    color: #ff1200;
}
/* button styles */
.Apparel-casi .btn-act-primary {
	text-decoration: none;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	padding: 5px 10px;
	min-width: 100px;
	color: #ffffff;
	background-color: #ce9d37;
	border: 2px solid;
	border-color: #ce9d37;
	border-radius: 4px;   
    transition: all .7s;
}
.Apparel-casi .btn-act-primary:hover {
	text-decoration: none;
	color: #ffffff;
}
.Apparel-casi .btn-act-secondary {
	text-decoration: none;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	padding: 5px 10px;
	min-width: 100px;
	color: #ce9d37;
	background-color: #ffffff;
	border-color: #ce9d37;
	border: 2px solid;
	border-radius: 4px;   
    transition: all .7s;
}
.Apparel-casi .btn-act-secondary:hover {
	text-decoration: none;
	color: #ce9d37;
	background-color: #ffffff;
	border-color: #ce9d37;
}
/* /button styles */
/* Site Breadcrumb */
.Apparel-casi .breadcrumb-container {padding-top: 20px;}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb {
	margin-bottom: 0;
	padding:0;
	list-style:none;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li {
	display:inline-block;
	padding-right:10px;
	position:relative;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li a {
	color: #ce9d37;
	font-size: 14px;
	text-decoration: none;
}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li::after {content: '>';font-size: 12px;position: absolute;right: 0;top: 3px;}
.Apparel-casi .breadcrumb-in ul.site-breadcrumb li:last-child::after {content: '';}
/* //Site Breadcrumb */
/**/
/* Custom checkbox */
/* The container */
.Apparel-casi .chbx-holder {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
  }
  /* Hide the browser's default checkbox */
  .Apparel-casi .chbx-holder input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
  }
  /* Create a custom checkbox */
  .Apparel-casi .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
	border-radius: 4px;
  }
  /* Create the checkmark/indicator (hidden when not checked) */
  .Apparel-casi .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  /* Show the checkmark when checked */
  .Apparel-casi .chbx-holder input:checked ~ .checkmark:after {
	display: block;
  }
  /* Style the checkmark/indicator */
  .Apparel-casi .chbx-holder .checkmark:after {
	left: 8px;
	top: 4px;
	width: 7px;
	height: 13px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }
/* Red */
.Apparel-casi .chbx-holder.red input:checked ~ .checkmark, .Apparel-casi .chbx-holder.red .checkmark {background-color: #c90642;}
.Apparel-casi .chbx-holder.red:hover input ~ .checkmark {background-color: #c90642;}
/* Green */
.Apparel-casi .chbx-holder.green input:checked ~ .checkmark, .Apparel-casi .chbx-holder.green .checkmark {background-color: #1a8e5d;}
.Apparel-casi .chbx-holder.green:hover input ~ .checkmark {background-color: #1a8e5d;}
/* Maroon */
.Apparel-casi .chbx-holder.maroon input:checked ~ .checkmark, .Apparel-casi .chbx-holder.maroon .checkmark {background-color: #b72971;}
.Apparel-casi .chbx-holder.maroon:hover input ~ .checkmark {background-color: #b72971;}
/* Orange */
.Apparel-casi .chbx-holder.orange input:checked ~ .checkmark, .Apparel-casi .chbx-holder.orange .checkmark {background-color: #be5d1c;}
.Apparel-casi .chbx-holder.orange:hover input ~ .checkmark {background-color: #be5d1c;}
/* Pink */
.Apparel-casi .chbx-holder.pink input:checked ~ .checkmark, .Apparel-casi .chbx-holder.pink .checkmark {background-color: #9c6fb3;}
.Apparel-casi .chbx-holder.pink:hover input ~ .checkmark {background-color: #9c6fb3;}
/* Yellow */
.Apparel-casi .chbx-holder.yellow input:checked ~ .checkmark, .Apparel-casi .chbx-holder.yellow .checkmark {background-color: #d2bb3b;}
.Apparel-casi .chbx-holder.yellow:hover input ~ .checkmark {background-color: #d2bb3b;}
/* Grey Bg*/
.Apparel-casi .chbx-holder.grey-bg input:checked ~ .checkmark, .Apparel-casi .chbx-holder.grey-bg .checkmark {background-color: #d9d9d9;}
.Apparel-casi .chbx-holder.grey-bg:hover input ~ .checkmark {background-color: #c0c0c0;}
/* //Custom checkbox */
/**/
/* Pagination */
.Apparel-casi .pagination {margin-bottom: 0;}
/* pagination colour override */
.Apparel-casi .page-link {
	color: #ce9d37;
}
.Apparel-casi .page-link:hover {
    z-index: 2;
    color: #ce9d37;
    text-decoration: none;
    background-color: #f6f6f6;
    border-color: #dee2e6;
}
.Apparel-casi .page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ce9d37;
    border-color: #ce9d37;
}
/* Accordion */
.Apparel-casi #accordion .card {
	border-radius: 0;
	border-top:0;
	background-color: #f8f8f8;
	margin-bottom: 3px;
}
.Apparel-casi #accordion .card-header {
	background-color: #d9d9d9;
    cursor: pointer;
    padding: 0;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
	border-radius: 0;
}
.Apparel-casi #accordion .card-header a {
	margin: 0;
	padding: 10px 15px 10px;
	font-size: 14px;
    font-weight: 500;
	color: #212529;
	display: block;
}
.Apparel-casi #accordion .card-header:hover {background-color: #c0c0c0;}
.Apparel-casi #accordion .card-header .btn-link:hover  {text-decoration: none;}
.Apparel-casi #accordion .card-header a:after {
	font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f068';
    position: absolute;
    right: 10px;
    top: 10px;
	font-size: 12px;
}
.Apparel-casi #accordion .card-header a.collapsed:after {
	content: '\f067';
}
/* /Accordion */
/* /Common Styles ------------------------------------------------------------------ */
/* --------------------------------------------------------------------------------- */
/* Product Listing Page ------------------------------------------------------------ */
.Apparel-casi .prd-hero-img img {width: 100%;}
.Apparel-casi .prd-hero {position: relative;}
.Apparel-casi .prd-hero .prd-hero-header {
	position: absolute;
	top: 0;
	padding: 10px;
	background-color: rgba(206,157,55,0.7);
	width: 100%;
}
.Apparel-casi .prd-hero .prd-hero-header h4 {
	font-size: 1rem;
	color: #ffffff;
	margin-bottom: 0;
}
.Apparel-casi .prd-filter-area {
	padding-top: 20px;
}
.Apparel-casi .prd-filter-area .filter-inner {
	background-color: #f3f3f3;
	padding: 15px;
	display: flex;
    flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top p {
	margin-bottom: 10px;
	line-height: 20px;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top .cat-name {
	font-size: 16px;
    font-weight: 600;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-top .cat-count {
	font-size: 16px;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom {
	display: flex;
	flex-direction: column;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .filter-colour {margin-right: 20px;	min-height: 70px;}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom p {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
	display: inline-block;
}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .colour-wrapper {display: flex;}
.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .price-ranger {
	margin-left: 10px;
	display: inline-block;
}
/* price slider */
.Apparel-casi .slidecontainer {width: 100%;}
.Apparel-casi .slider {
	-webkit-appearance: none;
	width: 100%;
	height: 5px;
	background: #e7dcc4;
	outline: none;
	opacity: 0.7;
	-webkit-transition: .2s;
	transition: opacity .2s;
	border-radius: 20px;
  }
  .Apparel-casi .slider:hover {opacity: 1; }
  .Apparel-casi .slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	width: 25px;
	height: 25px;
	background: #ce9d37;
	cursor: pointer;
	border-radius: 3px;
  }
  .Apparel-casi .slider::-moz-range-thumb {
	width: 25px;
	height: 25px;
	background: #ce9d37;
	cursor: pointer;
	border-radius: 3px;
  }
/* /price slider */
.Apparel-casi .prd-list-items {padding-top: 20px;}
.Apparel-casi .prd-list-items .item-wrapper {
	padding: 15px;
	background-color: #ffffff;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	border-radius: 6px;
	margin-bottom: 20px;
	border: 2px solid #ffffff;
}
.Apparel-casi .prd-list-items .item-wrapper:hover {border-color: #ce9d37;}
.Apparel-casi .prd-list-items .item-wrapper a {text-decoration: none;}
.Apparel-casi .prd-list-items .img-container {
	position: relative;
	margin-bottom: 10px;
}
.Apparel-casi .prd-list-items .img-container img {border-radius: 4px;}
.Apparel-casi .prd-list-items .img-container .item-price {
	border-radius: 4px;
	background-color: #ffffff;
	color:#ce9d37;
	padding: 5px 10px;
	position: absolute;
	bottom: 10px;
	right: 10px;
	font-size: 16px;
}
.Apparel-casi .prd-list-items .item-wrapper .item-text {min-height: 40px;}
.Apparel-casi .prd-list-items .item-wrapper p {
	font-size: 14px;
	margin-bottom: 0;
	color:#ce9d37;
	text-align: center;
}
.Apparel-casi .prd-pagination {
	padding-top: 20px;
	display: flex;
	justify-content: right;
}
/* Product Details Page ------------------------------------------------------------ */
.Apparel-casi .product-details {padding-top:20px;}
.Apparel-casi .product-details .prd-details-img-wrapper {
	display: flex;
	flex-direction: column-reverse;
	margin-bottom: 10px;
}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn {display: flex;margin-top: 5px;}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span {margin-right: 5px;}
.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span img {max-width: 50px;}
.Apparel-casi .product-details .prd-details-text p.prd-title {font-size: 20px;font-weight:600;margin-bottom: 5px;}
.Apparel-casi .product-details .prd-details-text .prd-price {font-size: 20px; font-weight: 500;}
.Apparel-casi .product-details .prd-details-text .prd-more-details {margin-top:10px;}
.Apparel-casi .product-details .prd-details-text .prd-more-details p {margin-bottom: 5px; font-size: 14px;}
.Apparel-casi .product-details .prd-details-text .prd-count {margin-top: 15px;}
.Apparel-casi .product-details .prd-details-text .prd-count .label-qty {
	display: block;
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 5px;
}
/* Product Quantity */
.Apparel-casi .prd-count-inner {
	display: flex;
}
.Apparel-casi .prd-count-inner button {
	font-size: 12px;
	color: #212529;
	padding: 5px 10px;
    background-color: #d9d9d9;
    border: 0;
    width: 40px;
    height: 30px;
}
.Apparel-casi .prd-count-inner button:focus {outline: none;}
.Apparel-casi .prd-count-inner .qty-no input {
	width: 40px;
    height: 30px;
	border: 1px solid #d9d9d9;
	background-color: #ffffff;
	font-size: 14px;
	text-align: center;
	font-weight: 500;
}
/* remove scroll in number input*/
.Apparel-casi .qty-no input[type=number]::-webkit-inner-spin-button, 
.Apparel-casi .qty-no input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.Apparel-casi .product-details .prd-details-text .prd-buttons {margin-top: 25px;display: flex;}
.Apparel-casi .product-details .commercial-details {margin-top: 25px;}
/* Shopping Cart Page -------------------------------------------------------------- */
.Apparel-casi .cart-details {padding-top: 20px;}
.Apparel-casi .cart-details .header-wrapper {display:none;}
.Apparel-casi .cart-details h4 { text-align: center; margin-bottom: 20px;}
.Apparel-casi .cart-details .show-cart-count {display: block; margin-bottom: 10px;}
.Apparel-casi .cart-list .cart-count-number {padding: 5px 0;border-top: 1px solid #eee;}
.Apparel-casi .cart-list .cart-count-number span {font-size: 12px; font-weight: 500;}
.Apparel-casi .cart-list .cart-items {
	border-top: 1px solid #eee; 
	/* border-bottom: 1px solid #eee; */
	padding: 10px 0;
}
.Apparel-casi .cart-list .cart-product-name {display: flex;margin-bottom: 10px;}
.Apparel-casi .cart-list .cart-product-name .img-wrap img {
	margin-right: 10px; 
	display: block; 
	max-width: 60px;
}
.Apparel-casi .cart-list .product-name-wrap a.product-name {
    font-size: 14px;
    font-weight: 600;
    display: block;
    color: #212529;
    text-decoration: none;
	line-height: 1;
	margin-bottom: 10px;
}
.Apparel-casi .cart-list .product-name-wrap .product-more-details p {
    margin-bottom: 10px;
    font-size: 12px;
}
.Apparel-casi .cart-list .product-name-wrap .cart-remove-item a {
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    color: #212529;
    display: block;
	line-height: 1;
}
.Apparel-casi .show-discount-text {
	margin-top: 10px;
    font-size: 12px;
    color: #ffa500;
    font-weight: 600;
}
/**/
.Apparel-casi .cart-list .cart-product-details .cart-product-price {
	margin-bottom: 10px;
	display: flex;
    justify-content: flex-end;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-price .label-price {
	margin-right: 10px;
    color: #b0b0b0;
	font-size: 12px;
    text-transform: uppercase;
	line-height: 1;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-price .price-wrap {
	font-weight: 600;
    font-size: 14px;
	line-height: 1;
}
/**/
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity {
	display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-qty {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-qty .label-qty {
	margin-right: 10px;
    color: #b0b0b0;
    font-size: 12px;
    text-transform: uppercase;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo {
	display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .label-promo-code {
	margin-right: 10px;
    color: #b0b0b0;
    font-size: 12px;
    text-transform: uppercase;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper {
	display: flex;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper .promo-code input {
	height: 30px;
    max-width: 100px;
    border: 1px solid #d9d9d9;
    background-color: #ffffff;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    letter-spacing: 3px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .promo-wrapper .promo-updated button {
	font-size: 14px;
    color: #212529;
    padding: 5px 10px;
    background-color: #d9d9d9;
    border: 0;
	height: 30px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total {
	display: flex;
    justify-content: flex-end;
	align-items: center;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total .label-total {
	font-size: 12px;
    text-transform: uppercase;
    color: #b0b0b0;
    margin-right: 10px;
}
.Apparel-casi .cart-list .cart-product-details .cart-product-total .total-wrap {
	display:flex;
	justify-content: flex-end;
	font-size: 14px;
	font-weight: 600;
}
/* remove button/input border on Focus */
.Apparel-casi .qty-no input:focus, 
.Apparel-casi .promo-code input:focus,
.Apparel-casi .promo-updated button:focus {outline: none;}
/* delivery option selection */
.Apparel-casi .cart-details .top-total-hr {
	border-color: #eee;
	margin-top: 0;
	margin-bottom: 0;
}
.Apparel-casi .cart-details .align-option {
	display: flex;
    justify-content: flex-end;
	align-items: center;
}
.Apparel-casi .cart-details .del-label {padding: 15px 10px;}


.Apparel-casi .cart-details .cart-sub-total .st-item {
	min-width: 165px;
    padding: 10px;
    border: 1px solid #eee;
}
.Apparel-casi .mr10 {margin-right: 10px;}
.Apparel-casi .mr20 {margin-right: 20px;}

.Apparel-casi .cart-details .st-item {font-weight: 600;}
.Apparel-casi .cart-details .total-to-pay .st-item {font-size: 16px;}

.Apparel-casi .cart-details .cart-button-wrapper {
	margin-top: 30px;
	display:flex;
	flex-direction: column;
}
.Apparel-casi .cart-details .cart-button-wrapper a:first-child {margin-bottom: 15px;}
/* Login Page ---------------------------------------------------------------------- */
.Apparel-casi .login {padding-top: 20px;}
.Apparel-casi .login .login-tabs {
	border: 1px solid #eee;
	padding: 20px;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	margin-bottom: 20px;
}
.Apparel-casi .login .nav-tabs {border-bottom: 0;margin-bottom: 20px;}
.Apparel-casi .login .nav-tabs .nav-item.show .nav-link, 
.Apparel-casi .login .nav-tabs .nav-link.active {border: 0;}
.Apparel-casi .login .login-tabs .nav-item {width: 50%;}
.Apparel-casi .login .btn-login {
	width: 100%;
    display: block;
	margin-top: 30px;
}
.Apparel-casi .login .forgot-password {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 15px;
}
.Apparel-casi .login label {margin-bottom: 5px;font-size: 12px;}
.Apparel-casi .login .forgot-password a {font-size: 12px;color: #212529;}
.Apparel-casi .login .nav-tabs .nav-link {border: 0;}
.Apparel-casi .login .nav-tabs .nav-item a.nav-link {
	text-align: center;
	text-transform: uppercase;
	color: #212529;
	font-size: 20px;
	font-weight: 600;
}
.Apparel-casi .login .nav-tabs .nav-item .nav-link.active { 
	border-bottom: 3px solid #ce9d37;
	color: #ce9d37;
}
.Apparel-casi .login .link-privacy-policy {
	text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    color: #212529;
}
.Apparel-casi .login .signup-benefits p {margin-bottom: 20px;}
.Apparel-casi .login .signup-benefits .text-header {font-size: 18px; font-weight: 600;}
.Apparel-casi .login .signup-benefits .signup-item {display: flex;margin-bottom: 20px;}
.Apparel-casi .login .signup-benefits .signup-item i {font-size: 22px;margin-right: 5px;min-width: 30px;color:#ce9d37;}
.Apparel-casi .login .signup-benefits .signup-item p {font-size: 14px;margin-bottom: 0;text-transform: uppercase;}
/* Cart Top ------------------------------------------------------------------------ */
.Apparel-casi .cart-top {
	width: 300px;
	padding: 10px;
}
.Apparel-casi .cart-top .cart-top-item {
	display: flex;
	display: flex;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8e8e8;
}
.Apparel-casi .cart-top .cart-top-product-img {margin-right: 5px;}
.Apparel-casi .cart-top .cart-top-product-img img {width: 50px;}
.Apparel-casi .cart-top .cart-top-prdocut-details a {
	padding: 0 !important;
}
.Apparel-casi .cart-top .cart-top-prdocut-qty-price span {
	color: #8d6717;
	font-size: 14px;
}
.Apparel-casi .cart-top .sub-total {
	display: flex;
	justify-content: space-between;
}
.Apparel-casi .cart-top .total-topay {
	display: flex;
	justify-content: space-between;
}
.Apparel-casi .cart-top .cart-top-total .sub-total {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 10px;
}
.Apparel-casi .cart-top .cart-top-total .total-topay {
	font-size: 16px;
	font-weight: 600;
}
.Apparel-casi .cart-top .cart-top-buttons {
	margin-top: 15px;
}
.Apparel-casi .cart-top .cart-top-buttons a {
	display: flex;
	justify-content: center;
}
.Apparel-casi .cart-top .cart-top-buttons a {color: #ffffff !important;}
.Apparel-casi .cart-top .cart-top-buttons a:hover {background-color: #ce9d37 !important;}
/* Register Verify ----------------------------------------------------------------- */
.Apparel-casi .register-verify {padding-top: 20px;}
.Apparel-casi .register-verify .list-group {margin-bottom: 20px;}
.Apparel-casi .register-verify .list-group-item {
	display: flex;
    align-items: center;
}
.Apparel-casi .register-verify .list-group-item i {
	font-size: 22px;
    margin-right: 5px;
    min-width: 30px;
    color: #ce9d37;
}
.Apparel-casi .register-verify .list-group-item a {
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	color: #212529;
}
.Apparel-casi .register-verify .register-text .text-header {
	font-size: 18px;
    font-weight: 600;
	margin-bottom: 20px;
}
.Apparel-casi .register-verify .btn-verify {
	width: 100%;
    display: block;
    margin-top: 30px;
}
/* Create Account ------------------------------------------------------------------ */
.Apparel-casi .create-account {padding-top: 20px;}
.Apparel-casi .create-account h4 {
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .create-account h6 {
    margin-bottom: 15px;
}
.Apparel-casi .create-account .btn-comp-reg {
	width: 100%;
    display: block;
	margin: auto;
}
/* Page - Read Our Story ----------------------------------------------------------- */
/* .our-story {padding-top: 20px;} */
.Apparel-casi .our-story h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Page - Bridal Service ----------------------------------------------------------- */
/* .our-story {padding-top: 20px;} */
.Apparel-casi .bridal-service h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Page - Weaving Process ---------------------------------------------------------- */
.Apparel-casi .weaving-process-in h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .mb20 {margin-bottom: 20px;}
/* Page - Testimonials ------------------------------------------------------------- */
.Apparel-casi .testimonials-in h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .testimonials-in .testimonials-item {background-color: #ffffff;margin-bottom: 20px;}
.Apparel-casi .testimonials-in .testimonials-item-inner {
	border:1px solid #e8e8e8;
	border-radius: 8px;
	padding: 10px;
	display: flex;
	flex-direction: column;
}
.Apparel-casi .testimonials-in .testimonials-user {
	display: flex;
}
.Apparel-casi .testimonials-in .testimonials-item img  {
	border-radius: 50%;
	width: 50px;
}
.Apparel-casi .testimonials-in .user-name {font-weight: 600;font-size: 14px;}
.Apparel-casi .testimonials-in .user-rating { padding: 0 10px;}
.Apparel-casi .testimonials-in .user-rating .rating-starts {
 color: #FDCC0D;
 font-size: 10px;
}
.Apparel-casi .testimonials-in .testimonials-text {
	padding: 10px 0;
	margin-bottom: 0;
	color: #818181;
	margin-top: 10px;
	border-top: 1px solid #e8e8e8;
}
.Apparel-casi .testimonials-in .btn-view {
	display: flex;
    justify-content: center;
}
/* Page - Blogs ------------------------------------------------------------- */
.Apparel-casi .blogs h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .blogs .blog-tabs {
	border: 1px solid #eee;
	padding: 20px;
	box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
	margin-bottom: 20px;
}
.Apparel-casi .blogs .nav-tabs {border-bottom: 0;margin-bottom: 20px;}
.Apparel-casi .blogs .nav-tabs .nav-item.show .nav-link, 
.Apparel-casi .blogs .nav-tabs .nav-link.active {border: 0;}
.Apparel-casi .blogs .nav-tabs .nav-link {border: 0;}
.Apparel-casi .blogs .nav-tabs .nav-item a.nav-link {
	text-align: center;
	text-transform: uppercase;
	color: #212529;
	font-size: 16px;
	font-weight: 600;
}
.Apparel-casi .blogs .nav-tabs .nav-item .nav-link.active { 
	border-bottom: 3px solid #ce9d37;
	color: #ce9d37;
}

.Apparel-casi .blogs .blog-items {
	display: flex;
	flex-direction: column;
	padding-top: 15px;
	margin-top: 15px;
	border-top: 1px solid #e8e8e8;
}
.Apparel-casi .blogs .blog-items:first-child {border-top: 0;padding-top: 0;}
.Apparel-casi .blogs .blog-items .blog-items-header {margin-bottom: 10px;}
.Apparel-casi .blogs .blog-items .blog-items-header a {
	font-size: 16px;
	font-weight: 500;
	color: #212529;
	text-decoration: none;
}

.Apparel-casi .blogs .blog-items .blog-items-details {margin-top: 10px;}
.Apparel-casi .blogs .blog-items img {width: 275px;}
/* Page - Blogs ------------------------------------------------------------- */
.Apparel-casi .quality-faq h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* Contact Page ------------------------------------------------------------- */
.Apparel-casi .contact h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
.Apparel-casi .contact .btn-submit-wrapper {
	display: flex;
    justify-content: center;
}
.Apparel-casi .contact .btn-submit-wrapper a {
	/* width: 300px; */
	width: 100%;
}
/* Gallery Page ------------------------------------------------------------- */
.Apparel-casi .gallery h4 {
	padding-top: 20px;
	text-align: center;
    margin-bottom: 20px
}
/* ============================================================================================================= */
/* ================================================================================= */
/* Media Queries =================================================================== */
/* ================================================================================= */
@media (min-width: 576px) {
	.Apparel-casi .our-collections-mobile .item-text p {
		min-height: 50px;
		max-height: 50px;
	}
	/* filters */
	.Apparel-casi .prd-filter-area .filter-inner .filter-top {
		flex-direction: row;
		justify-content: space-between;
	}
	.Apparel-casi .prd-filter-area .filter-inner .filter-bottom {flex-direction: row;}
	.Apparel-casi .prd-filter-area .filter-inner .filter-bottom .filter-colour {min-height: auto;}
	.Apparel-casi .slider {width: 210px;}
	/* Cart */
	.Apparel-casi .cart-items {
		display: flex;
		justify-content: space-between;
	}
	.Apparel-casi .cart-details .cart-button-wrapper {align-items: flex-end;}
	.Apparel-casi .cart-details .cart-button-wrapper a {min-width: 300px;}
	/* Contact Submit Button */
	.Apparel-casi .contact .btn-submit-wrapper a {
		width: 300px;
	}
}
/* ================================================================================= */
@media (min-width: 768px) {
	/* other styles */
	/* ------------ */
	.Apparel-casi .logo img {height: 100px;}
	.Apparel-casi .navbar button.navbar-toggler {top: 40px; left: 80px;}
	.Apparel-casi .btn-search {display:none;}
	.Apparel-casi .search-control input {width: 300px;}
	.Apparel-casi .search-control {display:block;}
	.Apparel-casi .search-control {
		z-index: unset;
		position: unset;
		left: unset;
		top: unset;
		height: unset;
		width: unset;
		padding: 0 10px;
	}
	.Apparel-casi .appointment h4 {font-size: 24px;}
	/* product listing page */
	.Apparel-casi .prd-hero .prd-hero-header {padding: 15px;	}
	.Apparel-casi .prd-hero .prd-hero-header h4 {font-size: 1.5rem;}
	/* Cart */
	.Apparel-casi .cart-details .show-cart-count, .cart-list .cart-count-number {display: none;}
	.Apparel-casi .label-price, .label-qty, .label-total {display: none;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo {
		flex-direction: column;
    	align-items: baseline;
		margin-bottom: 0;
	}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity .line-promo .label-promo-code {
		margin-bottom: 3px;
	}
	.Apparel-casi .cart-details .header-wrapper {
		display:flex;
		width: 100%;
		height: auto;
		margin-bottom: 5px;
	}
	.Apparel-casi .cart-list .cart-product-name {margin-bottom: 0;}
	.Apparel-casi .cart-header-left {width: 40%;}
	.Apparel-casi .cart-header-right {width: 60%;display: flex;}
	.Apparel-casi .cart-header-right .div1 {width: 40%; text-align: left;}
	.Apparel-casi .cart-header-right .div2 {width: 25%; text-align: right;}
	.Apparel-casi .cart-header-right .div3 {width: 50%; text-align: right;}
	.Apparel-casi .cart-header-right .div4 {width: 25%; text-align: right;}
	.Apparel-casi .cart-header-left span, .cart-header-right span {
		font-size: 14px;
    	font-weight: 600;
	}
	.Apparel-casi .cart-product-details {width: 60%;display: flex;}
	.Apparel-casi .cart-product-name {width: 40%;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-price {width: 25%;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-quantity {width: 50%;flex-direction: column;}
	.Apparel-casi .cart-list .cart-product-details .cart-product-total {width: 25%;align-items: baseline;}
	/* Create Account */
	.Apparel-casi .create-account {padding: 40px;}
	.Apparel-casi .create-account p {padding: 30px 0; margin-bottom: 0;}
	.Apparel-casi .create-account .btn-comp-reg {width: 400px;}
	/* Blog */
	.Apparel-casi .blogs .blog-items-inner-wrapper {display: flex;}
	.Apparel-casi .blogs .blog-items img {width: 275px;}
	.Apparel-casi .blogs .blog-items .blog-items-details {margin-top: 0;margin-left: 15px;}
	.Apparel-casi .blogs .blog-items .blog-items-header a {font-size: 20px;}
	/* Contact */
	.Apparel-casi .contact {max-width: 900px;margin: auto;}

}
/* ================================================================================= */
@media (min-width: 992px) {
	/* Our Collections Mobile ------------------------------------------------------ */
	.Apparel-casi .our-collections-mobile {display: none !important;}
	/* Our Collections Large ------------------------------------------------------- */
	.Apparel-casi .our-collections {display: block !important;margin-top: 20px;}
	/* other styles */
	/* ------------ */
	.Apparel-casi .logo img {height: 160px;}
	.Apparel-casi .bg-round {width: 40px;height: 40px;	}
	.Apparel-casi .header-right ul a .favourite-items i, 
	.Apparel-casi .header-right ul a .shopping-cart-items i, 
	.Apparel-casi .header-right ul a .user-icon i {
		font-size: 24px;
	}
	.Apparel-casi .count {
		bottom: -5px;
		font-size: 12px;
		width: 20px;
    	height: 20px;
    	line-height: 20px;
	}
	.Apparel-casi .welcome-message, .our-collections, .promotions, .appointment, .testimonials
	{margin-top: 40px;}
	.Apparel-casi .our-collections h4, .promotions h4, .testimonials h4
	{margin-bottom: 20px; font-size: 32px;}
	.Apparel-casi .welcome-message h4 {font-size: 32px; text-align: center;
		margin: 0;
		padding: 15px;}
	.Apparel-casi .welcome-message .message-wrapper p {padding: 0 60px 20px;}
	.Apparel-casi .search-control input {width: 450px;}
    .Apparel-casi .profile-name {
		display:block;
		color: #ce9d37;
		font-weight: 500;
	}
	
	/* new navigation styles */
	.Apparel-casi .navbar {
		padding-top: 8px;
		padding-bottom: 8px;
	}
	.Apparel-casi .navbar ul li a:hover {background-color: #ffffff;}

	/* Testimonials */
	.Apparel-casi .testimonials .testimonials-item img  {width: 75px;}
	.Apparel-casi .testimonials .user-name {font-size: 16px;}
	.Apparel-casi .testimonials p {font-size: 14px;}
	/* product listing page */
	.Apparel-casi .prd-list-items {padding: 40px 40px 0;}
	.Apparel-casi .prd-filter-area {padding: 20px 40px 0;}
	.Apparel-casi .prd-hero .prd-hero-header {padding: 20px;	}
	.Apparel-casi .prd-hero .prd-hero-header h4 {font-size: 2.5rem;}
	.Apparel-casi .prd-pagination {padding: 20px 40px 0;}
	/* products details */
	.breadcrumb-container {padding: 40px 40px 0;}
	.Apparel-casi .product-details {padding: 20px 40px 0;}
	.Apparel-casi .product-details .prd-details-img-wrapper {flex-direction: row;justify-content: flex-end;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn {flex-direction: column;margin-top: 0;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span {margin-bottom: 5px;}
	.Apparel-casi .product-details .prd-details-img-wrapper .prd-img-tbn span img {max-width: 75px;}
	.Apparel-casi .product-details .commercial-details {margin-top: 50px;}
	/* Cart */
	.Apparel-casi .cart-details {padding: 40px 50px 0;}
	/* Login Page */
	.Apparel-casi .login {padding: 80px 40px 80px;}
	.Apparel-casi .login .login-tabs-wrapper {display: flex;justify-content: flex-end;}
	.Apparel-casi .login .login-tabs {width: 400px;}
	.Apparel-casi .login .signup-benefits {width: 400px;margin-left: 20px;}
	/* Register Verify */
	.Apparel-casi .register-verify {padding: 80px 40px 80px;}
	.Apparel-casi .register-verify .register-details-wrapper {display: flex;justify-content: flex-end;}
	.Apparel-casi .register-verify .register-details-wrapper .list-group {width: 400px;}
	.Apparel-casi .register-verify .register-text {width: 400px;margin-left: 20px;}
	/* Create Account */
	.Apparel-casi .create-account {padding: 40px 200px 0;}
	/* Inner Pages */
	.Apparel-casi .page-inner {padding: 40px 50px 0;}
}
/* ================================================================================= */
@media (min-width: 1200px) {
	.Apparel-casi .search-control input {width: 600px !important;}
	/* Cart */
	.Apparel-casi .cart-details {padding: 40px 100px 0;}
}
/* ================================================================================= */


