@charset "UTF-8";

.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
}

.Witmeg header {
  background-color: rgb(0, 54, 54)!important;
  background: -webkit-linear-gradient(
152.76deg
,teal 0%,rgb(0, 255, 255) 100%)!important;
  background: linear-gradient(
297.24deg
,teal 0%,rgb(0, 255, 255) 100%)!important;
  padding: 90px;
  text-align: center;
  font-size: 35px;
  color: white;
}
.Witmeg header h2{
  font-weight: 800;
}

.Witmeg .footer {
  background-color: #1a2727!important;
  color: #e6e6e6;
  padding-top: 48px;
}
.Witmeg .CustomCard .ant-card-grid{
 margin-bottom: 25px; 
 visibility: visible;
 opacity: 1;
 transform: translate3d(0%, 0%, 0px);
 animation-delay: unset !important;
 transition-delay: unset !important;
 transition-duration: unset !important;
 animation-duration: unset !important;
 transform: scale(1.1); 
 transition-property: none !important;
 box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}
.Witmeg .CustomCard .ant-card-grid:hover{
  margin-bottom: 25px; 
   transform: scale(1.1); 
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }
.Witmeg .CustomCard  {
    border: 0px solid #f0f0f0 !important;
 }
 .Witmeg .ant-tag {
  font-size: 12px !important;
 }
 .Witmeg .CustomCard{
  margin-bottom: 18px !important;
 }
 .Witmeg .CustomCard img{
    padding-right: 15px;
    transition: transform .5s ease;
 }
 .Witmeg .CustomCard img:hover {
  transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
 .Witmeg .ant-card-meta::after {
   display: block;
   clear: none;
 }

 