.Modal-Casi {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}
.Modal-Casi-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 50px 20px;
  width: 28%;
  .modal-header-text {
    font-family: Lato;
    font-weight: 600;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    color: #121212;
  }
  .modal-sub-text {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0px;
    text-align: center;
    color: #9d9d9d;
    width: 50%;
    margin: auto;
    margin-top: 12px;
  }
  .modal-btn-cont {
    margin-top: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modal-img-container {
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 32px;
    img {
      width: 100px;
      height: 100px;
    }
  }
}
