.Hospitality-Footer {
  width: 100%;
  background-color: #181818;
  padding: 50px 0px;
  .Hospitality-Footer-Container {
    width: 80%;
    margin: auto;
    display: flex;
    gap: 40px;
    .copy-text {
      font-family: Lato;
      color: #999;
      font-size: 14px;
      margin-top: 20px;
    }
    .Hospitality-Footer-content {
      width: calc(25% - 30px);

      .logo-container {
        height: 70px;
        margin-bottom: 16px;
      }
      .footer-text {
        font-family: Lato;
        color: #999;
        font-size: 14px;
        margin-bottom: 16px;
      }
      .footer-text-2 {
        font-family: Lato;
        color: #b5a46d;
        font-size: 14px;
        margin-bottom: 16px;
      }
      .footer-text-header {
        color: #fff;
        font-weight: 600;
        font-family: Lato;
        margin-bottom: 20px;
        font-size: 15px;
      }
      .opening-hours-text {
        font-family: Lato;
        color: #999;
        font-size: 14px;
        margin-bottom: 12px;
        display: flex;
        width: 100%;
        align-items: flex-end;
        .dotted-line {
          flex-grow: 1;
          border-bottom: 1px dotted #9999995b;
          margin: 4px 8px;
        }
      }
      .social {
        font-family: Lato;
        color: #999;
        font-size: 14px;
        margin-bottom: 12px;
        display: flex;
        width: 100%;
        align-items: center;
        column-gap: 15px;
        .social-icon {
          height: 22px;
          width: 22px;
          filter: brightness(0) saturate(100%) invert(64%) sepia(4%)
            saturate(4%) hue-rotate(1deg) brightness(94%) contrast(93%);
        }
        a {
          color: #b5a46d !important;
          text-decoration: none !important;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Hospitality-Footer {
    .Hospitality-Footer-Container {
      width: 90%;
      display: block;
      .Hospitality-Footer-content {
        width: 100%;
        .footer-text-header {
          margin-top: 40px;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Hospitality-Footer {
    .Hospitality-Footer-Container {
      width: 90%;
      display: block;
      .Hospitality-Footer-content {
        width: 100%;
        .footer-text-header {
          margin-top: 40px;
        }
      }
    }
  }
}
