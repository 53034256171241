.EcommerceHome {
  height: 100%;
  width: 100%;
  .EcommerceHome-hearo-video {
    height: 70vh;
    width: 100%;
    margin: auto;
    border-radius: 20px;
  }
  .EcommerceHome-container {
    width: 80%;
    margin: auto;
  }
  .EcommerceHome-hero-details-contact {
    width: 80%;
    margin: auto;
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
}
@media only screen and (max-width: 600px) {
  .EcommerceHome {
    .EcommerceHome-hearo-video {
      width: 90%;
      height: 30vh;
    }
    .EcommerceHome-container {
      width: 90%;
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .EcommerceHome {
    .EcommerceHome-hearo-video {
      width: 90%;
      height: 30vh;
    }
    .EcommerceHome-container {
      width: 90%;
    }
  }
}
