.fixed-header {
    background-color: #fff;
    padding: 8px 0px;
    color: #001529;
    width: 100%;
  }
  
  .logo {
    font-size: 24px;
    font-weight: bold;
  }
  
  .menu-toggle {
    text-align: right;
  }
  
  .menu-toggle button {
    color: #001529;
    font-size: 18px;
  }
  
  .search-bar,
  .sign-in-menu {
    text-align: right;
  }
  .sign-in-menu a {
    color: #000;
    font-weight: bold;
  }

  .header-second-row {
    background-color: #e9c259;
    padding: 8px 12px;
    margin-top: 8px;
  }
  
  /* Media Query for Small Screens (xs) */
  @media (max-width: 575px) {
    .menu-toggle, .sign-in-menu {
      text-align: right;
    }
  
    /* .search-bar,
    .sign-in-menu {
      display: none;
    }
   */
    .menu-toggle button {
      font-size: 24px;
    }
  
    .fixed-header .ant-menu-item {
      font-size: 14px;
    }
  }