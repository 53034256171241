.Ecommerceregister {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  .Ecommerceregister-container {
    width: 30%;
    margin: auto;
    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .logo {
        height: 80px;
      }
    }
    .register-form {
      margin-top: 40px;
      .register-sub-text {
        font-family: Jost;
        letter-spacing: -0.2px;
        color: #4b4b4b;
        margin-bottom: 30px;
        margin-top: 10px;
      }
      .input-container {
        margin-bottom: 15px;
      }
      .register-btn-container {
        margin-top: 40px;
      }
      .sign-up-content {
        margin-top: 30px;
        font-family: Inter;
        font-size: 14px;
        text-align: left;
        color: #6f767e;
        text-align: justify;
        span {
          color: #000;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }
  .EcommerceAccount-container {
    width: 40%;
    margin: auto;
    padding-top: 5%;
    padding-bottom: 5%;
    .logo-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .logo {
        height: 80px;
      }
    }
    .register-form {
      margin-top: 40px;
      .register-sub-text {
        font-family: Jost;
        letter-spacing: -0.2px;
        color: #4b4b4b;
        margin-bottom: 30px;
      }
      .input-container {
        margin-bottom: 15px;
      }
      .register-btn-container {
        margin-top: 40px;
      }
      .input-grid-container {
        display: grid;
        grid-template-columns: calc(50% - 10px) 50%;
        column-gap: 10px;
        height: 100%;
        margin-bottom: 20px;
      }
      .seperator {
        font-family: Jost;
        font-size: 18px;
        color: #5e5e5e;
        margin-bottom: 20px;
        margin-top: 30px;
      }
      .sign-up-content {
        margin-top: 30px;
        font-family: Inter;
        font-size: 14px;
        text-align: left;
        color: #6f767e;
        text-align: justify;
        span {
          color: #000;
          cursor: pointer;
          font-weight: 500;
        }
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Ecommerceregister {
    .Ecommerceregister-container {
      width: 90%;
    }
    .EcommerceAccount-container {
      width: 90%;
      .register-form {
        .input-grid-container {
          grid-template-columns: 100%;
          gap: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .Ecommerceregister {
    .Ecommerceregister-container {
      width: 60%;
    }
    .EcommerceAccount-container {
      width: 80%;
    }
  }
}
