.eco-product-list .item {
  padding: 15px 10px;
  border: 1px solid #f2f0f5;
  border-radius: 4px;
}
.input-order-amount,
.input-order-amount:focus-visible {
  border: none !important;
  width: 50px;
  height: 30px;
  outline: none !important;
  text-align: center;
}
.item-popup .ant-modal-header {
  border-bottom: none;
}
.eco-order-summary-row {
  padding: 20px 10px;
  margin-top: 28px;
}
.eco-order-summary-total-row {
  border-top: 1px solid #e9e9e9;
  padding: 20px 10px;
  margin-bottom: 24px;
}
.eco-order-summary .ant-popover-inner {
  padding: 0;
}
.eco-order-summary .ant-popover-inner .ant-popover-title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  color: #333333;
  box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.12);
  /* padding: 30px 20px 20px; */
  background: transparent;
  padding: 25px 20px;
}
.eco-order-summary .ant-popover-title,
.ant-popover-inner-content {
  padding: 20px;
}
.eco-order-summary
  .ant-popover-content
  .ant-popover-inner
  .ant-popover-inner-content {
  padding-top: 40px;
}
.eco-order-summary-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4d4d4d;
}

.eco-order-summary-total {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0c0c23;
}

.eco-order-summary-total-label {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  align-items: center;

  color: #4d4d4d;
}
.eco-order-summary-total-bg {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 10px 6px;
}

.order-confirmation-page {
  padding: 30px 20px;
  overflow-y: auto;
  height: 100%;
  max-width: 1300px;
  margin: auto;
  padding: 30px 20px;
}
.order-confirmation-page .desktop-view {
  border: 1px solid #c6c7d2;
  border-radius: 8px;
}
.order-confirmation-page .mobile-view {
  display: none;
}
.order-success-form .eco-button.eco-button--primary {
  padding: 10px 0;
  background: #363b41;
  border-radius: 8px;
  height: auto;
}
.order-success-form .eco-button.eco-button--primary:hover,
.order-success-form .eco-button.eco-button--primary:focus {
  background: #000;
}
.order-success-form .eco-button.eco-button--primary .eco-button__text {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
}

.order-confirmation-page .selected-switch {
  /* background: #71A601; */
  padding: 6px 9px;
  cursor: pointer;
}
.order-confirmation-page .toggle-switch {
  /* background: #F6FFE4; */
  cursor: pointer;
}
.order-confirmation-page .toggle-switch-container svg {
  display: none;
}
.order-confirmation-page .toggle-switch .eco-icon--gray {
  fill: #5c6f14;
}
.order-confirmation-page .toggle-switch-container {
  /* background: #F6FFE4;
    border: 1px solid #71A601; */
}
.order-confirmation-page .eco-confirm-promo-val {
  font-family: "Inter";
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 19px;
  color: #039be5;
}

.order-confirmation-page
  .eco-input-text-container
  .eco-input-text.eco-input-text--background-only
  input[type="text"]:not(:disabled),
.order-confirmation-page .eco-select .eco-select__control,
.order-confirmation-page
  .eco-input-text
  .eco-input-text__wrapper
  input[type="text"] {
  background: #ffffff !important;
  border: 1px solid #d9dadb !important;
  border-radius: 5px;
}

.order-confirmation-page
  .eco-input-text-container
  .eco-input-text.eco-input-text--error
  input[type="text"]:not(:disabled) {
  background-color: #faebeb !important;
  border-color: #c33 !important;
}

.order-confirmation-page
  .eco-select
  .eco-select__control.eco-select__control--menu-is-open,
.order-confirmation-page
  .eco-input-text-container
  .eco-input-text
  .eco-input-text__wrapper
  input[type="text"]:focus,
.order-confirmation-page
  .eco-input-text
  .eco-input-text__wrapper
  input[type="number"]:focus {
  /* border-color: #71A601 !important; */
  box-shadow: 0 0 0 2px #b6e6fe !important;
  outline: none;
}

/* .order-confirmation-page .ant-radio-checked:after, .order-confirmation-page .ant-radio-checked .ant-radio-inner,
.order-confirmation-page .ant-radio-input:focus+.ant-radio-inner {
    border: 1px solid #71A601;
}
.order-confirmation-page .ant-radio-inner:after {
    background-color: #71A601;
} */

.order-confirmation-page .eco-icon-add {
  display: flex;
  align-items: center;
}

.order-confirmation-page .eco-icon-add svg {
  stroke: #4db35e;
  stroke-width: 1px;
  height: 14px;
  width: 14px;
}

.eco-select__indicator svg {
  fill: #939699;
}

.custom-btn {
  width: 150px;
}
.toggle-switch-container {
  background: #e5e7ed;
  border: 1px solid #c6c7d2;
  border-radius: 100px;
  width: 155px;
}
.toggle-switch {
  border-radius: 100px;
  transition: background-color 0.4s ease;
  padding: 6px 7px;
  background: #e5e7ed;
}
.selected-switch {
  border-radius: 100px;
  padding: 10px 15px;
  background: #039be5;
  color: #fff;
}
.eco-order-summary-table {
  width: 100%;
}
.eco-order-summary-table th,
td {
  padding: 20px 15px;
  border-bottom: 1px solid #e9e9e9;
}
.eco-order-summary-table th {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
}
.eco-order-summary-table th:first-child,
.eco-order-summary-table td:first-child {
  padding: 20px 5px;
  text-align: center;
}
.close-icon {
  fill: #d5d5d5 !important;
  cursor: pointer;
}
.card-payment-desc {
  line-height: 2rem !important;
}
.cvv-code .eco-input-text {
  margin: 0 !important;
}
.order-success-icon {
  fill: #99cc33 !important;
}
.order-danger-icon {
  fill: #cc3333 !important;
}
.eco-product-list {
  max-height: 40vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 5px;
}

.eco-order-summary .eco-product-list .eco-product-addon-row {
  position: relative;
  width: 78%;
}
.eco-order-summary .eco-product-list .eco-product-addon-content {
  position: absolute;
  bottom: -5px;
  width: 100%;
}
.eco-order-summary .eco-product-list .eco-product-title {
  font-family: "Inter";
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
  margin-bottom: 20px;
  max-width: 300px;
}
.eco-order-summary .eco-product-list .eco-product-addon {
  /* width: 150px; */
  gap: 4px !important;
}
.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-mins {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 1px 3px;
  cursor: pointer;
}

.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-mins
  svg {
  margin-top: -3px;
  stroke: #333;
}
.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-count {
  padding: 0 2px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
  color: #333333;
}
.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-count
  .input-order-amount {
  width: 30px;
}
.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-plus {
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 1px 3px;
  cursor: pointer;
}
.eco-order-summary
  .eco-product-list
  .eco-product-addon
  .eco-product-addon-plus
  svg {
  margin-top: -3px;
  stroke: #333;
}
/* .order-confirmation-page .eco-typography.eco-typography--bold.eco-show-promo {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #039BE5;
    cursor: pointer;
  } */
.eco-order-summary .eco-product-list .eco-accordion-addon-price {
  /* padding-left: 25px; */
  /* color: #71A601; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.005em;
  margin-top: 14px;
}

.eco-order-summary .eco-product-list .eco-product-add {
  gap: 0px !important;
  margin-left: 4px;
}
.eco-order-summary .eco-product-list .eco-product-add .eco-product-add-ctl {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #eae8ed;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  cursor: pointer;
}

.eco-order-summary .eco-product-list .eco-product-add .eco-product-add-ctl svg {
  fill: #abaaad;
}

.eco-product-list::-webkit-scrollbar {
  width: 5px;
}
.select-order-type-prompt {
  padding: 30px 0px;
  height: 100%;
  overflow-y: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #808080;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #808080;
}
.eco-product-list .cart-summary-image {
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  border: solid 1px #ebebeb;
  margin-right: 20px;
  border-radius: 6px;
}

.eco-order-summary-image {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 6px;
}
.ml-44 {
  margin-left: 44px;
}
.item-stack {
  width: 100%;
}
.order-success-form {
  height: calc(100vh - 144px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.order-success-form > div {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
}
.order-success-form-container {
  border: 1px solid #c4c4c4;
  padding: 50px;
  border-radius: 4px;
  background: #fff;
}
.eco-order-summary {
  min-width: 500px;
}
.quantity-settings {
  width: fit-content;
}
.ant-modal.delete-cartitem-popup-close .ant-modal-content {
  border-radius: 16px;
  padding: 20px 0px 10px;
  text-align: center;
}

.ant-modal.delete-cartitem-popup-close .ant-modal-content .ant-modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.ant-modal.delete-cartitem-popup-close {
  max-width: 325px;
}

.delete-cartitem-popup-close h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #7f919d;
}

.delete-cartitem-popup-close .eco-button.eco-button--primary {
  /* background: #71A601; */
  border-radius: 8px;
}

.delete-cartitem-popup-close .eco-button.eco-button--primary .eco-cutton__text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}

.delete-cartitem-popup-close .eco-button.eco-button--outline {
  background: #f5f5f5;
  border: 0.5px solid #464646;
  border-radius: 8px;
}
.delete-cartitem-popup-close .eco-button.eco-button--outline:hover {
  border: 0.5px solid #333;
  color: #000;
}

.delete-cartitem-popup-close .eco-button.eco-button--outline {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #333333;
}

.delete-cartitem-icon {
  background-image: url(../../../default/images/remove-from-cart.png);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 90px;
  height: 90px;
  background-size: contain;
}
.confirm-cartitem-icon {
  background-image: url(../../../default/images/icon-confirm-clock.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 83px;
  height: 83px;
  background-size: contain;
}

.confirm-cartitem-popup h2 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.005em;
  color: #7f919d;
}

.ant-modal.confirm-cartitem-popup .ant-modal-content {
  border-radius: 16px;
  padding: 20px 0px 10px;
  text-align: center;
}

.ant-modal.confirm-cartitem-popup .ant-modal-content .ant-modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.ant-modal.confirm-cartitem-popup {
  max-width: 330px;
}

.ant-modal.confirm-cartitem-popup .eco-button.eco-button--primary {
  background: #71a601;
  border-radius: 8px;
  padding: 10px 0;
  color: #ffffff;
  width: 100%;
}

.ant-modal.confirm-cartitem-popup .eco-button__text {
  font-family: "Plus Jakarta Sans";
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.005em;
}

.ant-modal.confirm-cartitem-popup .eco-button.eco-button--outline,
.ant-modal.confirm-cartitem-popup .eco-button.eco-button--outline:hover {
  background: #f6ffe4;
  border: 1px solid #79a33c;
  border-radius: 8px;
  padding: 10px 0;
  color: #333333;
  width: 100%;
}

.truncate-text {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-text-line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 70px;
  overflow: hidden;
}

.ant-popover.eco-order-summary {
  top: 64px !important;
  left: auto !important;
  right: 24px !important;
  position: fixed !important;
}

.ant-popover.eco-order-summary .ant-popover-arrow {
  display: none;
}
.eco-order-summary-table.responsiveTable tbody tr {
  border: none;
  border-bottom: 1px solid #e9e9e9;
}
.eco-order-summary-table.responsiveTable tbody td,
.eco-order-summary-table.responsiveTable tbody th {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #4d4d4d;
}
.eco-order-summary-table.responsiveTable
  h5.eco-typography.eco-typography--bold {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #4d4d4d;
}

.eco-order-summary-table.responsiveTable td .tdBefore {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #4d4d4d;
}
.eco-order-summary-table.responsiveTable
  tbody
  td
  h5.eco-typography--gray-lighter {
  font-size: 14px;
}

.order-confirmation-page .proceed-payment-button {
  background: #363b41 !important;
  padding: 4px 0;
}

.order-confirmation-page .eco-total-amount {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
  font-weight: bold;
  font-size: 1.6rem;
}

.order-confirmation-page .mobile-view .eco-product-add {
  width: fit-content;
  display: flex;
  align-items: center;
}

.order-confirmation-page
  .mobile-view
  .eco-product-add
  .eco-product-qty-action-button {
  padding: 5px;
  border: 1px solid #eae8ed;
  cursor: pointer;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.order-confirmation-page
  .mobile-view
  .eco-product-add
  .eco-product-qty-action-button
  svg {
  height: 12px;
  width: 12px;
  stroke: #000;
  stroke-width: 1px;
}

.order-confirmation-page .mobile-view .eco-product-add .eco-product-qty input {
  width: 40px;
}

.order-confirmation-page .mobile-view .eco-product-delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  border: 1px solid #eae8ed;
  border-radius: 6px;
}

.order-confirmation-page .mobile-view .eco-product-delete-button svg {
  height: 15px;
  width: 15px;
}

@media only screen and (max-width: 850px) {
  .order-confirmation-page .desktop-view {
    display: none;
  }
  .order-confirmation-page .mobile-view {
    display: initial;
  }
  .eco-order-summary-image {
    height: 60px;
    width: 60px;
  }
  .order-confirmation-page .proceed-payment-button {
    padding: 8px 0;
    width: 100%;
    margin: 0;
  }
}

@media only screen and (max-width: 768px) {
  .ant-popover.eco-order-summary {
    /* top: -15px !important; */
    right: 0px !important;
  }

  .eco-order-summary {
    width: 100%;
    margin: auto !important;
    position: relative !important;
    left: 0 !important;
    right: 0 !important;
    min-width: initial;
  }
  .eco-order-summary-image {
    width: 72px;
    height: 72px;
  }
  .cart-summary-image {
    width: 60px;
    height: 60px;
  }
  .order-confirmation-page {
    width: 100%;
    padding: 30px 20px;
  }
  .payment-details-bg {
    display: none !important;
  }
  .full-width-btn {
    width: 100%;
  }
  .toggle-switch-container {
    width: 90px;
  }
  .ml-44 {
    margin-left: 0;
  }
  .order-success-form {
    height: 80vh;
  }
  .order-success-form-container {
    border: none;
    padding: 0 50px;
  }
  .main-message {
    width: 200px;
    text-align: center;
  }
  .order-confirmation-page .toggle-switch-container svg {
    display: block;
  }
  .order-confirmation-page
    .toggle-switch-container
    .eco-order-summery-togg-title {
    display: none;
  }

  .truncate-text-line-2 {
    min-height: 40px;
  }
  .eco-order-summary .ant-popover-inner {
    height: 100vh;
    border-radius: 0px !important;
  }
}

@media screen and (max-width: 40em) {
  .eco-order-summary-table.responsiveTable td.pivoted {
    padding-left: calc(50% + -22px) !important;
    padding: 5px 15px;
  }
  .eco-order-summary-table.responsiveTable tbody tr .eco-summery-row-remove {
    position: absolute;
    left: -136px;
    top: 1px;
  }
  .eco-order-summary-table.responsiveTable tbody tr {
    position: relative;
  }
  .eco-order-summary-table.responsiveTable td .tdBefore {
    left: 3rem;
  }
}

@media screen and (max-height: 800px) {
  .eco-product-list {
    max-height: 37vh;
  }
  .eco-order-summary-row {
    padding: 10px 10px;
    margin-top: 0;
  }
  .eco-order-summary-total-row {
    padding: 10px 10px;
    margin-bottom: 0;
  }
  .eco-order-summary
    .ant-popover-content
    .ant-popover-inner
    .ant-popover-inner-content {
    padding-top: 15px;
  }
}

.eco-checkout-button {
  background-color: #fcb800;
}
