.Voucherstyles {
  padding-top: 40px;
  flex-grow: 1;
  overflow-y: auto;
  width: 80%;
  margin: auto;
}
@media only screen and (max-width: 600px) {
  .Voucherstyles {
    padding-top: 0;
    width: 100%;
  }
}
