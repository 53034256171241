.DrawerStyles-Menu {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100vh !important;
  width: 100%;
  z-index: 10000;
  margin: 0px !important;
  top: 0;
  bottom: 0 !important;
  .drawer-container {
    height: 100vh;
    background-color: #fff;
    width: 80%;
    padding: 32px 24px;
    .profile-conatiner {
      display: flex;
      justify-content: space-between;
      .profile-conatiner-left {
        display: flex;
        align-items: center;
        column-gap: 10px;
        .drawer-sign-in-menu-icon {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: #e4e9ee;
          display: flex;
          align-items: center;
          justify-content: center;
          .drawer-sign-in-menu-icon-profile {
            height: 20px;
            width: 20px;
            filter: var(--filter);
          }
        }

        .my-account-text-drawer {
          font-family: Roboto !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 15.23px;
          text-align: left;
          color: var(--primary-color);
        }
        .log-text-drawer {
          display: flex;
          font-family: Roboto !important;
          font-size: 13px;
          font-weight: 400;
          line-height: 15.23px;
          text-align: left;
          color: #7c8091;
          align-items: center;
          margin-top: 2.5px;
        }
        .border-line-sign-drawer {
          height: 13px;
          width: 1px;
          margin-left: 5px;
          margin-right: 5px;
          background-color: #7c8091;
        }
      }
      .profile-conatiner-right {
        height: 30px;
        width: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          height: 26px;
        }
      }
    }
    .menu-items-drawer {
      margin-top: 50px;
      .menu-item-drawer {
        font-family: Roboto !important;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        text-align: left;
        padding: 12px 0px;
        border-bottom: 1px solid #f0f0f0;
      }
    }
    .logout-conatiner {
      margin-top: 80px;
      cursor: pointer;
    }
  }
}
